/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Footer from "../common/Footer";
import Header from "../common/Header";
import SideNavBar from "../common/SideNavBar/ForSuperAdmin";
import SnackbarAlert from "../common/Snackbar";
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import { onboardedUpdate } from "../Services/Networks/User";
import Feedback from "../common/Feedback";

const Home = (props) => {
    const pathName = window.location.pathname;
    const keyslist = useSelector(state => state.user);
    const keyslists = useSelector(state => state);
    const [run, setrun] = useState(true);
    const steps = [{
        content: <h2>Let's begin our journey for Vernacle!</h2>,
        placement: 'center',
        target: 'body',
        style: {
          background: "red"
        }
      },
      {
        title: 'Translation',
        content: 'You can see your all translation key-value respect to selected project',
        floaterProps: {
          disableAnimation: true,
        },
        placement: 'right',
        target: '.wt-translation',
      },
      {
        content: 'These are all enrolled languages for a project!',
        placement: 'right',
        target: '.wt-langauge',
        title: 'Language',
      },
      {
        content: 'You can upload files to convert it to translation keys',
        placement: 'right',
        target: '.wt-uploadss',
        title: 'Uploads',
      },
      {
        content: 'You can download all translation keys-value ',
        placement: 'right',
        title: 'Downloads',
        target: '.wt-download',
      },
      {
        content: 'You can see all analytics related to your project and keys-value ',
        floaterProps: {
          disableAnimation: true,
        },
        placement: 'right',
        target: '.wt-analytics',
        title: 'Analytics',
      },
      {
        content: 'You can see all your orders and can create one',
        floaterProps: {
          disableAnimation: true,
        },
        placement: 'right',
        target: '.wt-order',
        title: 'Translation orders',
      },
      {
        content: 'You can see and edit the project settings, general settings, add memeber and change your pricing plans',
        floaterProps: {
          disableAnimation: true,
        },
        placement: 'right',
        target: '.wt-settings',
        title: 'Settings'
      },
      {
        content: <div>
            <h2>Your profile</h2>
            <p>From dropdown able to switch projects, documents and logout </p>
          </div>,
        floaterProps: {
          disableAnimation: true,
        },
        target: '.wt-drop',
      },
      {
        content: <div>
            <h2>Add keys</h2>
            <p>Add keys from here </p>
          </div>,
        floaterProps: {
          disableAnimation: true,
        },
        placement: 'left',
        target: '.wt-keyadd',
      },
      {
        content: <div>
          <h2>Namaste / नमस्ते </h2>
          <p>Welcome! You have completed onboarding.</p>
          <p></p>
        </div>,
        locale: { last: <strong> Completed </strong> },
        placement: 'center',
        target: 'body',
      },
    ];

    const handleJoyrideCallback = (data) => {
      const { status, type } = data;
      const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
      if (finishedStatuses.includes(status)) {
       setrun(false);
       onboardedUpdate({
        hasSeenOnboarding : true
       }, () => {});
      }
      
    };

    return (
      <div id="pcoded" className="pcoded snakbar-wrapper">
        <SnackbarAlert />
        {!keyslist?.hasSeenOnboarding && <Joyride
          callback={handleJoyrideCallback}
          continuous
          hideCloseButton
          run={run}
          scrollToFirstStep
          showProgress
          showSkipButton
          steps={steps}
          styles={{
            options: {
              zIndex: 10000,
              arrowColor: '#e3ffeb',
              backgroundColor: '#e3ffeb',
              overlayColor: 'rgba(0,0,0,.8)',
            },
            buttonNext: {
              background: "#6366f1"
            },
            buttonBack: {
              color: "#6366f1"
            }
          }}
        />
      }
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">
          <div className="pcoded-main-container">
          <div className="pcoded-wrapper">
              <SideNavBar />
              <div className="pcoded-content" id="admin_content">
                <Header id="header-edit" className="header-edting"/>
                <Outlet />
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Home;
