import { Icon } from '@iconify/react';
import { Button } from '@mui/material';
import React, {createRef, useEffect, useState} from 'react';
import Dropzone from 'react-dropzone';
import { csvToArray, excelToArray, jsonToArray } from '../Services/FileReader';

function DropZoneCus({setData, array }) {
    const dropzoneRef = createRef();
    const [error, setError] = useState("");
    const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point 
    if (dropzoneRef.current) {
        dropzoneRef.current.open()
    }
    };
    const allowdedFiletype = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/json", "text/csv"
    ]
   const onDropCustoom = (file) => {
    const singleFile = file[0];
    if(allowdedFiletype.includes(singleFile.type)){
       setError("");
       if(singleFile.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
            excelToArray(singleFile, setData);
       }
       if(singleFile.type == "application/json"){
            jsonToArray(singleFile, setData)
       }
       if(singleFile.type == "text/csv"){
            csvToArray(singleFile, setData)
       }
    }else{
        setError("* Only .xlsx, .csv, .json files are allowded")
    }
   }
   useEffect(() => {
     console.log("array", array)
   },[array]);

   const onError = () => {
     setError("* Issue with uploaded files")
   }
   const onDragEnter = () => {
     document.getElementById("container-drag").style.scale = 1.1;
   }
   const onDragLeave = () => {
    document.getElementById("container-drag").style.scale = 1;
  }
  return (
    <Dropzone ref={dropzoneRef} onError={onError} onDragLeave={onDragLeave} onDragEnter={onDragEnter} noClick noKeyboard multiple={false} onDrop={onDropCustoom}>
    {({getRootProps, getInputProps, acceptedFiles}) => {
        return (
        <div className="container-drop" id="container-drag">
            <div {...getRootProps({className: 'dropzone'})} style={{
                border: "2px dashed grey",
                width: "90%",
                height: "280px",
                borderRadius: 10,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "40px",
                position: "relative",
                textAlign: "center",
                alignItems: "center",
                alignContent: "center",
                background: "rgba(98, 67, 200, 0.135)"
            }}>
                <input {...getInputProps()} accept={".xlsx, .xls, .csv, .json"}/>
                <Icon icon={acceptedFiles?.length > 0 ? "fluent:folder-open-24-filled" : "heroicons:folder-solid"} className='icon-drag-drop'/>
                <p className='tags-name-upload'>
                    Drag 'n' drop here your documents to start uploading
                </p>
                <p className='tags-name-upload'>
                    OR
                </p>
                
                <Button
                    type="button"
                    variant='contained'
                    onClick={openDialog}
                >
                    Browse files
                </Button>
                <Icon icon="line-md:uploading-loop" className='ion-drag-drop'/>
            </div>
            <aside>
            <ul>
                {!error?.length > 0 ? acceptedFiles.map(file => (
                <li key={file.path}>
                    {file.path} - {file.size} bytes
                </li>
                )) : <li style={{color: "red", fontSize: "12px"}}>
                  {error}
            </li> }
            </ul>
            </aside>
        </div>
        );
    }}
    </Dropzone>
  )
}
export default DropZoneCus