import {editKeys} from './Networks/keys';


export const generateMTValues = (scheduler, rowData, getKeys, id) => {
    const data = rowData?.locales;
    const dataDef = data?.filter((d) => d.isDefault == true);
    const listLang = data?.filter((d) => d.isDefault !== true && (d.value == "" || d.value == null || d.value == undefined));
    let defaultLng = dataDef[0].locale.slice(0,2);
    let defaultValue = dataDef[0].value;
    let requestBody =  {
        name: rowData.name,
        projectId: id,
        values: []
    }
    if(listLang?.length > 0 ){
        listLang.forEach((element, i) => {
            scheduler
            .translate(defaultValue, defaultLng, element?.locale?.slice(0,2))
            .then((translate) => {
                console.log('Request-', element?.locale?.slice(0,2) , translate, requestBody['values'])
                requestBody['values'].push({
                    locale: element.locale,
                    value: translate
                })
            });
        });
        setTimeout(() => {
            editKeys(requestBody, () => {}, getKeys, () =>{}, ()=>{})
        }, 2000)
    }
    
}