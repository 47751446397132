export const data = {
    data: [
       {
           "id": "7c1a855b-5ccd-4929-a808-dc73d82a6e15",
           "key": "page.home.title",
           "description": null,
           "customPluralKey": null,
           "charLimit": null,
           "customAttributes": {},
           "tags": [],
           "screenshots": [
               {
                   "id": "421d8f4d-a132-46f4-90c4-e5f66f4af781",
                   "title": "Home page",
                   "description": "",
                   "url": "https://cdn.localizely.com/assets/screenshots/17e5bcc8-33f7-4f02-a994-cefa1c5e769d/421d8f4d-a132-46f4-90c4-e5f66f4af781_1.png",
                   "thumbnailUrl": "https://cdn.localizely.com/assets/screenshots/17e5bcc8-33f7-4f02-a994-cefa1c5e769d/421d8f4d-a132-46f4-90c4-e5f66f4af781_1-thumbnail.png"
               }
           ],
           "values": [
               {
                   "id": "320ac7bc-f597-44d1-8e22-1daa4cb1c581",
                   "value": "Sample project Home Page",
                   "pluralValue": null,
                   "locale": "en-US",
                   "reviewed": true,
                   "gengoRevisionPermission": "none",
                   "assignees": []
               },
               {
                   "id": "ea746b4d-0145-42cd-9514-7323114499e2",
                   "value": "Startseite des Beispielprojekts",
                   "pluralValue": null,
                   "locale": "de",
                   "reviewed": false,
                   "gengoRevisionPermission": "none",
                   "assignees": []
               }
           ],
           "plural": false
       },
       {
           "id": "ec0cee3c-603c-4e49-92c5-5444e100dfba",
           "key": "page.home.counter",
           "description": null,
           "customPluralKey": null,
           "charLimit": null,
           "customAttributes": {},
           "tags": [],
           "screenshots": [
               {
                   "id": "421d8f4d-a132-46f4-90c4-e5f66f4af781",
                   "title": "Home page",
                   "description": "",
                   "url": "https://cdn.localizely.com/assets/screenshots/17e5bcc8-33f7-4f02-a994-cefa1c5e769d/421d8f4d-a132-46f4-90c4-e5f66f4af781_1.png",
                   "thumbnailUrl": "https://cdn.localizely.com/assets/screenshots/17e5bcc8-33f7-4f02-a994-cefa1c5e769d/421d8f4d-a132-46f4-90c4-e5f66f4af781_1-thumbnail.png"
               }
           ],
           "values": [
               {
                   "id": "e2a79d7e-a77b-4b28-b087-5df38c619646",
                   "value": "You have pushed the button this many times",
                   "pluralValue": null,
                   "locale": "en-US",
                   "reviewed": true,
                   "gengoRevisionPermission": "none",
                   "assignees": []
               },
               {
                   "id": "202a475b-2d37-48c4-b45e-837d729a6357",
                   "value": "Du hast den Knopf so oft gedrückt",
                   "pluralValue": null,
                   "locale": "de",
                   "reviewed": false,
                   "gengoRevisionPermission": "none",
                   "assignees": []
               }
           ],
           "plural": false
       },
       {
           "id": "85e4276b-11d4-4269-8a1c-301d9be401dc",
           "key": "intro.welcome",
           "description": "This is a description of a key so that the translator better understands the context his is a description of a key so that the translator better understands the context his is a description of a key so that the translator better understands the context",
           "customPluralKey": null,
           "charLimit": null,
           "customAttributes": {},
           "tags": [],
           "screenshots": [],
           "values": [
               {
                   "id": "7266afe1-dd40-48ae-8d45-c8fc22ae1922",
                   "value": "Hi {firstName}, welcome to Localizely! This is a sample project where you can explore the features.",
                   "pluralValue": null,
                   "locale": "en-US",
                   "reviewed": true,
                   "gengoRevisionPermission": "none",
                   "assignees": []
               },
               {
                   "id": "2c161044-15e0-4af1-99e1-f8caa85274de",
                   "value": "Hallo {firstName}, willkommen bei Localizely. Dies ist ein Beispielprojekt, in dem Sie die Funktionen erkunden können.",
                   "pluralValue": null,
                   "locale": "de",
                   "reviewed": true,
                   "gengoRevisionPermission": "none",
                   "assignees": []
               }
           ],
           "plural": false
       },
       {
           "id": "714d8b1e-6446-4f9b-acc5-e1f761aa8556",
           "key": "intro.new-key",
           "description": null,
           "customPluralKey": null,
           "charLimit": null,
           "customAttributes": {},
           "tags": [
               {
                   "id": "6e7b4083-56df-4853-93b3-bee62de3c642",
                   "name": "new"
               }
           ],
           "screenshots": [],
           "values": [
               {
                   "id": "a571837e-93e2-4192-bdd0-e4ef78979a5e",
                   "value": "New keys created during import can be auto-tagged so that managers and translators know what keys miss translation.",
                   "pluralValue": null,
                   "locale": "en-US",
                   "reviewed": true,
                   "gengoRevisionPermission": "none",
                   "assignees": []
               },
               {
                   "id": "0add62fc-e59d-4c29-a1aa-11cdd9f313e2",
                   "value": "Während des Imports erstellte neue Keys können automatisch mit einem Tag versehen werden, sodass Manager und Übersetzer wissen, welche Keys keine Übersetzung haben.",
                   "pluralValue": null,
                   "locale": "de",
                   "reviewed": true,
                   "gengoRevisionPermission": "none",
                   "assignees": []
               }
           ],
           "plural": false
       },
       {
           "id": "52a40e0b-f428-4f27-84fe-539589e3a9fd",
           "key": "intro.too-long-text",
           "description": null,
           "customPluralKey": null,
           "charLimit": 50,
           "customAttributes": {},
           "tags": [],
           "screenshots": [],
           "values": [
               {
                   "id": "f8697ec9-d64b-413a-a100-82de371658ee",
                   "value": "This is a translation with notification, which indicates text exceeds its intended length.",
                   "pluralValue": null,
                   "locale": "en-US",
                   "reviewed": true,
                   "gengoRevisionPermission": "none",
                   "assignees": []
               },
               {
                   "id": "0ce3b478-8e1f-4385-aeac-2dd63a8fc1d7",
                   "value": "Dies ist eine Übersetzung mit Benachrichtigung, die angibt, dass der Text die vorgesehene Länge überschreitet.",
                   "pluralValue": null,
                   "locale": "de",
                   "reviewed": true,
                   "gengoRevisionPermission": "none",
                   "assignees": []
               }
           ],
           "plural": false
       },
       {
           "id": "304e94c1-2dcc-4e4d-8199-a0f0277f6769",
           "key": "intro.not-reviewed-text",
           "description": null,
           "customPluralKey": null,
           "charLimit": null,
           "customAttributes": {},
           "tags": [],
           "screenshots": [],
           "values": [
               {
                   "id": "5cbb0a07-e3ff-4bba-b3f0-d47fd2449334",
                   "value": "This is a translation with notification, which indicates the translation is not reviewed.",
                   "pluralValue": null,
                   "locale": "en-US",
                   "reviewed": false,
                   "gengoRevisionPermission": "none",
                   "assignees": []
               },
               {
                   "id": "7e8cd34b-6acd-4964-8929-fd8d9db00dce",
                   "value": "Dies ist eine Übersetzung mit Benachrichtigung, die besagt, dass die Übersetzung nicht überprüft wird.",
                   "pluralValue": null,
                   "locale": "de",
                   "reviewed": false,
                   "gengoRevisionPermission": "none",
                   "assignees": []
               }
           ],
           "plural": false
       }
   ],
   "meta": {
       "total": 6,
       "count": 6,
       "langs": [
           "en-US",
           "de"
       ],
       "locales": [
           {
               "localeName": "en-US",
               "uiLabelsColumnWidth": 300,
               "uiLabelsColumnShown": true
           },
           {
               "localeName": "de",
               "uiLabelsColumnWidth": 300,
               "uiLabelsColumnShown": true
           }
       ],
       "glossaryId": "4248967e-334e-4c75-b0d3-0a90c4926404"
   }
 };

 export const fileFormat = [{
    id: 1,
    label: 'Android Resources (.xml)'
 },
 {
    id: 2,
    label: 'Flutter ARB (.arb)'
 },{
    id: 3,
    label: 'Key-value JSON (.json)'
 },{
    id: 4,
    label: 'Angular XLIFF (.xlf)'
 },{
    id: 5,
    label: 'CSV (.csv)'
 },{
    id: 6,
    label: 'Excel  (.xlsx)'
 },{
    id: 7,
    label: 'React native (i18n)'
 },{
    id: 8,
    label: 'Javascript Object (.js)'
 },{
    id: 9,
    label: 'Structured JSON (.json)'
 },]
