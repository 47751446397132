import React from "react";
import { useSelector } from "react-redux";

const AdminDashboard = () => {
  const redux =  useSelector(state => state)
    console.log("data", redux);
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col-lg-8">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>Dashboard</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="page-body">
              <div className="row">
                <div className="col-xl-3 col-md-6">
                  <div className="card">
                    <div className="card-block">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h4 className="text-c-blue f-w-600">300</h4>
                          <h6 className="text-muted m-b-0">Total Translation</h6>
                        </div>
                        <div className="col-4 text-right">
                          <i className="feather icon-activity f-28"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer bg-c-blue">
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card">
                    <div className="card-block">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h4 className="text-c-yellow f-w-600">4</h4>
                          <h6 className="text-muted m-b-0">Verison Count</h6>
                        </div>
                        <div className="col-4 text-right">

                          <i className="icofont icofont-cart-alt f-28"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer bg-c-yellow">
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card">
                    <div className="card-block">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h4 className="text-c-blue f-w-600">HI, EN, FR</h4>
                          <h6 className="text-muted m-b-0">Languages</h6>
                        </div>
                        <div className="col-4 text-right">
                          <i className="feather icon-activity f-28"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer bg-c-blue">
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card">
                    <div className="card-block">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h4 className="text-c-blue f-w-600">Web, Android, IOS</h4>
                          <h6 className="text-muted m-b-0">Used as</h6>
                        </div>
                        <div className="col-4 text-right">
                          <i className="feather icon-activity f-28"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer bg-c-blue">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="styleSelector">
          </div>
        </div>
      </div>
    );
  }

export default AdminDashboard;
