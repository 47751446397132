import React, { useState } from 'react'
import { Card } from '@mui/material'
import { Icon } from '@iconify/react'
import { fetauredList, upComingApps } from '../../Constant/Integrations'
import { IntegrationDialog } from '../../Custom/IntegrationDailog'

function Integration() {
  const [open, setOpen] = useState(false);

  const openDialog = (itm) => {
    if(itm.title == "Slack"){
        setOpen(true);
    }
  }

  return (
    <div>
        <IntegrationDialog open={open} handleClose={() => setOpen(false)}/>
    <div>
        <h5 className="pl-3 pt-3" style={{ paddingBottom: 0 }}>Integration</h5>
        <span className="pl-3 pb-3">Streamline your localization workflow by connecting your favorite apps.</span>
    </div>
    <div>
        <h5 className="pl-3 pt-3" style={{ paddingBottom: 0 }}>Featured apps</h5>
    </div>
    <div className="row p-4">
        {fetauredList?.map((itm) => <Card className="col-3-cust-apps p-3" key={itm.id} onClick={() => openDialog(itm)}>
            <div className='d-flex align-tems-center'>
                <div>
                  <img src={itm?.logo}/>
                </div>
                <div className='ml-2'>
                    <b style={{color: "rgba(0,0,0,.8)", fontSize: 16}}>{itm.title}</b><br/>
                    <span style={{color: "rgba(0,0,0,.4)", fontSize: 12}}>by {itm?.author}</span>
                </div>
            </div>
            <div>
                <p className='m-0 mt-1' style={{color: "rgba(0,0,0,.7)", fontWeight: 500, fontSize: 14}}>
                    {itm?.summary}
                </p>
            </div>
        </Card>
        )}
    </div>
    <div>
        <h5 className="pl-3 pt-3" style={{ paddingBottom: 0 }}>Upcoming apps</h5>
    </div>
    <div className="row p-4">
        {upComingApps?.map((itm) => <Card className="col-3-cust-apps p-3" key={itm.id} onClick={() => openDialog(itm)}>
            <div className='tag-up-status'>{!itm.isAvailable && "Upcoming"}</div>
            <div className='d-flex align-tems-center'>
                <div>
                  <img src={itm?.logo}/>
                </div>
                <div className='ml-2'>
                    <b style={{color: "rgba(0,0,0,.8)", fontSize: 16}}>{itm.title}</b> {itm.isInstalled && <Icon icon={"fluent-mdl2:completed-solid"} color="#00c" />}<br/>
                    <span style={{color: "rgba(0,0,0,.4)", fontSize: 12}}>by {itm?.author}</span>
                </div>
            </div>
            <div>
                <p className='m-0 mt-1' style={{color: "rgba(0,0,0,.7)", fontWeight: 500, fontSize: 14}}>
                {itm?.summary}
                </p>
            </div>
        </Card>
        )}
    </div>
</div>
  )
}

export default Integration