import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Icon } from '@iconify/react';
import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, TextField, Typography } from '@mui/material';
import { addKey } from '../Services/Networks/keys';
import { copyToClipboard } from './ClipBoardCopy';
import { NOTIFICATION_TYPE } from '../Constant/Integrations';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function AlertDialogSlide({handleClose, open, url, key}) {
  return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="lg"
        fullWidth
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle  className='flex-sb'>
            <span>{"Screenshot : "}{url[1]}</span>
            <Icon icon={"eva:close-fill"} onClick={handleClose} style={{fontSize: 30, cursor: "pointer" }}/>
        </DialogTitle>
        <Divider />
        <DialogContent className='flex-c'>
          <img src={url[0]} className="img_preview"/>
        </DialogContent>
      </Dialog>
  );
}

export function ClipBoardDialogSlide({handleClose, open, openClipBoard, projectId}) {
  return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle  className='flex-sb'>
            <span>Copy whatever you need</span>
            <Icon icon={"eva:close-fill"} onClick={handleClose} style={{fontSize: 30, cursor: "pointer" }}/>
        </DialogTitle>
        <Divider />
        <DialogContent className='flex-fs'>
          <div>
            <div className='my-3'>
              <b>Project Id</b>
              <div className='flex-fs align-items-center'><Icon icon={"mdi:clipboard-text"} style={{ cursor: "pointer", fontSize: 20 }} onClick={() => copyToClipboard(projectId)} /><p className='my-0 ml-2 clip-back' style={{fontSize: 16}}>{projectId}</p> </div>
            </div>
            <div className='my-3'>
              <b>Key Name</b>
              <div className='flex-fs align-items-center'><Icon icon={"mdi:clipboard-text"} style={{ cursor: "pointer", fontSize: 20 }} onClick={() => copyToClipboard(openClipBoard?.row?.name)}/><p className='my-0 ml-2 clip-back' style={{fontSize: 16}}>{openClipBoard?.row?.name}</p> </div>
            </div>
            <div className='my-3'>
              <b>Key Description</b>
              <div className='flex-fs align-items-center'><Icon icon={"mdi:clipboard-text"} style={{ cursor: "pointer", fontSize: 20 }} onClick={() => copyToClipboard(openClipBoard?.row?.description)}/><p className='my-0 ml-2 clip-back' style={{fontSize: 16}}>{openClipBoard?.row?.description}</p> </div>
            </div>
            <div className='my-3'>
              <b>Base Value</b>
              <div className='flex-fs align-items-center'><p>{openClipBoard?.row?.locales?.map(d => <li key={d.locale} className='flex-fs align-items-center mx-2' style={{fontSize: 16}}>{d.locale} : <Icon icon={"mdi:clipboard-text"} onClick={() => copyToClipboard(d.value)} style={{marginLeft: 10, cursor: "pointer", fontSize: 20}}/><span className='m-2 clip-back'>{d.value} </span> </li> )}</p> </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
  );
}

export function ConfirmationDialogSlide({handleClose, bulkDelete, selectedRows, open, onConfirm, title, id, btnConfirm}) {
  const [isLoading, setIsLoading] = React.useState(false);

  return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className='flex-sb'>
            <span>{title}</span>
            <Icon icon={"eva:close-fill"} onClick={handleClose} style={{fontSize: 30, cursor: "pointer" }}/>
        </DialogTitle>
        <Divider />
        <DialogContent className='flex-c'>
          <p style={{textAlign: "center"}}>
            Delete <b>{id} </b>with all translations?
           <br/>
           This cannot be undone.
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{color: "#6366f1", borderColor: "#6366f1", marginRight: 5}} variant="outlined">Cancel</Button>
          <Button disabled={isLoading} onClick={() => onConfirm(id, setIsLoading)} variant="contained" style={{background: "#6366f1"}} >{!isLoading ? btnConfirm : 'Deleting...'}</Button>
        </DialogActions>
      </Dialog>
  );
}

export function ConfirmationDialogCopy({handleClose, duplicateKeyList, open, getKeys, title, projectId, btnConfirm, keyslistOriginal}) {
  const [name, setName ] = React.useState('');
  const [onerror, setOnerror ] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if(duplicateKeyList && duplicateKeyList?.name){
      setName(duplicateKeyList?.name)
    }
  }, [duplicateKeyList])
  
  const handleSave = () => {
    if(!name){
      setOnerror("Key is required to create data");
    }else{
        let locales = [];
        duplicateKeyList?.locales.map((d) => {
          let obj = {};
          obj["locale"] = d.locale;
          obj["value"] = d.value;
          obj["reviewed"] = d.reviewed
          locales.push(obj);
        })
        const payLoad = {
          name: name,
          hidden: duplicateKeyList.hidden,
          description: duplicateKeyList.description,
          screenshots: duplicateKeyList.screenshots,
          platforms: [],
          projectId: projectId,
          values: locales
        }
        addKey(setIsLoading, payLoad, () => getKeys('R', ()=>{}, keyslistOriginal), handleClose)
    }
  }

  return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className='flex-sb'>
            <span>{title}</span>
            <Icon icon={"eva:close-fill"} onClick={handleClose} style={{fontSize: 30, cursor: "pointer" }}/>
        </DialogTitle>
        <Divider />
        <DialogContent className='flex-fs'>
           <TextField 
           size='small' 
           onChange={(e) => setName(e.target.value)} 
           className='col-10' 
           placeholder='Dublicate key string' 
           value={name}
           error={onerror?.length > 0}
           helperText={onerror}
          /> 
        </DialogContent>
        <DialogActions className='px-4'>
          <Button onClick={handleClose} style={{color: "#6366f1", borderColor: "#6366f1", marginRight: 5}} variant="outlined">Cancel</Button>
          {/* <Button onClick={() => handleSave()} variant="outlined"  >Duplicate as reference</Button> */}
          <Button disabled={isLoading} onClick={() => handleSave()} variant="contained" style={{background: "#6366f1"}} >{!isLoading ? btnConfirm : 'Saving...'}</Button>
        </DialogActions>
      </Dialog>
  );
}

export const ConfirmDailog = ({handleClose, open, triggerSlack}) => {
  const [loading, setLoading] = React.useState(false);
  const handleClick = (type) => {
    setLoading(true);
    triggerSlack(type)
  }
  return <Dialog onClose={handleClose} open={open}>
  <DialogTitle>Configure slack channel for </DialogTitle>
  <Divider/>
  <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <ListItem alignItems="flex-start" className='hover-list' onClick={() => handleClick(NOTIFICATION_TYPE.INFO_COMMENTS_AND_KEY_VALUE)}>
        <ListItemAvatar>
          <Icon icon="uim:comment-dots" width={40}/>
        </ListItemAvatar>
        <ListItemText
          primary="Enable Comments & Keys Notification"
          secondary={
            <React.Fragment>
              {"Get notified on slack channels about comments and keys crud operations"}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start" className='hover-list' onClick={() => handleClick(NOTIFICATION_TYPE.INFO_CONSOLE)}>
        <ListItemAvatar>
        <Icon icon="fluent:window-console-20-regular" width={40}/>
        </ListItemAvatar>
        <ListItemText
          primary="Enable Console & accounts Notification"
          secondary={
            <React.Fragment>
              {"Get notification for invite member, billing and plans, integrations, order updates and etc."}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start" className='hover-list' onClick={() => handleClick(NOTIFICATION_TYPE.INFO_SYSTEM)}>
        <ListItemAvatar>
        <Icon icon="eos-icons:system-warning" width={40}/>
        </ListItemAvatar>
        <ListItemText
          primary="Enable Notification for system"
          secondary={
            <React.Fragment>
              {"Get notified on slack about your concerns, query and other system messages"}
            </React.Fragment>
          }
        />
      </ListItem>
  </List>
</Dialog>
}