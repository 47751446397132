import { Card } from '@mui/material'
import React from 'react'
import ImgUser from '../../Assets/images/user.png';

function Memeber({handleOpenInvite}) {
  return (
    <div>
    <h5 className="pl-3 pt-3" style={{paddingBottom: 0}}>Manage members</h5>
    <span className="pl-3 pb-3">Collaborate with others on this project</span>
    <div className="row" style={{padding: 20, marginLeft: 20}}>
        <Card className="col-3-cust p-0 paper-c-shadow">
            <div className="col-imag-backdrop">
               <img src={ImgUser}/><br/>
               <p className="card-col-name"> <i className="icofont icofont-hat-alt"></i> Admin</p>
            </div>
            <div className="p-3 text-align-center">
                <p className="ft-600">Nitesh singh</p>
                <p className="ft-500">holidolar@gmail.com</p>
                <button className='text-align-center-button member-r'>Remove from project</button>
            </div>
        </Card>
        <Card className="col-3-cust p-0 paper-c-shadow">
            <div className="col-imag-backdrop">
               <img src={ImgUser}/><br/>
               <p className="card-col-name"> <i className="icofont icofont-hat-alt"></i> Admin</p>
            </div>
            <div className="p-3 text-align-center">
                <p className="ft-600">Nitesh singh</p>
                <p className="ft-500">holidolar@gmail.com</p>
                <button className='text-align-center-button member-r'>Remove from project</button>
            </div>
        </Card>
        <Card className="col-3-cust p-0 paper-c-shadow">
            <div className="col-imag-backdrop-grey">
            </div>
            <div className="text-align-center">
                <i className="feather icon-user-plus"></i>
            </div>
            <div className="p-3 text-align-center mrt">
                <button className='text-align-center-button member' onClick={(e) => handleOpenInvite(e)}>Invite members</button>
            </div>
        </Card>
    </div>
  </div>
  )
}

export default Memeber