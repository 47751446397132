import axios from "axios";
import { GET_KEYS_LIST } from "../../Redux/slices/keys";
import { HANDLE_SNACKBAR } from "../../Redux/slices/SnackbarAlert";
import { store } from "../../store";
import { apiGateway } from "../../utils/constant/apigateways";
import { ResponseError, ResponseSuccess } from "../../utils/Response";

export const keysListApi = ( setLoading, payLoad, hideInput, keyslist, setDataNext, type ) => {
    setLoading(true);
    axios.post(apiGateway + "/key/list", 
        payLoad
    , {
       headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem('token-le-wegid')}`
       }
    })
    .then(res => {
       if(ResponseSuccess(res).status == true){
          setLoading(false)
          const key = 'name';
          let unique = [];
          if(type == 'U'){
            // unique = ResponseSuccess(res).response.keys

            unique=[...keyslist.data.keys]
            unique.push(...ResponseSuccess(res).response.keys);
            const uniques = []
            const f = keyslist.data.keys.forEach(d => uniques.push(d.name))
            
            // const filtered = [...unique].filter(({name}, index) => !uniques.includes(name, index+1))
          }else{
            unique = ResponseSuccess(res).response.keys
          }
         
          const payloadSet = {
            keys: unique,
            nextIndex: ResponseSuccess(res).response.nextIndex
          }
          store.dispatch(GET_KEYS_LIST(payloadSet));
          setDataNext(ResponseSuccess(res).response?.nextIndex);
       }else{
            store.dispatch(HANDLE_SNACKBAR({
                open: true,
                message: ResponseSuccess(res).response.message,
                type: "error"
            }));
       }
    })
    .catch((err) => {
        store.dispatch(HANDLE_SNACKBAR({
            open: true,
            message: "Not able to fetch keys!",
            type: "error"
        }));
       setLoading(false);
    }).finally(() => {
        if(hideInput) hideInput();
    })
}

export const addKey = ( setLoading, requestBody, getKeys, handleClose, keyslistOriginal ) => {
    setLoading(true);
    axios.post(apiGateway + "/key/add", requestBody, {
       headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem('token-le-wegid')}`
       }
    })
    .then(res => {
       if(ResponseSuccess(res).status == true){
          setLoading(false);
          getKeys('R', ()=>{}, keyslistOriginal);
          store.dispatch(HANDLE_SNACKBAR({
            open: true,
            message: "Created the key!",
            type: "success"
        }));
        //   store.dispatch(GET_KEYS_LIST(res?.data?.data));
       }else{
            store.dispatch(HANDLE_SNACKBAR({
                open: true,
                message: ResponseSuccess(res).message,
                type: "error" 
            }));
       }
       handleClose();
    })
    .catch((error) => {
        store.dispatch(HANDLE_SNACKBAR({
            open: true,
            message: ResponseError(error).message,
            type: "error"
        }));
       setLoading(false);
    })
}

export const keyDelete = ( setLoading, requestBody, getKeys, handleClose, keyslistOriginal) => {
    setLoading(true);
    axios.post(apiGateway + "/key/delete", requestBody, {
       headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem('token-le-wegid')}`
       }
    })
    .then(res => {
       if(ResponseSuccess(res).status == true){
          setLoading(false);
          store.dispatch(HANDLE_SNACKBAR({
            open: true,
            message: "key deleted successfully !",
            type: "success"
        }));
       }else{
            store.dispatch(HANDLE_SNACKBAR({
                open: true,
                message: "Something went wrong!",
                type: "error"
            }));
       }
    })
    .catch(() => {
        store.dispatch(HANDLE_SNACKBAR({
            open: true,
            message: "Something went wrong!",
            type: "error"
        }));
       setLoading(false);
    }).finally(() => {
        handleClose();
        getKeys('R', ()=>{}, keyslistOriginal);
    })
}

export const editKeys = ( requestBody, setLoading, getKeys, handleClose, hideInput ) => {
    axios.post(apiGateway + "/key/update", requestBody, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('token-le-wegid')}`
        }
    })
    .then(res => {
       if(ResponseSuccess(res).status == true){
          store.dispatch(HANDLE_SNACKBAR({
            open: true,
            message: "Updated the key!",
            type: "success"
        }));
       }else{
            store.dispatch(HANDLE_SNACKBAR({
                open: true,
                message: "Something went wrong!",
                type: "error"
            }));
       }
    })
    .catch(() => {
        store.dispatch(HANDLE_SNACKBAR({
            open: true,
            message: "Something went wrong!",
            type: "error"
        }));
    }).finally(() => {
        getKeys('R', hideInput);
        setLoading(false);
        handleClose();
    });
}