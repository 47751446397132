import { Icon } from "@iconify/react";
import { Autocomplete, Button, Chip, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { abbreviationLang } from "../../Constant/Language";
import { CustomWidthTooltip } from "../../Custom/BootStarpToolTip";

const TAX_RATE = 0.07;

function ccyFormat(num) {
    return `${num.toFixed(2)}`;
}

function priceRow(qty, unit) {
    return qty * unit;
}

function createRow(desc, qty, unit, unitprice) {
    const price = priceRow(unit, unitprice);
    return { desc, qty, unit, price, unitprice };
}

function subtotal(items) {
    return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

const rows = [
    createRow('English', 40, 905, 0.5),
    createRow('French', 10, 90, 0.6),
    createRow('Hindi', 2, 19, 1),
];

const invoiceSubtotal = subtotal(rows);
const invoiceTaxes = TAX_RATE * invoiceSubtotal;
const invoiceTotal = invoiceTaxes + invoiceSubtotal;

const TxnCreateOrder = () => {
    const [selectedTab, setSelectedTab] = useState("P");

    return (
        <div className="pcoded-inner-content">
            <div className="main-body">
                <div className="page-wrapper p-0">
                    <div className="page-body">

                        <div className="row">
                            <div className="col-xl-5 col-md-6 page-body-setting-s pt-0 scroll-inner-div-setting">
                                <div className="page-header pb-0 mb-2">
                                    <div className="row align-items-end">
                                        <div className="col-lg-8">
                                            <div className="page-header-title pt-0">
                                                <div className="d-inline pl-3 pt-2 pb-0">
                                                    <h4 className="p-0">Translation Order</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col my-1" style={{ width: "100%" }}>
                                    <label className="col-form-label">
                                        <span>Project Name</span><span>*</span>
                                    </label>
                                    <div className="">
                                        <TextField
                                            type="text"
                                            required={true}
                                            className="form-control"
                                            name="projectName"
                                            id="projectName"
                                            size="small"
                                            disabled
                                            style={{
                                                // borderColor: onerror.length > 0 && "red", 
                                                width: "96%",
                                                background: "transparent"
                                            }}
                                            placeholder="Project name"
                                            onChange={(e) => {
                                                // setProjectName(e.target.value);
                                                // setOnerror("")
                                            }}
                                            value={'ISRO'}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start"><Link>Switch project</Link></InputAdornment>,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="form-group col my-1" style={{ width: "96%" }}>
                                    <label className="col-form-label">
                                        <span>Source language</span>
                                        <CustomWidthTooltip arrow placement='top' title="Base language value act as value for which translations is to be performed">
                                            <Icon icon="ant-design:info-circle-filled" className='icon-dailog-info' />
                                        </CustomWidthTooltip>
                                    </label>
                                    <FormControl sx={{ my: 1, minWidth: "100%" }} size="small">
                                        <InputLabel id="demo-select-small">Source Language</InputLabel>
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            // value={age}
                                            label="Source Language"
                                        // onChange={handleChange}
                                        >
                                            <MenuItem value="" disabled>
                                                <em>None</em>
                                            </MenuItem>
                                            {abbreviationLang.map((name) => (
                                                <MenuItem
                                                    key={name?.abbreviation}
                                                    value={name?.abbreviation}
                                                // style={getStyles(name, personName, theme)}
                                                >
                                                    {name?.locale_name} -- {" "}<span style={{ color: "grey", textTransform: "uppercase" }}> {name?.abbreviation}</span>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="form-group col my-1" style={{ width: "96%" }}>
                                    <label className="col-form-label">
                                        <span>Target languages</span>
                                        <CustomWidthTooltip arrow placement='top' title="Select the languages you want to translate into. You'll be able to edit these later.">
                                            <Icon icon="ant-design:info-circle-filled" className='icon-dailog-info' />
                                        </CustomWidthTooltip>
                                    </label>
                                    <div className="ustom-height">
                                        <Autocomplete
                                            multiple
                                            size="small"
                                            // error={onerror?.length>0}
                                            // value={langaugeList}
                                            onChange={(event, newValue) => {
                                                //   handleChangeLanguage(event, newValue);
                                                //   setOnerror("")
                                            }}
                                            options={abbreviationLang}
                                            getOptionLabel={(option) => <>{option?.locale_name} -- <span style={{ color: "grey", textTransform: "uppercase" }}> {option?.abbreviation}</span></>}
                                            renderTags={(tagValue, getTagProps) =>
                                                tagValue.map((option, index) => (
                                                    <Chip
                                                        label={option.locale_name}
                                                        {...getTagProps({ index })}
                                                    />
                                                ))
                                            }
                                            style={{ width: "100%" }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Languages" placeholder="Languages" />
                                            )}
                                        />
                                        <span className="p-1 d-flex"><p className="angle-s"></p> Add <Link className="px-1"> all langauges </Link> of this project</span>
                                    </div>
                                </div>
                                <div className="form-group col my-1" style={{ width: "96%" }}>
                                    <label className="col-form-label">
                                        <span>Include in order</span>
                                        <CustomWidthTooltip arrow placement='top' title="Select the languages you want to translate into. You'll be able to edit these later.">
                                            <Icon icon="ant-design:info-circle-filled" className='icon-dailog-info' />
                                        </CustomWidthTooltip>
                                    </label>
                                    <div className="ustom-height">
                                        <Autocomplete
                                            multiple
                                            size="small"
                                            // error={onerror?.length>0}
                                            // value={langaugeList}
                                            onChange={(event, newValue) => {
                                                //   handleChangeLanguage(event, newValue);
                                                //   setOnerror("")
                                            }}
                                            options={abbreviationLang}
                                            getOptionLabel={(option) => <>{option?.locale_name} -- <span style={{ color: "grey", textTransform: "uppercase" }}> {option?.abbreviation}</span></>}
                                            renderTags={(tagValue, getTagProps) =>
                                                tagValue.map((option, index) => (
                                                    <Chip
                                                        label={option.locale_name}
                                                        {...getTagProps({ index })}
                                                    />
                                                ))
                                            }
                                            style={{ width: "100%" }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Include in order" placeholder="Include in order" />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="form-group col my-1" style={{ width: "96%" }}>
                                    <label className="col-form-label">
                                        <span>Filter by tags</span>
                                        <CustomWidthTooltip arrow placement='top' title="Select the languages you want to translate into. You'll be able to edit these later.">
                                            <Icon icon="ant-design:info-circle-filled" className='icon-dailog-info' />
                                        </CustomWidthTooltip>
                                    </label>
                                    <div className="ustom-height">
                                        <Autocomplete
                                            multiple
                                            size="small"
                                            // error={onerror?.length>0}
                                            // value={langaugeList}
                                            onChange={(event, newValue) => {
                                                //   handleChangeLanguage(event, newValue);
                                                //   setOnerror("")
                                            }}
                                            options={abbreviationLang}
                                            getOptionLabel={(option) => <>{option?.locale_name} -- <span style={{ color: "grey", textTransform: "uppercase" }}> {option?.abbreviation}</span></>}
                                            renderTags={(tagValue, getTagProps) =>
                                                tagValue.map((option, index) => (
                                                    <Chip
                                                        label={option.locale_name}
                                                        {...getTagProps({ index })}
                                                    />
                                                ))
                                            }
                                            style={{ width: "100%" }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Filter by tags" placeholder="Filter by tags" />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="form-group col my-1" style={{ width: "96%" }}>
                                    <label className="col-form-label">
                                        <span>Translation Manner</span>
                                        <CustomWidthTooltip arrow placement='top' title="Base language value act as value for which translations is to be performed">
                                            <Icon icon="ant-design:info-circle-filled" className='icon-dailog-info' />
                                        </CustomWidthTooltip>
                                    </label>
                                    <FormControl sx={{ my: 1, minWidth: "100%" }} size="small">
                                        <InputLabel id="demo-select-small">Translation Manner</InputLabel>
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            // value={age}
                                            label="Translation Manner"
                                        // onChange={handleChange}
                                        >
                                            <MenuItem value="" disabled>
                                                <em>None</em>
                                            </MenuItem>
                                            {abbreviationLang.map((name) => (
                                                <MenuItem
                                                    key={name?.abbreviation}
                                                    value={name?.abbreviation}
                                                // style={getStyles(name, personName, theme)}
                                                >
                                                    {name?.locale_name} -- {" "}<span style={{ color: "grey", textTransform: "uppercase" }}> {name?.abbreviation}</span>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="form-group col my-1" style={{ width: "96%" }}>
                                    <label className="col-form-label">
                                        <span>Translation quality </span>
                                        <CustomWidthTooltip arrow placement='top' title="Base language value act as value for which translations is to be performed">
                                            <Icon icon="ant-design:info-circle-filled" className='icon-dailog-info' />
                                        </CustomWidthTooltip>
                                    </label>
                                    <FormControl sx={{ my: 0, minWidth: "100%" }} size="small">
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel value="standard" control={<Radio />} label="Standard" />
                                            <FormControlLabel value="advanced" control={<Radio />} label="Advanced" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                                <div className="form-group col mb-2 pb-3" style={{ width: "96%" }}>
                                    <label className="col-form-label">
                                        <span>Project briefing </span>
                                        <CustomWidthTooltip arrow placement='top' title="Base language value act as value for which translations is to be performed">
                                            <Icon icon="ant-design:info-circle-filled" className='icon-dailog-info' />
                                        </CustomWidthTooltip>
                                    </label>
                                    <textarea
                                        placeholder="Describe your project in as much detail as possible — what it’s about, who your target audience is, links to the website or app, and other info. "
                                        style={{
                                            width: "100%",
                                            minHeight: 100,
                                            background: "transparent",
                                            borderRadius: 5,
                                            padding: 10,
                                            border: "1px solid rgba(55,50,50, .3)"
                                        }} />
                                </div>
                            </div>
                            <div className="col-xl-7 col-md-6 page-body-setting-m scroll-inner-div-setting">
                                <div className="page-header pb-0 mb-2">
                                    <div className="row align-items-end">
                                        <div className="col-lg-8">
                                            <div className="page-header-title pt-0">
                                                <div className="d-inline pl-3 pt-2 pb-0">
                                                    <h4 className="p-0">Order Summary</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: "100%" }} aria-label="spanning table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Language</TableCell>
                                                    <TableCell align="right">Keys</TableCell>
                                                    <TableCell align="right">Unit</TableCell>
                                                    <TableCell align="right">Unit price</TableCell>
                                                    <TableCell align="right">Sum</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row) => (
                                                    <TableRow key={row.desc}>
                                                        <TableCell>{row.desc}</TableCell>
                                                        <TableCell align="right">{row.qty}</TableCell>
                                                        <TableCell align="right">{row.unit}</TableCell>
                                                        <TableCell align="right">{row.unitprice}</TableCell>
                                                        <TableCell align="right">{ccyFormat(row.price)}</TableCell>
                                                    </TableRow>
                                                ))}
                                                {/* <TableRow>
                                                    <TableCell rowSpan={3}/>
                                                    <TableCell rowSpan={3}/>
                                                    <TableCell colSpan={2}>Total</TableCell>
                                                    <TableCell align="right">{ccyFormat(invoiceSubtotal)}</TableCell>
                                                </TableRow> */}
                                                <TableRow>
                                                    <TableCell rowSpan={3}/>
                                                    <TableCell rowSpan={3}/>
                                                    <TableCell><b>Tax</b></TableCell>
                                                    <TableCell align="right">{`0 %`}</TableCell>
                                                    <TableCell align="right"><b>0.00</b></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    {/* <TableCell rowSpan={0}/> */}
                                                    <TableCell colSpan={2}><b>Total</b></TableCell>
                                                    <TableCell align="right"><b>{ccyFormat(invoiceSubtotal)}</b></TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                                <div>
                                    <p className="p-2 my-2" style={{ background: "pink", color: "red", borderRadius: 5}}>Minimum price per language pair for this provider is $5.00.</p>
                                </div>
                                <div className="d-flex row flex-sb p-3">
                                    <div>

                                    </div>
                                    <div>
                                       <div>
                                         <b style={{fontSize: 20}}>Total: $ {ccyFormat(invoiceSubtotal)}</b>
                                         <p>Delivery in approx. 2 business days</p>
                                       </div>
                                       <div>
                                        <Button variant="contained">Proceed to pay</Button>
                                       </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default TxnCreateOrder;
