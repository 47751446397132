import { abbreviationLang } from "../Constant/Language"

export const ResponseSuccess = (payload) => {
    return {
        status: payload?.data?.success, response : payload?.data?.data
    }
}

export const ResponseError = (payload) => {
    return {
        status: payload?.response?.data?.success, response : payload?.response?.data?.data, message : payload?.response?.data?.data?.message
    }
}

export const ToLocalDate = (inDate) => {
    var date = new Date(inDate);
    // date.setTime(inDate.valueOf() - 60000 * inDate.getTimezoneOffset());
    return `${date.getDate()  > 9 ? date.getDate() : `0${date.getDate()}`} / ${(date.getMonth() + 1 )> 9 ? date.getMonth() + 1 : `0${(date.getMonth() + 1)}`} / ${date.getFullYear()} , ${date.getHours()} : ${date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`}`
}

export const localName = (code) => {
    let dataToReturn = ''
    let vl = 0;
    let vssl = 0;
    abbreviationLang.map((data, id) => {
        if(vl < data.locale_name.length){
            vl = data.locale_name.length;
            vssl = data.locale_name
        }
        if(data?.abbreviation == code ){
           dataToReturn=  data.locale_name 
        }
    })

    return dataToReturn
}