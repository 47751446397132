import { Card, FormControl, LinearProgress, Skeleton, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';
import ImgUser from '../../Assets/images/user.png';

function PricingSetting() {
    return (
        <div>
            <h5 className="pl-3 pt-3" style={{ paddingBottom: 0 }}>Billing & Pricing</h5>
            <span className="pl-3 pb-3">View and update your plans and billing details</span>
            <div className="pl-3 pt-4">
                <b style={{fontSize: 16}}>
                    Current usage
                </b>
            </div>
            <Card className="row p-4 card-view-settings">
                
                <div>
                    <b>Your app has a total of 9 project active.</b>
                    <p>Enjoy unlimited access to all Vernacle features for free 🎉</p>
                </div>
                <div style={{ width: "100%",}} className="flex-fs align-items-center" >
                    <LinearProgress color="error" value={95} sx={{width: "80%", height: 6, borderRadius: 10, marginRight: 1}} variant="determinate" /> <b style={{fontSize: 14, fontWeight: 500}}>9 / 10 Projects</b>
                </div>
                <div style={{ width: "100%", margin:"20px auto" }} className="flex-fs align-items-center" >
                    <LinearProgress value={10} sx={{width: "80%", height: 6, borderRadius: 10, marginRight: 1}} variant="determinate" /><b style={{fontSize: 14, fontWeight: 500}}>200 / 2000 Keys</b>
                </div>
                <div style={{ width: "100%", marginBottom: 20 }} className="flex-fs align-items-center" >
                    <LinearProgress value={50} sx={{width: "80%", height: 6, borderRadius: 10, marginRight: 1}} variant="determinate" /><b style={{fontSize: 14, fontWeight: 500}}> 10 / 20 Users</b>
                </div>
                <div>
                    <Link>Learn more</Link> about our pricing strategy and learn how we try create a fair pricing for everyone. Ultimately we only want to make money if you make money. Sounds like a deal?
                </div>
                <div style={{ width: '100%' }}>
                    <Link
                        onClick={() => {
                            // setFormOpen(!formOpen);
                        }}
                        style={{ float: 'right' }}
                        className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger"
                        data-modal="modal-13"
                    >
                        {" "}
                        Update
                    </Link>
                </div>
            </Card>
            <div className="pl-3 pt-4">
                <b style={{fontSize: 16}}>
                    Subscription Plan
                </b>
            </div>
            <Card className="row px-4 card-view-settings">  
                <Skeleton className='col-12' height={50}/>
                <Skeleton className='col-12' height={50}/>
                <Skeleton className='col-12' height={50}/>
                <Skeleton className='col-12' height={50}/>
            </Card>
            <div className="pl-3 pt-4">
                <b style={{fontSize: 16}}>
                    Billing histroy
                </b>
            </div>
            <Card className="row p-4 card-view-settings">
                <Skeleton className='col-12' height={50}/>
                <Skeleton className='col-12' height={50}/>
                <Skeleton className='col-12' height={50}/>
                <Skeleton className='col-12' height={50}/>
            </Card>
        </div>
    )
}

export default PricingSetting