import { HANDLE_SNACKBAR } from "../Redux/slices/SnackbarAlert";
import { store } from "../store";

export function copyToClipboard(data) {
	var contentToCopy = data;
	navigator.clipboard.writeText(contentToCopy).then(function() {
        store.dispatch(HANDLE_SNACKBAR({
            open: true,
            message: "Copied to clipboard!",
            type: "info"
        }))
	}, function(err) {
		console.error('Unable to copy with async ', err);
	});
}