import React, { useState } from 'react'

function Bugs() {

    const [selectedTab, setSelectedTab] = useState('M');

  return (
    <div className="pcoded-inner-content">
    <div className="main-body">
        <div className="page-wrapper p-0">
            <div className="page-body p-0">
                <div className="row">
                    <div className="col-xl-3 col-md-6 page-body-setting-s">
                        <div className="py-1">
                            <h3 style={{fontSize: 28}}>Feedback</h3>
                        </div>
                        {/* <ul className="ul-list-settings">
                            <li className={`d-flex ${selectedTab == "P" && "selected"} `} onClick={() => setSelectedTab("P")}>
                                <span className="pcoded-micon">
                                    <i className="feather icon-settings"></i>
                                </span>
                                <p className="ul-list-settings-p">
                                    <span className="pcoded-mtext">Project Settings</span><br />
                                    <span className="pcoded-mtext-s">Manage project and project wise settings .</span>
                                </p>
                            </li>
                            <li className={`d-flex ${selectedTab == "G" && "selected"} `} onClick={() => setSelectedTab("G")}>
                                <span className="pcoded-micon">
                                    <i className="feather icon-settings"></i>
                                </span>
                                <p className="ul-list-settings-p">
                                    <span className="pcoded-mtext">General Settings</span><br />
                                    <span className="pcoded-mtext-s">Manage general and personal informations</span>
                                </p>
                            </li>
                            <li className={`d-flex ${selectedTab == "M" && "selected"} `} onClick={() => setSelectedTab("M")}>
                                <span className="pcoded-micon">
                                    <i className="feather icon-users"></i>
                                </span>
                                <p className="ul-list-settings-p">
                                    <span className="pcoded-mtext">Memebers</span><br />
                                    <span className="pcoded-mtext-s">Invite and manage memebers</span>
                                </p>
                            </li>
                            <li className={`d-flex ${selectedTab == "N" && "selected"} `} onClick={() => setSelectedTab("N")}>
                                <span className="pcoded-micon">
                                    <i className="feather icon-bell"></i>
                                </span>
                                <p className="ul-list-settings-p">
                                    <span className="pcoded-mtext">Notifications</span><br />
                                    <span className="pcoded-mtext-s">Control notification about translation tasks and comments</span>
                                </p>
                            </li>
                            <li className={`d-flex ${selectedTab == "B" && "selected"} `} onClick={() => setSelectedTab("B")}>
                                <span className="pcoded-micon">
                                    <i className="feather icon-credit-card"></i>
                                </span>
                                <p className="ul-list-settings-p">
                                    <span className="pcoded-mtext">Billing & Plans</span><br />
                                    <span className="pcoded-mtext-s">Change you billing method and plans</span>
                                </p>
                            </li>
                            <li className={`d-flex ${selectedTab == "I" && "selected"} `} onClick={() => setSelectedTab("I")}>
                                <span className="pcoded-micon">
                                    <i className="icofont icofont-terminal"></i>
                                </span>
                                <p className="ul-list-settings-p">
                                    <span className="pcoded-mtext">Integrations</span><br />
                                    <span className="pcoded-mtext-s">Setup the integrations with our services</span>
                                </p>
                            </li>
                        </ul> */}
                    </div>
                    <div className="col-xl-9 col-md-6 page-body-setting-m scroll-inner-div-setting">
                        {/* {fetchComponent(selectedTab)} */}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Bugs