import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Paper, Table, TableBody, TableHead, TablePagination, TableCell, TableContainer, TableRow, Checkbox, Tooltip, Skeleton, Button, Card } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const TranslationOrder = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [dataOrders, SetOrder] = useState([]);
    const projectID = useSelector(state => state?.project?.selectedProject?.id);

    const columns = [
        {
            name: "id",
            label: "Order Id",
            align: "left",
            minWidth: 30,
        },
        {
            name: "name",
            label: "Name",
            align: "left",
            minWidth: 30,
        },
        {
            name: "date",
            label: "Date",
            align: "left",
            minWidth: 65,
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "status",
            label: "Status",
            align: "left",
            minWidth: 40,
        },
        {
            name: "keys",
            label: "Keys",
            align: "left",
            minWidth: 30,
        },
        {
            name: "type",
            label: "Briefing",
            align: "left",
            minWidth: 30,
        },
        {
            name: "OrderBy",
            label: "Order By",
            align: "left",
            minWidth: 30,
        },
        {
            name: "total",
            label: "Amount",
            align: "left",
            minWidth: 30,
        },
        {
            name: "id",
            label: "Actions",
            align: "left",
            minWidth: 65,
            options: {
                filter: true,
                sort: true,
            }
        }
    ];

    return <div className="dashobard_wrapper grid-fs">
        <div className="dashobard_header flex-sb">
            <div className="flex-fs" style={{ gridGap: "5px", height: 30 }}>
            </div>
            {/* <div className="flex-fs" style={{ gridGap: "5px", height: 30 }}>
                <Button select variant="contained" size="small" onClick={() => window.location.href = `/home/${projectID}/addorders`}>
                    New order <Icon icon="" />
                </Button>
                <Button variant="outlined" size="small">
                    Download CSV Report
                </Button>
            </div> */}
        </div>
        {!dataOrders?.length > 0 ?
            <div className='flex-slack-not-configure'>
                    <h1>Get your project professionally translated</h1>
                    <p style={{ color: "#595959" }}>Our, translator network can help you with the translation of your project in a matter of days.</p>
                    <Button variant='contained' className="paper-c-shadow" disabled={true} onClick={() => {
                        // setOpenDialog(true);
                    }}
                        style={{  padding: 10, borderRadius: 5, margin: "0px auto", width: 300 }}>
                       Generate an instant order
                    </Button>
            </div> 
            : 
            <div className="dashobard_context_wrapper">
                <div className="d-inline">
                    <h4 className="px-2">Latest Translation Order</h4>
                </div>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: "65vh", borderBottom: "1px solid rgba(224, 224, 224, 1)" }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead className="table-custom-row">
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: `${column.minWidth}%` }}
                                    >
                                        <b>{column.label}</b>
                                    </TableCell>
                                ))}
                            </TableHead>
                            {isLoading ? <TableBody>
                                <TableRow variant="h1">
                                    <TableCell className="flex-fs">
                                        <Skeleton width={"60%"} style={{ margin: "auto", height: 50 }} />
                                        <Skeleton width={"60%"} style={{ margin: "auto", height: 50 }} />
                                    </TableCell>
                                    <TableCell className="flex-fs">
                                        <Skeleton width={"60%"} style={{ margin: "auto", height: 50 }} />
                                        <Skeleton width={"60%"} style={{ margin: "auto", height: 50 }} />
                                    </TableCell>
                                    <TableCell className="flex-fs">
                                        <Skeleton width={"60%"} style={{ margin: "auto", height: 50 }} />
                                        <Skeleton width={"60%"} style={{ margin: "auto", height: 50 }} />
                                    </TableCell>
                                    <TableCell className="flex-fs">
                                        <Skeleton width={"60%"} style={{ margin: "auto", height: 50 }} />
                                        <Skeleton width={"60%"} style={{ margin: "auto", height: 50 }} />
                                    </TableCell>
                                </TableRow>
                            </TableBody> :
                                <TableBody>
                                    <TableRow className="table-hover-icons-normal-rows" tabIndex={-1} style={{ borderBottom: "1px solid rgba(0,0,0,.1)" }} >
                                        <TableCell style={{ borderRight: "1px solid rgba(0,0,0,.0)" }} align="left">01G*****WN4X </TableCell>
                                        <TableCell style={{ borderRight: "1px solid rgba(0,0,0,.0)" }}>
                                            Webpage Locals
                                        </TableCell>
                                        <TableCell style={{ borderRight: "1px solid rgba(0,0,0,.0)" }}>
                                            2023-01-20
                                        </TableCell>
                                        <TableCell style={{ borderRight: "1px solid rgba(0,0,0,.0)" }}>
                                            <span className="tag-default-lang">Pending</span>
                                        </TableCell>
                                        <TableCell style={{ borderRight: "1px solid rgba(0,0,0,.0)" }}>
                                            <Link>6</Link>
                                        </TableCell>
                                        <TableCell style={{ borderRight: "1px solid rgba(0,0,0,.0)" }}>
                                            Ordinary translation
                                        </TableCell>
                                        <TableCell style={{ borderRight: "1px solid rgba(0,0,0,.0)" }}>
                                            <span>nitesh singh</span>
                                        </TableCell>
                                        <TableCell style={{ borderRight: "1px solid rgba(0,0,0,.0)" }}>
                                            <b style={{ color: "red" }}>$ 4.20</b>
                                        </TableCell>
                                        <TableCell style={{ borderRight: "1px solid rgba(0,0,0,.0)" }}>
                                            <div className="flex-fs" style={{ marginTop: 8 }}>
                                                <Tooltip title="View Details" arrow>
                                                    <Icon icon={"ic:baseline-open-in-new"} className={"icon-actions-end"} />
                                                </Tooltip>
                                                <Tooltip title="Invoice" arrow>
                                                    <Icon icon={"la:file-invoice"} className={"icon-actions-end"} />
                                                </Tooltip>
                                                <Tooltip title={"Cancel"} arrow>
                                                    <Icon icon={"mdi:cancel"} className={"icon-actions-end"} style={{ color: "red" }} />
                                                </Tooltip>
                                                <Tooltip title="Admin approved" arrow>
                                                    <Icon icon={"akar-icons:double-check"} className={"icon-actions-end"} style={{ color: "#64bcab" }} />
                                                </Tooltip>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="table-hover-icons-normal-rows" tabIndex={-1} >
                                        <TableCell align="left">01G*****AJHU </TableCell>
                                        <TableCell>
                                            Portal Viks
                                        </TableCell>
                                        <TableCell>
                                            2022-01-20
                                        </TableCell>
                                        <TableCell>
                                            <span className="tag-default-lang" style={{ backgroundColor: "green" }}>completed</span>
                                        </TableCell>
                                        <TableCell>
                                            <Link>10</Link>
                                        </TableCell>
                                        <TableCell>
                                            Machine translation
                                        </TableCell>
                                        <TableCell>
                                            <span>Dolar singh</span>
                                        </TableCell>
                                        <TableCell>
                                            <b style={{ color: "red" }}>$ 40.20</b>
                                        </TableCell>
                                        <TableCell>
                                            <div className="flex-fs" style={{ marginTop: 8 }}>
                                                <Tooltip title="View Details" arrow>
                                                    <Icon icon={"ic:baseline-open-in-new"} className={"icon-actions-end"} />
                                                </Tooltip>
                                                <Tooltip title="Invoice" arrow>
                                                    <Icon icon={"la:file-invoice"} className={"icon-actions-end"} />
                                                </Tooltip>
                                                <Tooltip title={"Cancel"} arrow>
                                                    <Icon icon={"mdi:cancel"} className={"icon-actions-end"} style={{ color: "red" }} />
                                                </Tooltip>
                                                <Tooltip title="Admin approved" arrow>
                                                    <Icon icon={"akar-icons:double-check"} className={"icon-actions-end"} style={{ color: "#64bcab" }} />
                                                </Tooltip>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            }

                        </Table>
                    </TableContainer>
                </Paper>
            </div> 
        }
    </div>
}

export default TranslationOrder;