import axios from "axios";
import { COMPANY_DETAILS } from "../../Redux/slices/companySlice";
import { USER_DETAILS } from "../../Redux/slices/userSlice";
import { store } from "../../store";
import { apiGateway } from "../../utils/constant/apigateways";
import { ResponseSuccess } from "../../utils/Response";

export const getCompanies = (setLoading) => {
    setLoading(true);
    axios.get(apiGateway + "/companies", {
       headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem('token-le-wegid')}`
       }
    })
    .then(res => {
       if(ResponseSuccess(res).status == true){
          setLoading(false);
          localStorage.setItem("compy-det", JSON.stringify(ResponseSuccess(res).response));
          localStorage.setItem("oauth-incomplete", true);
          localStorage.setItem("oauth-step", 2);
          store.dispatch(COMPANY_DETAILS(ResponseSuccess(res).response))
       }else{
          alert("Something went wrong")
       }
    })
    .catch(() => {
       alert("Something went wrong !");
       setLoading(false);
    })
}
 
export const fetchuserDetails = () => {
   // setLoading(true);   
   axios.get(apiGateway + "/user", {
      headers: {
         "Content-Type": "application/json",
         "Authorization": `Bearer ${localStorage.getItem('token-le-wegid')}`
      }
   })
   .then(res => {
      if(ResponseSuccess(res).status == true){
         // setLoading(false);
         store.dispatch(USER_DETAILS(ResponseSuccess(res).response))
      }else{
         alert("Something went wrong");
         // setLoading(false);
      }
   })
   .catch(() => {
      alert("Something went wrong !");
      // setLoading(false);
   })
}

export const onboardedUpdate = (requestBody, setLoading) => {
   setLoading(true);   
   axios.patch(apiGateway + "/user", 
      requestBody, {
      headers: {
         "Content-Type": "application/json",
         "Authorization": `Bearer ${localStorage.getItem('token-le-wegid')}`
      }
   })
   .then(res => {
      if(ResponseSuccess(res).status == true){
         setLoading(false);
         store.dispatch(USER_DETAILS(ResponseSuccess(res).response));
      }else{
         setLoading(false);
      }
   })
   .catch(() => {
      setLoading(false);
   })
}