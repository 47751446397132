import React, { useCallback, useEffect, useRef, useState } from "react";
import { Paper, Table, TableBody, Button, TableCell, TableContainer, Checkbox, Tooltip, Typography, Skeleton, Drawer, List, ListItem, Menu, MenuItem, CircularProgress, LinearProgress } from "@mui/material";
import { Icon } from "@iconify/react";
import { AlertDialogSlide, ClipBoardDialogSlide, ConfirmationDialogCopy, ConfirmationDialogSlide } from "../../Custom/DialogBox";
import TranslationAdd from "./TranslationAdd";
import { Delete, ExpandMoreOutlined } from "@mui/icons-material";
import { keyDelete, keysListApi, editKeys } from "../../Services/Networks/keys";
import { useDispatch, useSelector } from "react-redux";
import History from "./History";
import Comments from "./Comments";
import { GET_COMMENT_LIST } from "../../Redux/slices/commentsSlice";
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { GET_KEYS_LIST } from "../../Redux/slices/keys";
import { localName } from "../../utils/Response";
import { Scheduler } from '@translate-tools/core/util/Scheduler';
import { GoogleTranslator } from '@translate-tools/core/translators/GoogleTranslator';
import { generateMTValues } from "../../Services/GenerateMtValue";

const options = [
  'Last added',
  'First added',
  'Key name A-Z',
  'Key name Z-A',
];

const filteroptions = [
  'All',
  'Untranslated',
  'Unverified',
  'Tag',
  'Platform',
];
 
const Translation = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [url, setUrl] = useState([]);
  const [openInput, setOpenEdit] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [datatable, setTableData] = useState([]);
  const [editData, setEditData] = useState();
  const [openDelete, setopenDelete] = useState(false);
  const [deleteRowid, setDeleteRowid] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [bulkDelete, setBulkDelete] = useState(false);
  const [openTags, setOpenTags] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isReviewLoading, setIsReviewLoading] = useState('');
  const [isHiddenLoading, setIsHiddenLoading] = useState('');
  const [isComments, setIsComments] = useState(false);
  const [isHistory, setIsHistory] = useState(false);
  const [selectedKey, setSelectedKey] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [linearLoading, setLinearLoading] = useState(false);
  const [isUpdating, setUpdating] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0)
  const [duplicateKey, setDuplicateKey] = useState(false);
  const [duplicateKeyList, setDuplicateKeyList] = useState();
  const [historyData, setHistoryData] = useState({});
  const [openClipBoard, setOpenClipBoard] = useState({open: false})
  const keyslist = useSelector(state => state.keys?.data?.keys);
  const keyslistOriginal = useSelector(state => state.keys);
  // const keyslistnext = useSelector(state => state.keys?.data?.nextIndex);
  const projectID = useSelector(state => state?.project?.selectedProject);
  const userDetails = useSelector(state => state?.user);
  const [timeAgo, setTimeAgo] = useState();
  const [keyslistnext, setKeyslistnext] = useState(null)
  const dispatch = useDispatch();
  const openPopover = Boolean(anchorEl);
  const openPopoverFilter = Boolean(anchorElFilter);
  const translator = new GoogleTranslator();
  const scheduler = new Scheduler(translator);
  const observer = useRef();
  
  const lastKeyElementRef = useCallback(node => { 
    if (isLoading) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && keyslistnext) {
        setRowsPerPage(prevpage => rowsPerPage + 1);
        getKeys('U', () => {}, keyslistOriginal);
      }
    })
    if (node) observer.current.observe(node)
  }, [isLoading, keyslistnext]);

  useEffect(() => {
    TimeAgo.addDefaultLocale(en);
    // Create formatter (English).
    setTimeAgo(new TimeAgo('en-US'));
  },[])
 
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickListItemFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };
  const handleMenuItemClickFilter = ( event, index) => {
    setAnchorElFilter(null);
    setSelectedFilterIndex(index)
  }

  const handleClosePopover = () => {
    setAnchorEl(null);
    setAnchorElFilter(null);
  };

  const getKeys = (type, funHide, array) => {
    const payload = {
      projectId : projectID?.id, 
    } 

    if(type == 'U')
      payload.nextIndex = keyslistnext
    if (type == 'U' || type == 'R')
      keysListApi(setLinearLoading, payload, funHide, array, setKeyslistnext, type);
    else
      keysListApi(setIsLoading, payload, ()=>{}, array, setKeyslistnext, type);
  }

  useEffect(() => {
    const payloadSet = {
      keys: [],
      nextIndex: null
    }
    dispatch(GET_KEYS_LIST(payloadSet));
    setTableData([]);
    getKeys(null, () => {}, keyslistOriginal);
  }, []);

  useEffect(() => {
    setTableData(keyslist);
  }, [keyslist]);

  const columns = [
    {
      name: "name",
      label: "Key",
      align: "left",
      minWidth: 30,
    },
    {
      name: "locales",
      label: "Traslation",
      align: "left",
      minWidth: 65,
      options: {
        filter: true,
        sort: true,
      }
    }
  ];

  const openEdit = (index, rowIndex, type, value) => {
    if (`${index}_${rowIndex}` === openInput) {
      let dataRow = datatable;
      if (type === "s") {
        setInputValue(inputValue)
        dataRow[rowIndex].values[index].value = inputValue;
        setTableData(dataRow);
        setOpenEdit(-1);
      } if (type === "c") {
        setInputValue(value)
        setOpenEdit(-1);
      }
    } else {
      setInputValue(value)
      setOpenEdit(`${index}_${rowIndex}`);
    }
  };

  const handleClickOpen = (d, key) => {
    setOpen(true);
    const pay = [d, key];
    setUrl(pay);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenForm = (edit) => {
    setEditData(edit);
    setFormOpen(!formOpen)
  };

  const handleCloseForm = () => {
    setEditData([]);
    setFormOpen(!formOpen)
  };

  const handleChangeInput = (e, j, i, value) => {
    setInputValue(e.target.value);
  };

  const handleCloseDelete = () => {
    setopenDelete(!openDelete);
  };

  const onConfirm = (id, setIsDeleting) => {
    if (bulkDelete) {
      let filtered = datatable;
      selectedRows?.forEach((d) => {
        filtered = filtered.filter(it => it.key !== d.key);
        setTableData(filtered);
      })
    } else {
      const payload = {
        name: id,
        projectId: projectID?.id
      }
      keyDelete(setIsDeleting, payload, getKeys, handleCloseDelete, keyslistOriginal);
    }
    setSelectedRows([]);
  }

  const dublicateList = (data) => {
    let dataList = JSON.parse(JSON.stringify(data));
    dataList.name = `${data.name}_copy`;
    setDuplicateKeyList(dataList);
    setDuplicateKey(!duplicateKey);
  }

  const handleCheckBox = (item) => {
    if (!isItemSelected(item)) {
      setSelectedRows([...selectedRows, item])
    } else {
      const d = selectedRows?.filter(d => item?.id !== d.id)
      setSelectedRows([...d])
    }
  }

  const onSelectAllClick = (e) => {
    if (selectedRows?.length === rowsPerPage) {
      setSelectedRows([])
    } else {
      setSelectedRows(datatable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
    }
  }

  const isItemSelected = (e) => {
    let isSelected = false;
    selectedRows.some(d => {
      if (e?.id === d.id) {
        isSelected = true;
      }
    });
    return isSelected
  }

  const handleTooltipClose = () => {
    openTags(false);
  };

  const handleTooltipOpen = () => {
    openTags(true);
  };

  const handleTranslation = (row) => {
    generateMTValues(scheduler, row, getKeys, projectID?.id);
  }
  const toggleDrawer = (open, type) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (type == "history") {
      setIsHistory(!isHistory);
    } else {
      dispatch(GET_COMMENT_LIST([]));
      setIsComments(!isComments)
    }
  };
  const hideInput = () => {
    setOpenEdit(-1);
    setUpdating(false)
  }
  const editKeysData = (e, type, data, valueData) => {
    const payload = {
      name: data.name,
      projectId: projectID?.id
    }
    setIsReviewLoading('');
    if (type == "review") {
      setIsReviewLoading(`${valueData.locale}_${data.name}`);
      payload['values'] = [
        {
          locale: valueData.locale,
          reviewed: !valueData.reviewed
        }
      ]
      editKeys(payload, setIsReviewLoading, getKeys, () => { }, hideInput);
      // setOpenEdit(-1);
    };
    if (type == "value") {
      payload['values'] = [
        {
          locale: valueData.locale,
          value: inputValue
        }
      ]
      setUpdating(true)
      if(inputValue !== valueData.value)
        editKeys(payload, () => {}, getKeys, () => {}, hideInput);
      else
        setUpdating(false);
    };
    if (type == "hidden") {
      setIsHiddenLoading(data.name);
      payload['hidden'] = !data.hidden;
      editKeys(payload, setIsHiddenLoading, getKeys, () => {}, hideInput);
      // setOpenEdit(-1);
    };
  };
 
  const fillDefaultVlaue = (rowData ) => {
     const data = rowData?.locales?.filter((d) => d.isDefault == true)
     setInputValue(data[0]?.value);
  }

  return (
    <div className="pcoded-inner-content">
      <Drawer
        anchor={'right'}
        open={isHistory}
        onClose={toggleDrawer(false, "history")}
      >
        <History 
          onClose={() => setIsHistory(false)} 
          selectedKey={selectedKey}
          data={historyData} 
          userDetails={userDetails} />
      </Drawer>
      <Drawer
        anchor={'right'}
        open={isComments}
        onClose={toggleDrawer(false, "comments")}
      >
        <Comments 
          selectedKey={selectedKey}
          data={""}
          userDetails={userDetails}
          timeAgo={timeAgo}
          onClose={() => {
            setIsComments(false);
            dispatch(GET_COMMENT_LIST([]));
          }}
        />
      </Drawer>
      <ClipBoardDialogSlide open={openClipBoard?.open} handleClose={( ) => setOpenClipBoard({open: false})} openClipBoard={openClipBoard} projectId={projectID?.id}/>
      <ConfirmationDialogCopy keyslistOriginal={keyslistOriginal} projectId={projectID?.id} getKeys={getKeys} open={duplicateKey} duplicateKeyList={duplicateKeyList} title={"New key name"} btnConfirm={"Duplicate"} data={handleCloseDelete} handleClose={() => setDuplicateKey(!duplicateKey)}/>
      <AlertDialogSlide open={open} url={url} handleClose={handleClose} />
      <TranslationAdd keyslistOriginal={keyslistOriginal} open={formOpen} setEditData={setEditData} edit={editData} handleClose={handleCloseForm} getKeys={getKeys} />
      <ConfirmationDialogSlide onConfirm={onConfirm} title={"Delete key"} id={deleteRowid} open={openDelete} handleClose={handleCloseDelete} btnConfirm={"Delete"} />
      <div className="main-body">
        <div className="page-wrapper">
          <div className="page-header">
            <div className="row align-items-end">
              <div className="col-lg-8">
                <div className="page-header-title">
                  <div className="d-inline">
                    <h4>Translation List</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <Button
                  onClick={() => {
                    setFormOpen(!formOpen);
                  }}
                  variant="contained"
                  className="wt-keyadd f-right d-inline-block md-trigger"
                  data-modal="modal-13"
                  style={{opacity: 1, textTransform: "capitalize"}}
                >
                  {" "}
                  <i className="icofont icofont-plus m-r-5"></i> Add Translation
                </Button>
              </div>
            </div>
          </div>
          <Paper sx={{ width: '100%', overflow: 'hidden' }} className="paper-c-shadow">
            <div className="flex-sb alc-pl-20" style={{ background: selectedRows?.length > 0 && "aliceblue" }}>
              <div>
                {selectedRows?.length > 0 && <b style={{ color: "#262626" }}>{selectedRows?.length} Row selected </b>}
              </div>
              <div className="flex-right">
                {selectedRows?.length > 0 && <Tooltip title="Delete selected">
                  <div className="filter-span-button" onClick={() => {
                    setDeleteRowid(`${selectedRows?.length} rows`);
                    setBulkDelete(true);
                    handleCloseDelete();
                  }}>
                    <Delete />
                  </div>
                </Tooltip>
                }
                {!selectedRows?.length > 0 && 
                  <div className="filter-spsan-button px-2">
                    <List
                      component="nav"
                      aria-label="Device settings"
                      sx={{ bgcolor: 'background.paper' }}
                    >
                      <ListItem
                        id="sorting-button"
                        aria-haspopup="listbox"
                        aria-expanded={openPopover ? 'true' : undefined}
                        onClick={handleClickListItem}
                        style={{padding: 0}}
                      >
                        <Button variant="outlined" style={{fontSize: 12, minWidth: 120, padding: 2,paddingLeft: 5}}>
                          <b >Sort</b> - <span style={{textTransform: "capitalize", paddingRight: 10}}>{options[selectedIndex]}</span>
                          <ExpandMoreOutlined />
                        </Button>
                      </ListItem>
                    </List>
                    <Menu
                      id="lock-menu"
                      anchorEl={anchorEl}
                      open={openPopover}
                      onClose={handleClosePopover}
                      MenuListProps={{
                        'aria-labelledby': 'sorting-button',
                        role: 'listbox',
                      }}
                    >
                      {options.map((option, index) => (
                        <MenuItem
                          key={option}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Menu>
                   
                  </div>
                }
                {!selectedRows?.length > 0 && 
                  <div className="filter-spsan-button px-2">
                    <List
                      component="nav"
                      aria-label="Device settings"
                      sx={{ bgcolor: 'background.paper' }}
                    >
                      <ListItem
                        id="filter-button"
                        aria-haspopup="listbox"
                        aria-expanded={openPopoverFilter ? 'true' : undefined}
                        onClick={handleClickListItemFilter}
                        style={{padding: 0, opacity: .6}}
                      >
                        <Button variant="contained" style={{fontSize: 12, minWidth: 120, padding: 2, paddingLeft: 5}}>
                          <b >Filter</b> - <span style={{textTransform: "capitalize", paddingRight: 10}}>{filteroptions[selectedFilterIndex]}</span>
                          <ExpandMoreOutlined />
                        </Button>
                      </ListItem>
                    </List>
                    <Menu
                      id="lock-menu"
                      className="menuList-custom"
                      anchorEl={anchorElFilter}
                      open={openPopoverFilter}
                      onClose={handleClosePopover}
                      MenuListProps={{
                        'aria-labelledby': 'filter-button',
                        role: 'listbox',
                      }}
                    >
                      
                      {filteroptions.map((option, index) => (
                        <MenuItem
                          key={option}
                          style={{position: "relative"}}
                          className={`${option}-hovershow` }
                          selected={index === selectedFilterIndex}
                          onClick={(event) => handleMenuItemClickFilter(event, index)}
                        >
                          {option == "Tag" || option == "Platform" ? <div className="flex-sb align-items-center" style={{width: "100%"}}>
                            <span>{option}</span>
                            <Icon icon={"material-symbols:keyboard-arrow-right"}/>
                            </div> : option}
                          {option == "Tag" && <Paper className="tags-sideabsolute"> 
                            <MenuItem> 
                              TagsNew
                            </MenuItem>
                            <MenuItem> 
                              Portal
                            </MenuItem>
                            </Paper>}
                          {option == "Platform" && <Paper className="platform-sideabsolute"> 
                            <MenuItem> 
                              Web
                            </MenuItem>
                            <MenuItem> 
                              Android
                            </MenuItem>
                            <MenuItem> 
                              IOS
                            </MenuItem>
                            </Paper>}
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                }
              </div>
            </div>
            <TableContainer id="scroll-view"  sx={{ maxHeight: "69vh", borderBottom: "1px solid rgba(224, 224, 224, 1)" }}>
              <Table stickyHeader aria-label="sticky table">
                <div className="table-custom-row">
                  <TableCell padding="checkbox" className="table-custom-cell">
                    <Checkbox
                      color="primary"
                      // indeterminate={selectedRows?.length > 0 && selectedRows?.length < rowsPerPage}
                      // checked={rowsPerPage > 0 && selectedRows?.length === rowsPerPage}
                      // onChange={(e) => onSelectAllClick(e)}
                      inputProps={{
                        'aria-label': 'select all desserts',
                      }}
                    />
                  </TableCell>
                  {columns.map((column, k) => (
                    <TableCell
                      key={k}
                      align={column.align}
                      style={{ minWidth: `${column.minWidth}%`, border: "none" }}
                    >
                      <b>{column.label}</b>
                    </TableCell>
                  ))}
                  {/* </TableRow> */}
                </div>
                <TableBody>
                  {isLoading ? <Typography variant="h1">
                    <div className="flex-fs">
                      <Skeleton width={"30%"} style={{ margin: "auto", height: 50 }} />
                      <Skeleton width={"60%"} style={{ margin: "auto", height: 50 }} />
                    </div>
                    <div className="flex-fs">
                      <Skeleton width={"30%"} style={{ margin: "auto", height: 50 }} />
                      <Skeleton width={"60%"} style={{ margin: "auto", height: 50 }} />
                    </div>
                    <div className="flex-fs">
                      <Skeleton width={"30%"} style={{ margin: "auto", height: 50 }} />
                      <Skeleton width={"60%"} style={{ margin: "auto", height: 50 }} />
                    </div>
                  </Typography> :
                    datatable?.length > 0 ? datatable?.map((row, j) => {
                      return (
                        <div className="table-hover-icons" tabIndex={-1} key={j} 
                        ref={datatable?.length === j + 1 ? lastKeyElementRef : null}
                        >
                          <div padding="checkbox" className="td-cell-custom">
                            <Checkbox
                              color="primary"
                              // onChange={() => handleCheckBox(row)}
                              // checked={isItemSelected(row)}
                              inputProps={{
                                // 'aria-labelledby': labelId,
                              }}
                            />
                          </div>
                          {columns?.map((column, i_d) => {
                            const value = row[column?.name];
                            return (
                              <div key={i_d} align={column?.align} style={{ width: column?.name == "name" ? "30%" : "65%" }}>
                                {column?.name == "locales" &&
                                  <table className="table-custom-inner-cell">
                                    {[...value]?.sort((it, it2) => it.isDefault < it2.isDefault ? 1 : -1)?.map((d, i) => <tr className="table-custom-inner-cell-tr" key={i}>
                                      <div className="inner-lan-show" style={{background: d.isDefault && "#6366f145"}}>
                                        <span className="p-10">{localName(d.locale)} </span>
                                      </div>
                                      <div className="inner-lan-value" onClick={() => openEdit(i, j, "", d.value)}>
                                        {openInput == `${i}_${j}` ?
                                          <div className="grid-fs">
                                            <textarea type="text" value={inputValue} disabled={isUpdating} onChange={(e) => handleChangeInput(e, i, j)} className="input-table-edit" placeholder="Enter the base language text" />
                                            <div className="flex-fs " style={{ marginTop: 8, alignItems: "center" }}>
                                              <Tooltip title="Save" arrow>
                                                <Icon icon={"ic:round-done"} onClick={(e) => editKeysData(e, 'value', row, d)} className={"icon-actions-end"} />
                                              </Tooltip>
                                              {/* <Tooltip title={d.reviewed ? "Reviewed" : "Mark Reviewed"} arrow placement="top">
                                            {isReviewLoading?.length > 0 && isReviewLoading == d.locale ?
                                              <Icon icon={"line-md:loading-twotone-loop"} className={d.reviewed ? "icon-actions-end verified-action-hover" : "icon-actions-end"} style={{background: d.reviewed ? "#64bcab":""}}/> :
                                              <Icon icon={"bi:check-all"} onClick={(e) => editKeysData(e, 'review', row, d)} className={d.reviewed ? "icon-actions-end verified-action-hover" : "icon-actions-end"} style={{background: d.reviewed ? "#64bcab":""}}/>
                                            }
                                          </Tooltip> */}
                                              {!d?.isDefault == true && <Button className="button-input-source mx-2 p-1" variant="outlined" size="small" onClick={() => fillDefaultVlaue(row, setInputValue)}>Input source</Button>}
                                              <Tooltip title="Discard" arrow>
                                                <Icon icon={"eva:close-fill"} className={"icon-actions-end"} onClick={() => openEdit(i, j, "c", d.value)} style={{ color: "red" }} />
                                              </Tooltip>
                                              <p className="mb-0">
                                                <span className="word-count">{openInput == `${i}_${j}` ? inputValue.length : d.value?.length}</span> : Save and got to next
                                              </p>
                                            </div>
                                          </div> :
                                          <span>{d.value}</span>
                                        }
                                      </div>
                                      {openInput !== `${i}_${j}` && <div className="inner-lan-action">
                                        {/* <Icon icon={"octicon:unverified-16"} className={"icon-actions-end"}/> */}
                                        {/* <Icon icon={openInput == `${i}_${j}` ? "ic:round-done" : "eva:edit-outline"} className={"icon-actions-end"} onClick={() => openEdit(i, j)}/> */}
                                        <Tooltip title={d.reviewed ? "Reviewed" : "Mark Reviewed"} arrow placement="top">
                                          {isReviewLoading?.length > 0 && isReviewLoading == `${d.locale}_${row.name}` ?
                                            <CircularProgress className={d.reviewed ? "icon-actions-end verified-action-hover" : "icon-actions-end"} style={{ background: d.reviewed ? "#64bcab" : "", width: 30, height: 30 }} /> :
                                            <Icon icon={"bi:check-all"} onClick={(e) => editKeysData(e, 'review', row, d)} className={d.reviewed ? "icon-actions-end verified-action-hover" : "icon-actions-end"} style={{ background: d.reviewed ? "#64bcab" : "" }} />
                                          }
                                        </Tooltip>
                                        <Tooltip title="History" arrow placement="top">
                                          <Icon icon={"fontisto:history"} className={"icon-actions-end"} onClick={() => {
                                            setHistoryData(d);
                                            setSelectedKey({ key: value, value: row }); 
                                            setIsHistory(true)
                                          }} 
                                          />
                                        </Tooltip>
                                      </div>
                                      }
                                    </tr>
                                    )}
                                  </table>
                                }
                                {column?.name == "name" &&
                                  <div className="grid-display-key pt-2">
                                    <div style={{ height: "40%"}}>
                                      <div style={{ fontWeight: 500, color: "primary" }}>
                                        {value} { }
                                      </div>
                                      <div className="py-2">
                                        <span style={{ color: "rgba(0,0,0, 0.5)"}}>{row?.description}</span>
                                      </div>
                                      <div>
                                        {row?.tags?.map(d => <span key={d?.id} className="tags_chip">{d?.name}</span>)}
                                      </div>
                                      <div className="flex-fs" style={{ alignItems: "center" }}>
                                        {row?.screenshots?.map(d => <img key={d?.id} className="image_ss" src={d?.thumbnailUrl} onClick={() => handleClickOpen(d?.url, value)} />)}
                                        <span className="icon-actions-add-ss">
                                          <Icon icon={"fluent:image-add-20-regular"} style={{ fontSize: 24 }} />
                                        </span>
                                      </div>
                                    </div>
                                    <div style={{ height: "40%"}}>
                                      <div className="mt-3 icons-visibility-hover-row">
                                        <Tooltip title="Comments" arrow placement="bottom">
                                          <Icon icon={"ph:chat-circle-dots-bold"} className={"icon-actions-end"} onClick={() => {
                                            setIsComments(!isComments);
                                            setSelectedKey({ key: value, value: row });
                                          }} />
                                        </Tooltip>
                                        <Tooltip title="Add Tags" arrow placement="bottom">
                                          <Icon icon={"eva:pricetags-outline"} className={"icon-actions-end"} onClick={() => setOpenTags(!openTags)} />
                                        </Tooltip>
                                        <Tooltip title="Clipboard" arrow placement="bottom">
                                          <Icon icon={"tabler:clipboard"} className={"icon-actions-end"} onClick={() => setOpenClipBoard({open: true, row: row})} />
                                        </Tooltip>
                                        <Tooltip title="Dublicate trans" arrow placement="bottom">
                                          <Icon icon={"eva:copy-outline"} className={"icon-actions-end"} onClick={() => dublicateList(row)} />
                                        </Tooltip>
                                        <Tooltip title="Edit" arrow placement="bottom">
                                          <Icon icon={"fluent:edit-16-regular"} className={"icon-actions-end"} onClick={() => handleOpenForm(row)} />
                                        </Tooltip>
                                        <Tooltip title="Translate empty with machine translator" arrow placement="bottom">
                                          <Icon icon={"heroicons-solid:translate"} className={"icon-actions-end"} onClick={() => handleTranslation(row)}/>
                                        </Tooltip>
                                        <Tooltip title={row?.hidden ? "Unhide from developer" : "Hide from developer"} arrow placement="bottom">
                                          {isHiddenLoading?.length > 0 && isHiddenLoading == row.name ?
                                            <Icon icon={"line-md:loading-twotone-loop"} className={row?.hidden ? "icon-actions-end verified-action-hover" : "icon-actions-end"} /> :
                                            <Icon icon={"clarity:eye-hide-solid"} onClick={(e) => editKeysData(e, 'hidden', row)} className={"icon-actions-end"} style={{ color: row?.hidden ? "#64bcab" : "", background: row?.hidden && "white" }} />
                                          }
                                        </Tooltip>
                                        <Tooltip title="Delete" arrow placement="bottom">
                                          <Icon icon={"fluent:delete-12-regular"} className={"icon-actions-end"} onClick={() => {
                                            setDeleteRowid(row.name);
                                            handleCloseDelete();
                                          }} />
                                        </Tooltip>
                                      </div>
                                    </div>
                                  </div>
                                }
                              </div>
                            );
                          })}
                        </div>
                      );
                    })
                      :
                      <h1 className="text-align-center">Oops! It seems there is one keys. Try to add !</h1>}
                </TableBody>
              </Table>
            </TableContainer>
            {linearLoading && <LinearProgress style={{ background: "#6366f1"}}/>}
            <div className="p-2">
               {/* <Button variant="outlined" disabled={keyslistOriginal?.data?.nextIndex == null} onClick={() => getKeys('U', () => {}, keyslistOriginal)} style={{float: "right", margin:"10px", padding: "3px 5px", textTransform: "capitalize", fontSize: 12  }}><Replay5Outlined /> Load more</Button> */}
            </div>
          </Paper>
        </div>
      </div>
    </div>
  );
}

export default Translation;
