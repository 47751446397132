import { Icon } from '@iconify/react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, InputLabel, MenuItem, Select, Slide, TextField } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';
import { CustomWidthTooltip } from '../../Custom/BootStarpToolTip';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function InviteUser({open, handleClose}) {
  return (
    <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="sm"
        // fullWidth
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle  className='flex-sb'>
            <span>Invite new members <br/> <span style={{ fontSize: 14}}>Select a role and copy the invite link down below.</span></span>
            <Icon icon={"eva:close-fill"} onClick={handleClose} style={{fontSize: 30, cursor: "pointer" }}/>
        </DialogTitle>
        <Divider />
        <DialogContent>
           <div>
            <div className="inputfeild-basic-group">
              <TextField
                size="small"
                className="input-custom-css" 
                type="text" 
                id="outlined-basic" 
                label="Enter Email" 
                variant="outlined" 
              />
            </div>
            <div className="inputfeild-basic-group d-flex">
              <TextField
                size="small"
                className="input-custom-css" 
                type="text" 
                id="outlined-basic" 
                label="Enter Full Name" 
                variant="outlined" 
                style={{marginRight: 10}}
              />
              <FormControl variant="outlined" className="input-custom-css">
                <InputLabel id="demo-simple-select-label" className="input-custom-css-label">Select User Role</InputLabel>
                <Select
                  id="demo-simple-select"
                  // value={10}
                  style={{height: 40}}
                  label="Company Size"
                  // onChange={handleChange}
                >
                  <MenuItem value={10}>Admin</MenuItem>
                  <MenuItem value={20}>Editor</MenuItem>
                  <MenuItem value={20}>Viewer</MenuItem>
                </Select>
              </FormControl>
            </div>
           </div>
           <div style={{}}>
            <Link
              onClick={() => {
                // setFormOpen(!formOpen);
              }}
              className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger"
              data-modal="modal-13"
            >
              {" "}
              Send Invite
            </Link>
           </div>
           <br/><hr/>
           <div style={{width: "80%", textAlign:"center", margin: "5px auto", color: "rebeccapurple"}}>
            <p>This link will be sent to email id. You can revoke those invites at any time. However, never share them publicly.</p>
           </div>
        </DialogContent>
    </Dialog>
  )
}

export default InviteUser