import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Memeber from "./Memeber";
import InviteUser from "./InviteUser";
import queryString from "query-string";
import Integration from "./Integration";
import Notification from "./Notification";
import ProjectSetting from "./ProjectSetting";
import GeneralSetting from "./GeneralSetting";
import PricingSetting from "./Pricing";
import { getWindowTab, oAuthSlackConfigs } from "../../Services/Networks/Integration";

const Setting = () => {
    const projectDetails = useSelector(state => state?.project?.selectedProject);
    const queryParams = queryString.parse(window.location.search);
    const [openInvite, setInvite] = useState(false);
    const [selectedTab, setSelectedTab] = useState("P");
    const [searchParams, setSearchParams] = useSearchParams()
    
    useEffect(() => {
      if(queryParams?.tab && queryParams?.tab == "notification" ){
        setSelectedTab("N");
      }
      if(queryParams?.tab && queryParams?.tab == "general" ){
        setSelectedTab("G");
      }
      if(queryParams?.tab && queryParams?.tab == "project" ){
        setSelectedTab("P");
      }
      if(queryParams?.tab && queryParams?.tab == "memeber" ){
        setSelectedTab("M");
      }
      if(queryParams?.tab && queryParams?.tab == "billing_Plans" ){
        setSelectedTab("B");
      }
      if(queryParams?.tab && queryParams?.tab == "integrations" ){
        setSelectedTab("I");
      }
    },[queryParams])
    const handleOpenInvite = () => {
        setInvite(true);
    }

    const handleClose = () => {
        setInvite(false);
    }
   
    const fetchComponent = (tab) => {
        switch (tab) {
            case "P":
                return <ProjectSetting handleOpenInvite={() => {}} />
                break;
            case "G":
                return <GeneralSetting handleOpenInvite={() => {}} />
                break;
            case "M":
                return <Memeber handleOpenInvite={handleOpenInvite} />
                break;
            case "N":
                return <Notification projectId={projectDetails?.id}/>
                break;
            case "B":
                return <PricingSetting />
                break;
            case "I":
                return <Integration />
                break;

            default:
                break;
        }
    }

    return (
        <div className="pcoded-inner-content">
            <InviteUser handleClose={handleClose} open={openInvite} />
            <div className="main-body">
                <div className="page-wrapper p-0">
                    <div className="page-body">
                        <div className="row" style={{flexWrap: "nowrap"}}>
                            <div className="page-body-setting-s" style={{width: "25%", minWidth: 240}}>
                                <div className="page-header">
                                    <div className="row align-items-end">
                                        <div className="col-lg-8">
                                            <div className="page-header-title">
                                                <div className="d-inline px-4 pt-4">
                                                    <h4>Settings</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ul className="ul-list-settings pt-0">
                                    <li className={`d-flex ${selectedTab == "P" && "selected"} `} onClick={() => {
                                        setSearchParams({tab: "project"})      
                                        setSelectedTab("P");
                                        }}>
                                        <span className="pcoded-micon">
                                            <i className="icofont icofont-settings"></i>
                                        </span>
                                        <p className="ul-list-settings-p">
                                            <span className="pcoded-mtext">Project Settings</span><br />
                                            <span className="pcoded-mtext-s">Manage project and project wise settings .</span>
                                        </p>
                                    </li>
                                    <li className={`d-flex ${selectedTab == "G" && "selected"} `} onClick={() => {
                                            setSearchParams({tab: "general"});
                                            setSelectedTab("G")
                                        }}>
                                        <span className="pcoded-micon">
                                            <i className="feather icon-settings"></i>
                                        </span>
                                        <p className="ul-list-settings-p">
                                            <span className="pcoded-mtext">General Settings</span><br />
                                            <span className="pcoded-mtext-s">Manage general and personal informations</span>
                                        </p>
                                    </li>
                                    <li className={`d-flex ${selectedTab == "M" && "selected"} `} onClick={() => {
                                            setSearchParams({tab: "memeber"});
                                            setSelectedTab("M")
                                        }}>
                                        <span className="pcoded-micon">
                                            <i className="feather icon-users"></i>
                                        </span>
                                        <p className="ul-list-settings-p">
                                            <span className="pcoded-mtext">Memebers</span><br />
                                            <span className="pcoded-mtext-s">Invite and manage memebers</span>
                                        </p>
                                    </li>
                                    <li className={`d-flex ${selectedTab == "N" && "selected"} `} onClick={() => {
                                            setSelectedTab("N");
                                            setSearchParams({tab: "notification"});
                                        }}>
                                        <span className="pcoded-micon">
                                            <i className="feather icon-bell"></i>
                                        </span>
                                        <p className="ul-list-settings-p">
                                            <span className="pcoded-mtext">Notifications</span><br />
                                            <span className="pcoded-mtext-s">Control notification about translation tasks and comments</span>
                                        </p>
                                    </li>
                                    <li className={`d-flex ${selectedTab == "B" && "selected"} `} onClick={() => {
                                            setSearchParams({tab: "billing_Plans"});
                                            setSelectedTab("B")
                                        }}>
                                        <span className="pcoded-micon">
                                            <i className="feather icon-credit-card"></i>
                                        </span>
                                        <p className="ul-list-settings-p">
                                            <span className="pcoded-mtext">Billing & Plans</span><br />
                                            <span className="pcoded-mtext-s">Change you billing method and plans</span>
                                        </p>
                                    </li>
                                    <li className={`d-flex ${selectedTab == "I" && "selected"} `} onClick={() => {
                                            setSelectedTab("I");
                                            setSearchParams({tab: "integrations"});
                                        }}>
                                        <span className="pcoded-micon">
                                            <i className="icofont icofont-terminal"></i>
                                        </span>
                                        <p className="ul-list-settings-p">
                                            <span className="pcoded-mtext">Integrations</span><br />
                                            <span className="pcoded-mtext-s">Setup the integrations with our services</span>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <div className="page-body-setting-m scroll-inner-div-setting" style={{width: "72%", background: "rgb(250 251 255)"}}>
                                {fetchComponent(selectedTab)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Setting;
