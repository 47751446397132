import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Icon } from '@iconify/react';
import { Box, ButtonGroup, Checkbox, Chip, Divider, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Tooltip, tooltipClasses } from '@mui/material';
import { useEffect } from 'react';
import { CustomWidthTooltip } from '../../Custom/BootStarpToolTip';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { addKey, editKeys } from '../../Services/Networks/keys';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TranslationAdd({handleClose, edit, open, url, key, getKeys,keyslistOriginal}) {
  const defaultPlatforms =['Web', 'Android', 'IOS'];
  const project = useSelector(state => state?.project?.selectedProject);

  const [platforms, setPlatforms] = useState([]);
  const [keys, setKeys] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [basevalue, setBasevalue] = useState("");
  const [onerror, setOnerror] = useState("");
  const [hidden, setHidden] = useState(false);
  
  const handleChange = (value) => {
    if(!platforms.includes(value))
      setPlatforms([...platforms, value]);
    else{
      const newPlatfrom = platforms.filter(d => d !== value);
      setPlatforms(newPlatfrom)
    }
  };
  useEffect(() => {
    if(edit && Object.keys(edit)?.length > 0 && edit?.locales?.length > 0){
      let val = ''
        edit?.locales?.map(data => {
          if(data?.locale == "en_US") val =  data?.value;
        })
        setKeys(edit?.name)
        setDescription(edit?.description)
        setBasevalue(val)
        setHidden(edit?.hidden)
    }else{
        setKeys("");
        setDescription("");
        setBasevalue("");
        setPlatforms([]);
    }
  },[edit]);

  const handleSave = () => {
    if(!keys){
      setOnerror("Key is required to create data");
    }else{
      // add concept of default while biilding object
      if(edit && Object.keys(edit)?.length > 0 && edit?.locales?.length > 0){
        let buildValue = [];
        project?.locales?.map(d => {
          if(d.isDefault){
            let obj = {}
            obj["locale"] = d.locale;
            obj["value"] = basevalue;
            buildValue.push(obj);
          }
        })

        let val = ''
        edit?.locales?.map(data => {
          if(data?.locale == "en_US") val =  data?.value;
        })
        let prevValue = {}
          prevValue["locale"] = "en_US";
          prevValue["value"] = val;
        const prevState = {
          name: edit.name,
          projectId:  project?.id,
          hidden:  edit?.hidden,
          description: edit?.description, 
          values: [prevValue]
        }
        const payLoad = {
          name: keys,
          projectId: project?.id,
          hidden: hidden,
          description: description,
          // platforms: platforms,
          values: buildValue
        }
        if(!(JSON.stringify(payLoad) == JSON.stringify(prevState))){
          setIsLoading(true);
          editKeys(payLoad, setIsLoading, getKeys, handleClose)
        }
      }else{
        let buildValue = []
        project?.locales?.map(d => {
          let obj = {};
          obj["locale"] = d.locale;
          if(d.isDefault){
            obj["value"] = basevalue
          }else{
            obj["value"] = ""
          }
          buildValue.push(obj);
        })
        const payLoad = {
          name: keys,
          projectId: project?.id,
          hidden: hidden,
          description: description,
          platforms: platforms,
          reviewed: false,
          values: buildValue
        }
        addKey(setIsLoading, payLoad, getKeys, handleClose, keyslistOriginal)
      }
      
    }
  }
  return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle  className='flex-sb'>
            <span>New string key</span>
            <Icon icon={"eva:close-fill"} onClick={handleClose} style={{fontSize: 30, cursor: "pointer" }}/>
        </DialogTitle>
        <Divider />
        <DialogContent>
           <div>
            <div className="form-group col" style={{width: "100%"}}>
                <label className="col-form-label">
                    <span>Key</span><span>*</span>
                    <CustomWidthTooltip arrow placement='top' title="Keys act as placeholders for translations in your code">
                      <Icon icon="ant-design:info-circle-filled" className='icon-dailog-info'/>
                    </CustomWidthTooltip>
                </label>
                <div className="">
                    <input
                        type="text"
                        required={true}
                        className="form-control"
                        name="key"
                        id="key"
                        style={{borderColor: onerror.length > 0 && "red"}}
                        onInput={(e) => {
                            e.persist();
                            e.target.value = e.target.value.split(" ").join(".")
                        }}
                        disabled={edit && Object.keys(edit)?.length > 0}
                        placeholder="Unique key name"
                        onChange={(e) => {
                          setKeys(e.target.value);
                          setOnerror("")
                        }}
                        value={keys}
                    />
                    {onerror.length > 0 && <p style={{color: "red"}}>{onerror}</p>}
                </div>
            </div>
            <div className="form-group col" style={{width: "100%"}}>
                <label className="col-form-label">
                    <span>Base language value</span> 
                    <CustomWidthTooltip arrow placement='top' title="Base language value act as value for which translations is to be performed">
                      <Icon icon="ant-design:info-circle-filled" className='icon-dailog-info'/>
                    </CustomWidthTooltip>
                </label>
                <div className="">
                    <textarea
                        type="text"
                        className="form-control"
                        name="baseValue"
                        placeholder="Add base language text(optional)"
                        onChange={(e) => {
                          setBasevalue(e.target.value);
                        }}
                        value={basevalue}
                    />
                </div>
            </div>
            <div className="form-group col" style={{width: "100%", marginBottom: 0}}>
                <label className="col-form-label">
                    <span>Description</span> 
                    <CustomWidthTooltip arrow placement='top' title="Help project contributors understand what this piece of text is for.">
                      <Icon icon="ant-design:info-circle-filled" className='icon-dailog-info'/>
                    </CustomWidthTooltip>
                </label>
                <div className="">
                    <textarea
                        type="text"
                        className="form-control"
                        name="description"
                        placeholder="Provide more context(optional)"
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                        value={description}
                    />
                </div>
            </div>
            <div className="form-group col mt-2" style={{width: "100%"}}>
                <label className="col-form-label">
                    <span>Platforms</span> 
                    <CustomWidthTooltip arrow placement='top' title="Keys can be assigned to multiple platforms — no need for duplicate work!">
                      <Icon icon="ant-design:info-circle-filled" className='icon-dailog-info'/>
                    </CustomWidthTooltip>
                </label>
                <div className="custom-tags-select">
                <div size="small" aria-label="small button group" >
                  <Button color='primary' key="web" size="small" onClick={() => handleChange('web')} style={{paddingRight: 10,borderRadius: 50, background: platforms.includes("web") ? "#6366f161" : "#80808024", color: platforms.includes("web") ? "#595959" : "#595959"}}> <Icon icon={"logos:chrome"} style={{margin: "0px 6px"}}/> Web</Button>
                  <Button key="android" size="small" onClick={() => handleChange('android')} style={{paddingRight: 10,borderRadius: 50, background: platforms.includes("android") ? "#6366f161" : "#80808024", color: platforms.includes("android") ? "#595959" : "#595959", margin: "0px 10px"}}> <Icon icon={"logos:android-icon"} style={{margin: "0px 6px"}}/>Android</Button>
                  <Button key="ios" size="small" onClick={() => handleChange('ios')} style={{paddingRight: 10,borderRadius: 50, background: platforms.includes("ios") ? "#6366f161" : "#80808024", color: platforms.includes("ios") ? "#595959" : "#595959"}}><Icon icon={"logos:apple"} style={{margin: "0px 6px"}}/> IOS</Button>
                </div>
                {/* <FormControl fullWidth className='col-12'>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={platforms}
                    size="small"
                    className="form-control"
                    onChange={handleChange}
                    placeholder="Select platform"
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }} size="small">
                            {selected?.length > 0 ? selected.map((value) => (
                            <Chip key={value} label={value} style={{height: 20}}/>
                            )) : "Select platform"}
                        </Box>
                    )}
                >
                  {defaultPlatforms.map((name) => (
                  <MenuItem
                      key={name}
                      value={name}
                  >
                      {name}
                  </MenuItem>
                  ))}
                </Select>
                </FormControl> */}
                </div>
            </div>
            <div className='btm-check-foradd'>
            <Checkbox
                color="primary"
                sx={{
                    '&.Mui-checked': {
                      color: "#64bcab",
                    },
                }}
                checked={hidden}
                value={hidden}
                onChange={() => setHidden(!hidden)}
                inputProps={{
                    'aria-label': 'Hide from contributers',
                }}
            />
            <span>
              Hide from contributers
            </span>
            <CustomWidthTooltip arrow placement='top' title="Prevent this dialog from closing after you hit Save. Useful if you’re adding multiple keys in a row.">
                <Icon icon="ant-design:info-circle-filled" className='icon-dailog-info'/>
            </CustomWidthTooltip>
          </div>
           </div>
        </DialogContent>
        <DialogActions className='flex-sb flex-sb-webkit'>
          <div className='btm-check-foradd' style={{marginLeft: "-7px"}}>
              <Checkbox
                  color="primary"
                  sx={{
                      '&.Mui-checked': {
                        color: "#64bcab",
                      },
                    }}
                  // indeterminate={numSelected > 0 && numSelected < rowCount}
                  // checked={rowCount > 0 && numSelected === rowCount}
                  // onChange={onSelectAllClick}
                  inputProps={{
                      'aria-label': 'Keep Dailog open',
                  }}
              />
              <span>
                Keep Dailog open 
              </span>
              <CustomWidthTooltip arrow placement='top' title="Prevent this dialog from closing after you hit Save. Useful if you’re adding multiple keys in a row.">
                  <Icon icon="ant-design:info-circle-filled" className='icon-dailog-info'/>
              </CustomWidthTooltip>
          </div>
          <div>
            <Button onClick={handleClose} style={{color: "#6366f1", borderColor: "#6366f1", marginRight: 5}} variant="outlined">Cancel</Button>
            <Button onClick={handleSave} variant="contained" style={{background: "#6366f1"}} disabled={isLoading}>{isLoading ? "Saving..." : "Save" }</Button>
          </div>
          </DialogActions>
      </Dialog>
  );
}
