import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Icon } from '@iconify/react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Divider, MenuItem, Select } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export function CustomTableForm({handleClose, open, array}) {

    const [col1, setCol1] = React.useState("key");
    const [col2, setCol2] = React.useState("value");
    const [col3, setCol3] = React.useState("description");

    const handleChange = (e) => {
      const {name, value}= e.target;
      if(name == "col1"){
        if(col1 !== value){
            if(col2 == value){
                setCol2(col1);
                setCol1(col2)
            }
            if(col3 == value){
                setCol3(col1)
                setCol1(col3)
            }
        }else{
            setCol1(value)
        }
      }
      if(name == "col2"){
        if(col2 !== value){
            if(col1 == value){
                setCol1(col2);
                setCol2(col1)
            }
            if(col3 == value){
                setCol3(col2)
                setCol2(col3)
            }
        }else{
            setCol2(value)
        }
      }
      if(name == "col3"){
        if(col3 !== value){
            if(col1 == value){
                setCol1(col3);
                setCol3(col1);
            }
            if(col2 == value){
                setCol2(col3);
                setCol3(col2);
            }
        }else{
            setCol3(value)
        }
      }
    }
  return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        fullWidth
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className='flex-sb'>
            <span>Choose how to import columns</span>
            <Icon icon={"eva:close-fill"} onClick={handleClose} style={{fontSize: 30, cursor: "pointer" }}/>
        </DialogTitle>
        <Divider />
        <DialogContent className='grid-fs'>
            <p>
                Please define what each column represents from the selected file. <a href='/'>Read more</a>
            </p>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell align="center">
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={col1}
                                name="col1"
                                variant={"standard"}
                                onChange={handleChange}
                                label="Lable"
                            >
                                <MenuItem value={"key"}>String key</MenuItem>
                                <MenuItem value={"value"}>Translation (Opt)</MenuItem>
                                <MenuItem value={"description"}>Description (Opt)</MenuItem>
                            </Select>
                        </TableCell>
                        <TableCell align="center"> 
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={col2}
                                name="col2"
                                variant={"standard"}
                                onChange={handleChange}
                                label="Lable"
                            >
                                <MenuItem value={"key"}>String key</MenuItem>
                                <MenuItem value={"value"}>Translation (Opt)</MenuItem>
                                <MenuItem value={"description"}>Description (Opt)</MenuItem>
                            </Select>
                        </TableCell>
                        <TableCell align="center">
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={col3}
                                name="col3"
                                variant={"standard"}
                                onChange={handleChange}
                                label="Lable"
                            >
                               <MenuItem value={"key"}>String key</MenuItem>
                                <MenuItem value={"value"}>Translation (Opt)</MenuItem>
                                <MenuItem value={"description"}>Description (Opt)</MenuItem>
                            </Select>
                        </TableCell>
                     
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {array.map((row, i) => (
                        <TableRow
                        key={i}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {row.key}
                        </TableCell>
                        <TableCell align="center">{row.value}</TableCell>
                        <TableCell align="center">{row.description}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{color: "#6366f1", borderColor: "#6366f1", marginRight: 5}} variant="outlined">Cancel</Button>
          <Button variant="contained" style={{background: "#6366f1"}} >Apply</Button>
        </DialogActions>
      </Dialog>
  );
}
