import { Icon } from "@iconify/react";
import html2canvas from "html2canvas";
import SnackbarAlert from "./Snackbar";
import { countries } from "../Constant/Language";
import { arrayQuotes } from "../Constant/Quotes";
import imgSl from '../Assets/images/image-sl.png';
import { defaultAvatar } from "../Constant/Avatar";
import React, { useEffect, useState } from "react";
import Avatar, { genConfig } from 'react-nice-avatar';
import { useDispatch, useSelector } from "react-redux";
import { KeyboardBackspace } from "@mui/icons-material";
import { oAuthCall, userUpdate, verifyToken } from "../Services/Networks/Oauth";
import { Autocomplete, Box, Button, CircularProgress, FormControl, FormControlLabel, InputLabel, MenuItem, MobileStepper, Radio, RadioGroup, Select, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";

const SignUp = () => {
  const dispatch = useDispatch();
  const [quotes, setQuotes] = useState({});
  const [view, setView] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [configs, setConfig] = useState(defaultAvatar);
  const [avatar, setAvatar] = useState("g-avatar");
  const [tSteps, setTsteps] = useState(4);
  const [loginEnable, setLoginEnable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [signupProceed, setSignupProceed] = useState(false);
  const [localToken, setLocalToken] = useState("");
  const [companyName, setName] = useState("")
  const [size, setSize] = useState("")
  const [country, setCountry] = useState({
    "code": "IN",
    "label": "India",
    "phone": "91"
})
  const [websiteURL, setWebsiteURl] = useState("")
  const [profileImage, setProfileImage] = useState("");
  const [workprofile, setWorkProfile] = useState("");
  const [img_id, setImgId] = useState("");
  const [oauthType, setOauthType] = useState("");
  let openWindow;

  const userDetails = useSelector(state => state.user)
  const onLogin = async () => {
    localStorage.setItem("loged_In", true);
    localStorage.setItem(
      "loged_In_auth",
      localStorage.getItem('token-le-wegid')
    );
    localStorage.setItem("loged_In_role", "super-admin");
    window.location.reload();
    window.location.href = "/dashboard";

  };

  setInterval(() => {
    if (loading == true) {
      setLocalToken(localStorage.getItem("token-le-wegid"))
    }
    if (openWindow?.closed == true && !localStorage.getItem("token-le-wegid")) {
      setLoading(false);
    }
  }, 1000);

  useEffect(() => {
    if (localToken?.length > 0 && activeStep == 0 && localStorage.getItem("oauth-redirect") == "true") {
      verifyToken(setLoading, setActiveStep, setSignupProceed, () => { }, setProfileImage, onLogin, setLoginEnable, oauthType, setOauthType);
    }
  });

  useEffect(() => {
    if (localStorage.getItem("token-le-wegid")?.length > 0 && localStorage.getItem("oauth-redirect") == "false" && localStorage.getItem("oauth-incomplete") == "true") {
      setLoading(true);
      verifyToken(setLoading, setActiveStep, setSignupProceed, setValue, setProfileImage, onLogin, setLoginEnable, oauthType, setOauthType);
    }
    if (localStorage.getItem("oauth-incomplete") == "true") {
      setLoginEnable(false)
    }
  }, []);


  const setValue = () => {
    setView(localStorage.getItem("view-auth"));
    setName(userDetails?.company?.name);
    setSize(userDetails?.company?.size);
    setCountry(userDetails?.company?.country.label);
    setWebsiteURl(userDetails?.company?.website);
    setWorkProfile(userDetails?.workProfile);
  }

  const handleRadioChange = (e) => setAvatar(e.target.value);

  useEffect(() => {
    // setTimeout(() => {
    getQuotes();
    // }, 5000);
  }, []);

  const getQuotes = () => {
    setQuotes(arrayQuotes[Math.floor(Math.random() * (4 - 0 + 1)) + 0]);
  }

  const handleChange = async (event, nextView) => {
    localStorage.setItem("view-auth", nextView);
    if (nextView === 'indivisual') {
      setTsteps(4);
    } else {
      setTsteps(5);
    }
    setView(nextView);
  };

  const capu = (id, av_id) => {
    setImgId(av_id);
    html2canvas(document?.getElementById(id)).then(canvas => {
      document.body.appendChild(canvas);
      var ctx = canvas.getContext("2d");
      // var image = ctx.toDataURL("image/png").replace("image/png", "image/octet-stream");  // here is the most important part because if you dont replace you will get a DOM 18 exception.  
      // window.location.href=image;
      const image = new Image();
      image.src = "plumeria.jpg";
      image.addEventListener("load", () => {
        ctx.drawImage(image, 0, 0, 233, 320);

        const imageData = ctx.getImageData(10, 20, 80, 230);
        ctx.putImageData(imageData, 260, 0);
      });
    });
  };

  // const callNextUpdate = (e) => {
  //   if(view == 'indivisual'){
  //     if(activeStep == 1){
  //       setActiveStep(3);
  //     }else if(activeStep == 3){
  //       setLoading(true);
  //       userUpdate(UrequestBody, setLoading,setActiveStep, setSignupProceed, activeStep, ()=>{}, dispatch);
  //     }if(activeStep == 4){
  //       setLoading(true);
  //       userUpdate(IrequestBody, setLoading,setActiveStep, setSignupProceed, activeStep, onLogin, dispatch);
  //     }
  //   }
  //   if(view !== 'indivisual'){
  //     if(activeStep == 2){
  //       if(localStorage.getItem("oauth-step") !== "2"){
  //         setLoading(true);
  //         // setSignupProceed(false);
  //         companyUpdate(requestBody, setLoading, setActiveStep, setSignupProceed, activeStep);
  //       }else
  //         setActiveStep(activeStep+1);
  //     }else if(activeStep == 3){
  //       if(localStorage.getItem("oauth-step") !== "3"){
  //         setLoading(true);
  //         userUpdate(UrequestBody, setLoading,setActiveStep, setSignupProceed, activeStep, ()=>{}, dispatch);
  //       }else
  //         setActiveStep(activeStep+1);
  //     }else if(activeStep == 4){
  //       if(localStorage.getItem("oauth-step") !== "4"){
  //         setLoading(true);
  //         userUpdate(IrequestBody, setLoading,setActiveStep, setSignupProceed, activeStep, onLogin, dispatch);
  //       }else
  //         setActiveStep(activeStep+1);
  //     }else
  //       setActiveStep(activeStep+1);
  //   }
  // }

  const callNextUpdate = (e) => {
    // if(view == 'indivisual' && activeStep == 1){
    //     setActiveStep(3);
    // }else if(view == 'indivisual' && activeStep == 3){
    //     setLoading(true);
    //     userUpdate(UrequestBody, setLoading,setActiveStep, setSignupProceed, activeStep, ()=>{}, dispatch);
    // }else if(view == 'indivisual' && activeStep == 4){
    //     setLoading(true);
    //     userUpdate(IrequestBody, setLoading,setActiveStep, setSignupProceed, activeStep, onLogin, dispatch);
    // }else{
    let requestBody;
    if (img_id) {
      requestBody = {
        "company": {
          "name": companyName,
          "website": websiteURL,
          "size": size,
          "country": country
        },
        "workProfile": workprofile,
        "profilePic": img_id,
      }
    } else {
      requestBody = {
        "company": {
          "name": companyName,
          "website": websiteURL,
          "size": size,
          "country": country
        },
        "workProfile": workprofile,
      }
    }

    if (activeStep == 1) {
      if (companyName !== "" && size !== "" && country !== "" && websiteURL !== "")
        userUpdate(requestBody, setLoading, setActiveStep, setSignupProceed, activeStep, () => { }, dispatch);
      else
        alert("Details required");

    } else if (activeStep == 2) {
      if (workprofile !== "")
        userUpdate(requestBody, setLoading, setActiveStep, setSignupProceed, activeStep, () => { }, dispatch);
      else
        alert("Work profile required");

    } else if (activeStep == 3) {
      requestBody["isNew"] = false;
      userUpdate(requestBody, setLoading, setActiveStep, setSignupProceed, activeStep, onLogin, dispatch);
    } else
      setActiveStep(activeStep + 1);
  }

  const openGoogleOauth = (type) => {
    setLoading(true);
    setOauthType(type);
    openWindow = window.open(oAuthCall(), "_blank");
  }

  const handleChangeProfile = (e, v) => {
    setWorkProfile(v);
  }
  const getconfig = (i) => genConfig(i);

  const getForms = (step) => {
    let component = <></>;
    switch (step) {
      case 0:
        component = (
          <>
            <div className="wrapper-forms">
              <div className="login-form-img">
                <img className="img-fluid img-200" src="./assets/images/icon.png" alt="Theme-Logo" />
              </div>
              <div className="login-form-heading">
                <b className="login-form-heading-b">Start your free 14-day trial</b>
                <p className="login-form-heading-p">Full access · No credit card required</p>
              </div>
              {/* <div className="inputfeild-basic">
              <div className="inputfeild-basic-group">
                <TextField 
                  size="small"
                  className="input-custom-css" 
                  required 
                  type="email" 
                  id="outlined-basic" 
                  label="Enter your work email" 
                  variant="outlined"
                />
              </div>
              <div className="inputfeild-basic-group">
                <TextField
                  size="small"
                  className="input-custom-css" 
                  type="text" 
                  id="outlined-basic" 
                  label="Enter Fullname" 
                  variant="outlined" 
                />
              </div>
              <div className="inputfeild-basic-group">
                <TextField
                  size="small" 
                  className="input-custom-css" 
                  required 
                  type="text" 
                  id="outlined-basic" 
                  label="Enter Phone number" 
                  variant="outlined" 
                />
              </div>
              <div className="inputfeild-basic-group">
              <FormControl variant="outlined" size="small" className="input-custom-css" >
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput 
                  required 
                  size="small"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={(e) => setPassword(e.target.value)}
                  id="outlined-adornment-password"
                  label="Password" 
                 
                />
                </FormControl>
              </div>
            </div>
            <div className="divider-or-option">
              <span>OR</span>
            </div> */}
              <div style={{ marginTop: "60%" }}>
                <button className="inputfeild-basic-group g-signup" onClick={() => openGoogleOauth("signup")} disabled={loading}>
                  <Icon icon={"flat-color-icons:google"} className={"icon-actions-signup"} />
                  Continue with google {loading && <CircularProgress style={{ width: "20px", height: "20px" }} />}
                </button>
              </div>
              <div style={{ margin: "10px auto", fontSize: 12 }}>
                {/* <p>Already has an account? <a href="#" onClick={() => {localStorage.clear(); setLoginEnable(true)}}>Log in</a></p> */}
              </div>
            </div>
          </>
        );
        break;

      // case 1:
      //   component = (<>
      //   <div className="wrapper-forms">
      //     <div className="login-form-img">
      //       <img className="img-fluid img-200" src="./assets/images/icon.png" alt="Theme-Logo" /> 
      //     </div>
      //     <div className="login-form-heading">
      //         <b className="login-form-heading-b">Automate your localisation</b>
      //         <p className="login-form-heading-p">Are you a indivisual developer or an organization</p>
      //     </div>
      //     <div className="login-form-selction">
      //       <ToggleButtonGroup
      //         orientation="vertical"
      //         value={view}
      //         exclusive
      //         onChange={handleChange}
      //       >
      //       <ToggleButton value="indivisual" aria-label="indivisual">
      //         <div className="custom-radio-select" value="indivisual">
      //             <div className="custom-radio-select-icon-f">
      //               <i className="icofont icofont-user-alt-2"></i>
      //             </div>
      //             <div className="custom-radio-select-content">
      //               <b>I'm an indivisual</b>
      //               <p>I need to automate some basic localistaion</p>
      //             </div>
      //             <div className="custom-radio-select-content-action">
      //               {view == "indivisual"? 
      //               <i className="icofont icofont-check-circled" style={{fontSize: 20}}></i>
      //               :
      //               <i className="icofont icofont-close-circled" style={{fontSize: 20, color: 'white'}}></i>}
      //             </div>
      //         </div>
      //       </ToggleButton>
      //       <ToggleButton value="organisation" aria-label="modorganisationule">
      //         <div className="custom-radio-select">
      //             <div className="custom-radio-select-icon-f">
      //               <i className="icofont icofont-users-alt-2"></i>
      //             </div>
      //             <div className="custom-radio-select-content">
      //               <b>I'm an Organisation</b>
      //               <p>My team needs to automate the localisation</p>
      //             </div>

      //             <div className="custom-radio-select-content-action">
      //             {view == "organisation" ?
      //               <i className="icofont icofont-check-circled" style={{fontSize: 20}}></i>
      //               :
      //               <i className="icofont icofont-close-circled" style={{fontSize: 20, color: 'white'}}></i>}
      //             </div>
      //         </div>
      //       </ToggleButton>
      //       </ToggleButtonGroup>
      //     </div>
      //   </div>
      //   </>
      // );
      // break;

      case 1:
        component = (
          <>
            <div className="wrapper-forms">
              <div className="login-form-img">
                <img className="img-fluid img-200" src="./assets/images/icon.png" alt="Theme-Logo" />
              </div>
              <div className="login-form-heading">
                <b className="login-form-heading-b">Help us with your company</b>
                <p className="login-form-heading-p">Bear withus for a minute we are setting up! </p>
              </div>
              <div className="inputfeild-basic">
                <div className="inputfeild-basic-group">
                  <TextField
                    size="small"
                    className="input-custom-css"
                    required
                    type="text"
                    id="outlined-basic"
                    name="name"
                    value={companyName}
                    label="Enter your Team name"
                    variant="outlined"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="inputfeild-basic-group">
                  <FormControl variant="outlined" className="input-custom-css">
                    <InputLabel id="demo-simple-select-label" className="input-custom-css-label">Team Size</InputLabel>
                    <Select
                      id="demo-simple-select"
                      value={size}
                      style={{ height: 40 }}
                      label="Team Size"
                      name="size"
                      onChange={(e) => setSize(e.target.value)}
                    // onChange={handleChange}
                    >
                      <MenuItem value={10}>0 - 10 People</MenuItem>
                      <MenuItem value={50}>10 - 50 People</MenuItem>
                      <MenuItem value={100}>50 - 100 People</MenuItem>
                      <MenuItem value={250}>100 - 250 People</MenuItem>
                      <MenuItem value={500}>+ 500 People</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="inputfeild-basic-group">
                  <Autocomplete
                    id="country-select-demo"
                    sx={{ width: 300, height: 40 }}
                    className="input-custom-css"
                    options={countries}
                    value={country}
                    size="small"
                    onChange={(e, newValue) => setCountry(newValue)}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.label} ({option.code}) +{option.phone}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a country"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                  {/* <FormControl variant="outlined" className="input-custom-css">
                <InputLabel id="demo-simple-select-label" className="input-custom-css-label">Country</InputLabel>
                <Select
                  id="demo-simple-select"
                  value={country}
                  style={{height: 40}}
                  label="Organisation Location"
                  name="country"
                  onChange={(e) => setCountry(e.target.value)}
                  // onChange={handleChange}
                >
                  <MenuItem value={"India"}>India</MenuItem>
                  <MenuItem value={"USA"}>USA</MenuItem>
                  <MenuItem value={"Canada"}>Canada</MenuItem>
                  <MenuItem value={"Nepal"}>Nepal</MenuItem>
                  <MenuItem value={"UK"}>UK</MenuItem>
                </Select>
              </FormControl> */}
                </div>
                <div className="inputfeild-basic-group">
                  <TextField
                    size="small"
                    className="input-custom-css"
                    required
                    type="text"
                    id="outlined-basic"
                    label="Website URL"
                    variant="outlined"
                    name="website"
                    value={websiteURL}
                    onChange={(e) => setWebsiteURl(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </>
        );
        break;

      case 2:
        component = (
          <>
            <div className="wrapper-forms">
              <div className="login-form-img">
                <img className="img-fluid img-200" src="./assets/images/icon.png" alt="Theme-Logo" />
              </div>
              <div className="login-form-heading">
                <b className="login-form-heading-b">What kind of work do you do?</b>
                <p className="login-form-heading-p">Wow! you are on the edge 1 more to go. </p>
              </div>
              <div className="inputfeild-basic">
                <div className="inputfeild-basic-group d-flex-grid">
                  <ToggleButtonGroup
                    orientation="vertical"
                    value={workprofile}
                    className="toggle-grid-st"
                    exclusive
                    onChange={handleChangeProfile}
                  >
                    <ToggleButton value="software_engineer" aria-label="indivisual">
                      <div className="custom-radio-select-profession" value="indivisual">
                        <div className="custom-radio-select-content">
                          <Icon className="icon-toogle-button" icon="ant-design:setting-twotone" />
                          <b>Software engineer</b>
                        </div>
                      </div>
                    </ToggleButton>
                    <ToggleButton value="localization_manager" aria-label="modorganisationule">
                      <div className="custom-radio-select-profession">
                        <div className="custom-radio-select-content">
                          <Icon className="icon-toogle-button" icon="icons8:translation" />
                          <b>Localization manager</b>
                        </div>
                      </div>
                    </ToggleButton>
                    <ToggleButton value="translator" aria-label="modorganisationule">
                      <div className="custom-radio-select-profession">
                        <div className="custom-radio-select-content">
                          <Icon className="icon-toogle-button" icon="arcticons:libretranslater" />
                          <b>Translator</b>
                        </div>
                      </div>
                    </ToggleButton>
                    <ToggleButton value="student" aria-label="modorganisationule">
                      <div className="custom-radio-select-profession">
                        <div className="custom-radio-select-content">
                          <Icon className="icon-toogle-button" icon="ph:student-fill" />
                          <b>Student</b>
                        </div>
                      </div>
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <ToggleButtonGroup
                    orientation="vertical"
                    value={workprofile}
                    className="toggle-grid-st"
                    exclusive
                    onChange={handleChangeProfile}
                  >
                    <ToggleButton value="product_manager" aria-label="indivisual">
                      <div className="custom-radio-select-profession" value="indivisual">
                        <div className="custom-radio-select-content">
                          <Icon className="icon-toogle-button" icon="ic:outline-hub" />
                          <b>Product manager</b>
                        </div>
                      </div>
                    </ToggleButton>
                    <ToggleButton value="designer" aria-label="modorganisationule">
                      <div className="custom-radio-select-profession">
                        <div className="custom-radio-select-content">
                          <Icon className="icon-toogle-button" icon="fluent:design-ideas-20-regular" />
                          <b>Designer</b>
                        </div>
                      </div>
                    </ToggleButton>
                    <ToggleButton value="content_writer" aria-label="modorganisationule">
                      <div className="custom-radio-select-profession">
                        <div className="custom-radio-select-content">
                          <Icon className="icon-toogle-button" icon="fluent:content-view-20-regular" />
                          <b>Content writer</b>
                        </div>
                      </div>
                    </ToggleButton>
                    <ToggleButton value="other" aria-label="modorganisationule">
                      <div className="custom-radio-select-profession">
                        <div className="custom-radio-select-content">
                          <Icon className="icon-toogle-button" icon="bxs:coffee-bean" />
                          <b>Other</b>
                        </div>
                      </div>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
            </div>
          </>
        );
        break;

      case 3:
        component = (
          <>
            <div className="wrapper-forms">
              <div className="login-form-img">
                <img className="img-fluid img-200" src="./assets/images/icon.png" alt="Theme-Logo" />
              </div>
              <div className="login-form-heading">
                <b className="login-form-heading-b">Have some fun select your avatar 🧑‍💻</b>
                <p className="login-form-heading-p">I sure this will be last step. </p>
              </div>
              <div className="inputfeild-basic">
                <div className="toggle-avatar-wrapper">
                  <RadioGroup className="toggle-avatar"
                    value={avatar}
                    onChange={handleRadioChange}>
                    <FormControlLabel value="g-avatar" control={<Radio className="radio-cus-avatar" />} label={
                      <div className="icon-avatr-selected-wrapper" onClick={(e) => capu("g-avatar")} id="g-avatar">
                        {avatar == `g-avatar` && <i className="icofont icofont-check-circled" style={{ fontSize: 20 }}></i>}
                        <img referrerpolicy="no-referrer" className="avt-gmail-id" src={profileImage} size="90" round={true} />
                      </div>
                    } />
                    {configs?.map((d, i) =>
                      <FormControlLabel key={i} value={`${i}avatar`} control={<Radio className="radio-cus-avatar" />} label={
                        <div className="icon-avatr-selected-wrapper" onClick={(e) => capu(`${i}avatar`, d.id)} id={`${i}avatar`} >
                          {avatar == `${i}avatar` && <i className="icofont icofont-check-circled" style={{ fontSize: 20 }}></i>}
                          <Avatar className="avatar-gen-custo-override" style={{ width: '90px', height: '90px' }} {...getconfig(d)} />
                        </div>
                      } />
                    )}
                  </RadioGroup>
                </div>
              </div>
            </div>
          </>
        );
        break;

      default:
        component = <></>;
        break;
    }
    return component;
  }

  return (
    <section className="login-block">
      <SnackbarAlert />
      <div className="container-fluid d-flex" style={{ height: "100vh", overflow: "hidden", padding: 0 }}>
        <div className="col-sm-30-pr showhide" style={{ height: "100%", padding: 0 }}>
          {/* {loginEnable ? 
             <Login setLoginEnable={setLoginEnable} login={onLogin} openGoogleOauth={openGoogleOauth} loading={loading}/>
             : */}
          <div className="container-form-wrapper">
            <div className="container-form">
              {getForms(activeStep)}
              {activeStep !== 0 && <div className="form-navigator-button-wrap">
                <div className="form-navigator-button">
                  <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                    if (view == 'indivisual' && activeStep == 3) {
                      setActiveStep(1);
                    } else {
                      setActiveStep(activeStep - 1);
                    }
                  }} variant="none" disabled={activeStep === 0} startIcon={<KeyboardBackspace />}>
                    Back
                  </Button>
                  <Button style={{ border: "none", outline: "none" }} variant="none"
                    onClick={(e) =>
                      callNextUpdate(e)
                    } disabled={!signupProceed} endIcon={<KeyboardBackspace style={{ transform: "rotate(180deg)" }} />}>
                    {activeStep == 4 ? "Finish" : "Next"} {loading && <CircularProgress style={{ width: "20px", height: "20px" }} />}
                  </Button>
                </div>
              </div>}
            </div>
            {activeStep !== 0 && <div>
              <MobileStepper
                variant="dots"
                steps={tSteps}
                position="static"
                activeStep={view == 'indivisual' && activeStep > 2 ? activeStep - 1 : activeStep}
                sx={{ maxWidth: 400, flexGrow: 1 }}
              />
            </div>
            }
          </div>
        </div>
        <div className="col-sm-70-pr back-img" style={{ height: "100%", overflow: "hidden" }}>
          <div className="quotes-back">
            <p className="quotes-text">
              {quotes?.value}
            </p>
            <p className="quotes-text-by">
              -- {quotes?.by}
            </p>
          </div>
          <div className="imag-login-block">
            <img src={imgSl} className="incline-poster-login" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignUp;
