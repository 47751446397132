import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../common/Footer'
import SnackbarAlert from "../common/Snackbar";

const Blank = (props) => {
    
    return (
      <div id="pcoded" className="pcoded snakbar-wrapper">
        <SnackbarAlert />
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">
          <div className="pcoded-main-container">
            <div className="background-dashboard">
                <div><Outlet /></div>
                <Footer />
            </div>
          </div>
        </div>
      </div>
    );
}

export default Blank