import React, { useEffect, useState } from "react";
import Avatar, { genConfig } from "react-nice-avatar";
import { defaultAvatar } from "../Constant/Avatar";
import { Popover } from "@mui/material";
import {singOut} from '../Services/Networks/Oauth'
import { useSelector } from "react-redux";
import { oAuthSlackConfigs } from "../Services/Networks/Integration";
import Feedback from "./Feedback";

const Header = () => {

  const userData = JSON.parse(localStorage.getItem("userObj"));
  const userDataS = useSelector(state => state.user);
  const [ openBug, setOpenBug ] = useState(false);
  const projectDetails = useSelector(state => state?.project?.selectedProject);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    oAuthSlackConfigs(projectDetails?.id, false)
  }, [projectDetails]);
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const getProfileImage = () => {
    const img = defaultAvatar?.filter(data => data?.url === userData?.profilePicture || data?.url === userDataS?.profilePic);
    
    if(img.length !== 0){
      const config = genConfig(img[0])
      return <Avatar className="avatdar-gen-custo-override"  style={{ width: '45px', height: '45px', marginTop: 5 }} {...config} />
    }else{
      return <img referrerpolicy="no-referrer" src={userData?.profilePicture} className="img-radius"  alt="User-Image" />
    }
  }

  return (
    <nav className="navbar header-navbar pcoded-header noprint" id="ad-adda" >
      <div className="navbar-wrapper">
      <Feedback open={openBug} handleClose={() => setOpenBug(false)}/>
        <div className="navbar-container container-fluid align-items-center">
          <ul className="nav-left mt-3 align-items-center">
            <li className="ml-3 user-profile header-notification">
              {/* <h6>{projectName}</h6> */}
              {/* <select
                style={{ width: 300, fontWeight: 600 }}
                name="cruise_owner_admin_owner_id"
                className="form-control w-300 select-proj"
              // value={this.state.cruise_owner_admin_owner_id}
              // onChange={this.handleChange}
              >
                <option>Select Project</option>
                {companyData?.length > 0 && companyData?.map(d => <option>Proj-#Revo video</option>)}
              </select> */}
            </li>
            {/* <li className="ml-3 user-profile header-notification">
                <select
                  name="cruise_owner_admin_owner_id"
                  className="form-control"
                  style={{width: 300}}
                  // value={this.state.cruise_owner_admin_owner_id}
                  // onChange={this.handleChange}
                >
                  <option>Select Version</option>
                  <option>Select Owner</option>
                  <option>Select Owner</option>
                </select>
              </li> */}
          </ul>
          <ul className="nav-right">
            <li>
              <span> <b>Welcome back! </b> {userDataS?.name ?? "Jhon Doe"}</span>
              <button className="mx-3 bg-transparent border-0" dataToggle="tooltip" title="Notification">
                <i className="feather icon-bell" style={{ color: "#6366f1" }}></i>
              </button>
            </li>
            <li className="wt-drop user-profile header-notification"  aria-describedby='header-menu' onClick={handleClick}>
              {getProfileImage()}
            </li>
            <Popover
                id='header-menu'
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                // className='drop_down_headers'
              >
                <div className="drop_down_headers">
                  <div className="span-email-header">Signed in as <b>{userData?.email}</b></div>
                  <a href="/dashboard" style={{margin: 0, padding: 0, color: "#262626", width: "100%"}}><div>Dashboard</div></a>
                  <a href="https://docs.vernacle.in" target="_blank" style={{margin: 0, padding: 0, color: "#262626", width: "100%"}} rel="noreferrer"><div>Documentation</div></a>
                  <a onClick={() => {handleClose(); setOpenBug(true)}} style={{margin: 0, padding: 0, color: "#262626", width: "100%", cursor: "pointer"}}><div style={{color: "red"}}>Raise bugs *</div></a>
                  {/* <div>Profile Settings</div> */}
                  {/* <div>Account settings</div> */}
                  <div onClick={() => singOut()} style={{cursor: "pointer"}}>Logout</div>
              </div>
              </Popover>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;
