import { Alert, Snackbar } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HANDLE_SNACKBAR } from '../Redux/slices/SnackbarAlert';

function SnackbarAlert() {
  const dispatch = useDispatch();
  const snackInfo = useSelector(state => state.snackbar);
  
  return (
    <div className='custom-alert-snackbar'>
        <Snackbar open={snackInfo?.open} autoHideDuration={4000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => {
            dispatch(HANDLE_SNACKBAR({
                open: false,
                message: "",
                type: "success"
            }));
        }}>
        <Alert 
          onClose={() => {
            dispatch(HANDLE_SNACKBAR({
                open: false,
                message: "",
                type: "success"
            }));
          }} 
          severity={snackInfo?.type} 
          sx={{ width: '100%' }}
          >
            {snackInfo?.message}
        </Alert>
        </Snackbar>
    </div>
  )
}

export default SnackbarAlert