import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Icon } from '@iconify/react';
import { ButtonGroup, ClickAwayListener, Divider, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import { NOTIFICATION_TYPE, slackData } from '../Constant/Integrations';
import { Link } from 'react-router-dom';
import { ArrowDropDown } from '@mui/icons-material';
import { getWindowTab, integrateInit, oAuthSlackConfigs } from '../Services/Networks/Integration';
import { useSelector } from 'react-redux';
import { store } from '../store';
import { SET_TYPE } from '../Redux/slices/Slack_notification';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const options = [{
    label: 'Configure',
    value: ''
  },{
    label: 'Enable Comments & Keys Notification',
    value: NOTIFICATION_TYPE.INFO_COMMENTS_AND_KEY_VALUE
  },{
    label: 'Enable Console & accounts Notification',
    value: NOTIFICATION_TYPE.INFO_CONSOLE
  },{ 
    label: 'Enable System Notification',
    value: NOTIFICATION_TYPE.INFO_SYSTEM
  }];

export function IntegrationDialog({ handleClose, open }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [openPop, setOpenPop] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const notificationDetails = useSelector(state => state?.slackconfig?.data);

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };
  const notificationData = useSelector(state => state?.slackconfig);
  var nIntervId="";
  if(notificationData?.types?.trigger == true){
    nIntervId = setInterval(() => {
        if(getWindowTab()){
            handleClose();
            store.dispatch(SET_TYPE({
              trigger: false
            }));
            clearInterval(nIntervId);
            // oAuthSlackConfigs(projectId, true, nIntervId, setIsLoading);
        }
    }, 1000);
}

  const handleMenuItemClick = (event, index, value) => {
    setSelectedIndex(index);
    setOpenPop(false);
    
    if(value !== "")
      integrateInit({ title: "Slack" }, setIsLoading, () => {}, value, notificationDetails)
  };

  const handleToggle = () => {
    setOpenPop((prevOpen) => !prevOpen);
  };

  const handleClosePop = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenPop(false);
  };
  const data = slackData.definition
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="md"
      fullWidth
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className='flex-sb'>
        <span>{data.title}</span>
        <Icon icon={"eva:close-fill"} onClick={handleClose} style={{ fontSize: 30, cursor: "pointer" }} />
      </DialogTitle>
      <Divider />
      <DialogContent className=''>
        <div className='flex-sb mb-2'>
          <div className='flex-fs'>
            <div>
              <img src={data?.logo} width="90" />
            </div>
            <div className='mx-2'>
              <h2>{data.title}</h2>
              <span style={{ color: "grey" }}>by {data.author}. {data.categories[0].name}</span>
            </div>
          </div>
          <div>
            {/* <Button disabled={isLoading} className='my-2' variant='contained' onClick={() => integrateInit(data, setIsLoading, handleClose)} style={{textTransform: "capitalize"}} >
                {isLoading ?  "Initiating..." : "Configure"}
              </Button><br/> */}
              <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                <Button onClick={handleClick}>{options[selectedIndex].label}</Button>
                <Button
                  size="small"
                  aria-controls={openPop ? 'split-button-menu' : undefined}
                  aria-expanded={openPop ? 'true' : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                >
                  <Icon icon={"gridicons:dropdown"} width={24} style={{color: 'white'}}/>
                </Button>
              </ButtonGroup>
              <Popper
                sx={{
                  zIndex: 1,
                }}
                open={openPop}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClosePop}>
                        <MenuList id="split-button-menu" autoFocusItem>
                          {options.map((option, index) => (
                            <MenuItem
                              key={option.value}
                              disabled={index == 0}
                              onClick={(event) => handleMenuItemClick(event, index, option.value )}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
              <br/><Link>Learn more</Link>
          </div>
        </div>
        <div className='my-4'>
          <b>About Slack</b>
          <p>{data.about}</p>
        </div>
        <div className='my-4'>
          <b>Key features</b>
          {data.keyFeatures?.map(d => <li key={d}>{d}</li>)}
        </div>
      </DialogContent>
    </Dialog>
  );
}
