import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "slack-intigration",
  initialState: {},
  reducers: {
    GET_SLACKCONFIG_LIST: (data, action) => {
      return { ...data, data: action.payload };
    },
    SET_TYPE: (data, action) => {
      return { ...data, types: action.payload };
    },
  },
});

export const { GET_SLACKCONFIG_LIST, SET_TYPE } = slice.actions;

export default slice.reducer;
