import { CheckOutlined, CloseRounded } from '@mui/icons-material';
import { Button, ButtonGroup, Card, CardContent, Typography } from '@mui/material'
import React from 'react'
import Avatar, { genConfig } from 'react-nice-avatar';
import { useDispatch, useSelector } from 'react-redux';
import { defaultAvatar } from '../Constant/Avatar';
import Logo from '../Assets/images/icon.png';
import { Icon } from '@iconify/react';
// import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'

function Invitation() {
    const dispatch = useDispatch();
    // const userData = JSON.parse(localStorage.getItem("userObj"));
    const userData = useSelector(state => state.user);
    const projectListData = useSelector(state => state.project?.data);
    const { width, height } = window.screen
    
    const getProfileImage = () => {
        const img = defaultAvatar?.filter(data => data.url === userData?.profilePic || data.url === userData?.profilePicture);
        
        if(img.length !== 0){
          const config = genConfig(img[0])

          return <Avatar className="avatar-gen-custo-override" style={{ width: '60px', height: '60px', border: "3px solid #a795e221", borderRadius: 6, marginTop: "30px" }} {...config} />
        }else{
          return <img referrerpolicy="no-referrer" alt="User-Image" src={userData?.profilePicture} className="img-radius" style={{width: '60px', height: '60px', border: "3px solid #a795e221", borderRadius: 6, marginTop: "30px"}}/>
        }
    }
    return (
    <div className='main-pcoded-card'>
        <Confetti
            width={width}
            height={height}
            opacity={.9}
            // drawShape={ctx => {
            //     ctx.beginPath()
            //     for(let i = 0; i < 22; i++) {
            //       const angle = 0.35 * i
            //       const x = (0.2 + (1.5 * angle)) * Math.cos(angle)
            //       const y = (0.2 + (1.5 * angle)) * Math.sin(angle)
            //       ctx.lineTo(x, y);
            //     }
            //     ctx.stroke()
            //     ctx.closePath()
            // }}
        />
        <div className='inner-main-pcoded-card'>
        <div className='card-back-animation'></div>
            <Card className='card-dashboard-custom invite-cus' style={{ position: "relative"}}>
                <div className='icon-bg-card-invite'></div>
                <CardContent style={{zIndex: 10}}>
                    <div className='d-flex flex-c my-2'>
                        <div style={{border: "3px solid #a795e221", borderRadius: 6, width: 60, height: 60,marginTop: "-60px"}}>
                            <img className="img-fluid img-0" src={Logo} style={{width: 80, height: 50, overflow: "hidden"}} alt="Vernacle" /> 
                        </div>
                        <Icon icon="teenyicons:curved-connector-solid" style={{ fontSize: 50, color: "#a795e267" }}/>
                        {getProfileImage()}
                    </div>
                    <Typography sx={{ fontSize: 18, fontWeight: 700, marginTop: 3 }} color="text.secondary" gutterBottom>
                        You are invited!
                    </Typography>
                    <div className='p-2' style={{background: "#a795e221", borderRadius: 4, width: "80%", margin: "20px auto"}}>
                        <Typography sx={{ fontSize: 14}} color="text.secondary" gutterBottom>
                            You've been invited to join <b>test-abc</b> as an admin by <b>Nitesh sigh</b>!
                        </Typography>
                    </div>
                    <div className='mt-3'>
                        <div>
                            <Button variant='outlined' sx={{ marginRight: 3, borderRadius: 20, cursor: "pointer" }}>
                            <CloseRounded sx={{ fontSize: 16}}/> Cancel
                            </Button>
                            <Button variant='contained' className='btn-as-invite' sx={{borderRadius: 20, cursor: "pointer"}}>
                                <CheckOutlined color='white' sx={{ fontSize: 16, color: "white"}} /> Accept
                            </Button>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    </div>
  )
}

export default Invitation