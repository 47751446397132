export const arrayQuotes = [{
    id:1,
    value: "“Most of all, I discovered that in order to succeed with a product you must truly get to know your customers and build something for them.”",
    by: " Marc Benioff"
  },
  {
    id:2,
    value: "“Products tend to succeed thanks to a single core use case that really mattered to users”",
    by: "Othman Laraki"
  },{
    id:3,
    value: "“Warning signs that your product sucks: “I’m really busy right now but I’ll start using your app soon.”",
    by: "David Cancel"
  },
  {
    id:4,
    value: "“Working hard, that’s the only thing I know better than my competitor. If my competitors say, “I work eight hours a day,” then I can work 10 hours. If you don’t need sleep, I also do not need sleep.”",
    by: "Eric Yuan"
  }
  ]