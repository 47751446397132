import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Icon } from '@iconify/react';
import { Autocomplete, Box, Chip, Divider, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField, Tooltip, tooltipClasses } from '@mui/material';
import { abbreviationLang } from '../../Constant/Language';
import { updateProject } from '../../Services/Networks/Projects';
import { useSelector } from 'react-redux';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LangaugeAdd({handleClose, open}) {
  const [onerror, setOnerror] = React.useState("");
  const [langaugeList, setLangauges] = React.useState([]);
  const [isUpdating, setUpdating] = React.useState(false);
  const [valueLocal, setValueLocal] = React.useState([]);
  const projectDetails = useSelector(state => state?.project?.selectedProject);

  const handleChangeLanguage = (event, newValue) => {
    let locale_payload = [];
    let baseLanguage = '';
    projectDetails?.locales?.forEach((d) => d.isDefault == true ? baseLanguage = d.locale : '' );
    newValue.forEach((d) => {
      if(baseLanguage == d.split("- ")[1])
        locale_payload.push({locale: d.split("- ")[1] , isDefault: true})
      else
        locale_payload.push({locale: d.split("- ")[1] , isDefault: false})
    })
    setLangauges([...newValue]);
    setValueLocal([...locale_payload]);
  };
   
  React.useEffect(() => {
    let map = [];
    projectDetails?.locales?.map(d => map.push(d.locale));
    if(projectDetails){
      let arr = [];
      abbreviationLang?.map((option) => {
        if(map.includes(option.abbreviation)){
          arr.push(`${option?.locale_name} - ${option?.abbreviation}`);
        }
      });
      setLangauges([...arr]);
    }
  },[projectDetails])

  const updateProjectDetails = () => {
  if(valueLocal?.length > 0){
    const payload = {
    projectId: projectDetails?.id,
      payload: {
          projectName: projectDetails.name,
          locales: valueLocal
      }
    }
    updateProject(payload, setUpdating, projectDetails, handleClose, () => window.location.reload())
  }else{
    setOnerror('No changes made so far!')
  }
  }
  return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle  className='flex-sb'>
            <span>New Langauge</span>
            <Icon icon={"eva:close-fill"} onClick={() => {
                setOnerror("")
                handleClose()
            }} style={{fontSize: 30, cursor: "pointer" }}/>
        </DialogTitle>
        <Divider />
        <DialogContent>
           <div>
            <div className="form-group col" style={{width: "100%"}}>
                <label className="col-form-label">
                    <span>Language</span><span>*</span>
                </label>
                <div className=""></div>
                <Autocomplete
                  size='small'
                  multiple
                  error={onerror?.length>0}
                  value={langaugeList}
                  onChange={(event, newValue) => {
                    handleChangeLanguage(event, newValue);
                    setOnerror("");
                  }}
                  options={abbreviationLang.map((option) => `${option?.locale_name} - ${option?.abbreviation}`)}
                  // options={abbreviationLang}
                  // getOptionLabel={(option) => <>{option?.locale_name} -- <span style={{color: "grey", textTransform: "uppercase"}}> {option?.abbreviation}</span></>}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        style={{borderRadius: 4}}
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  style={{width: 500 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Languages" placeholder="Languages" error={onerror.length > 0} />
                  )}
                />
                <span style={{color: "red"}}>{onerror?.length > 0 && onerror}</span>
            </div>
           </div>
        </DialogContent>
        <DialogActions className='flex-sb flex-sb-webkit'>
          <div className='btm-check-foradd'>
          </div>
          <div>
            <Button onClick={handleClose} style={{color: "#6366f1", borderColor: "#6366f1", marginRight: 5}} variant="outlined">Cancel</Button>
            <Button onClick={() => {
                  updateProjectDetails();
            }} variant="contained" disabled={isUpdating} style={{background: "#6366f1"}} >{isUpdating ? "Saving..." : "Save"}</Button>
          </div>
          </DialogActions>
      </Dialog>
  );
}
