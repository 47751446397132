import axios from "axios"
import { HANDLE_SNACKBAR } from "../../Redux/slices/SnackbarAlert"
import { USER_DETAILS } from "../../Redux/slices/userSlice"
import { store } from "../../store"
import { apiGateway } from "../../utils/constant/apigateways"
import { ResponseSuccess } from "../../utils/Response"

export const oAuthCall = () => {
   return `${apiGateway}/auth/google/authorize`
}

export const singOut = () => {
   localStorage.clear();
   window.location.href = "/"
   window.location.reload();
 };

export const verifyToken = (setLoading, setActiveStep, setSignupProceed, setView, setProfileImage, onLogin, setLoginEnable, oauthType, setOauthType) => {
   localStorage.setItem("oauth-redirect", false);
   axios.get(apiGateway + "/user/verify",{
      headers: {
         "Content-Type": "application/json",
         "Authorization": `Bearer ${localStorage.getItem('token-le-wegid')}`
      }
   })
   .then(res => {
      if(ResponseSuccess(res).status == true){
         setLoading(false);
         if(ResponseSuccess(res).response?.isNew == true){
               setLoginEnable(false);
               if(localStorage.getItem("oauth-step")) {
                  setActiveStep(parseFloat(localStorage.getItem("oauth-step"))+1);
                  setView();
               }else{
                  setActiveStep(1);
               }
               setSignupProceed(true);
               setProfileImage(ResponseSuccess(res).response?.profilePicture);
               localStorage.setItem("userObj",  JSON.stringify(ResponseSuccess(res).response));
               localStorage.setItem("oauth-incomplete", true);
         }else{
            localStorage.setItem("userObj", JSON.stringify(ResponseSuccess(res).response))
            onLogin();
         }
         store.dispatch(USER_DETAILS(ResponseSuccess(res).response))

      }else{
         store.dispatch(HANDLE_SNACKBAR({
            open: true,
            message: "Oops! Something went wrong",
            type: "error"
         }));
         setLoading(false);
         setActiveStep(0)
      }
   })
   .catch(() => {
      store.dispatch(HANDLE_SNACKBAR({
         open: true,
         message: "Oops! Something went wrong",
         type: "error"
      }));
      setLoading(false);
      setActiveStep(0)
   })
}

export const userUpdate = (requestBody, setLoading, setActiveStep, setSignupProceed, activeStep, onLogin, dispatch) => {
   setLoading(true);   
   axios.patch(apiGateway + "/user", 
      requestBody, {
      headers: {
         "Content-Type": "application/json",
         "Authorization": `Bearer ${localStorage.getItem('token-le-wegid')}`
      }
   })
   .then(res => {
      if(ResponseSuccess(res).status == true){
         setLoading(false);
         if(activeStep == 3){
            localStorage.removeItem("oauth-step", activeStep);
            localStorage.setItem("oauth-incomplete", false);
            onLogin();
         }else{
            localStorage.setItem("oauth-incomplete", true);
            localStorage.setItem("oauth-step", activeStep);
            setActiveStep(activeStep+1);
         }
         dispatch(USER_DETAILS(ResponseSuccess(res).response))
      }else{
         store.dispatch(HANDLE_SNACKBAR({
            open: true,
            message: "Oops! Something went wrong",
            type: "error"
         }));
         setLoading(false);
         setActiveStep(activeStep)
      }
   })
   .catch(() => {
      store.dispatch(HANDLE_SNACKBAR({
         open: true,
         message: "Oops! Something went wrong",
         type: "error"
      }));
      setLoading(false);
      setActiveStep(activeStep)
   })
}