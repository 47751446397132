import { combineReducers } from "redux";
import user from "./slices/userSlice";
import company from "./slices/companySlice";
import project from "./slices/ProjectSlice";
import snackbar from "./slices/SnackbarAlert";
import keys from './slices/keys';
import comments from './slices/commentsSlice';
import slackconfig from './slices/Slack_notification';

const rootReducer = combineReducers({
  user,
  company,
  project,
  snackbar,
  keys,
  comments,
  slackconfig
});

export default rootReducer;
