import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, CircularProgress, Divider, IconButton,List, TextField, Tooltip } from "@mui/material";
import ICON from '../../Assets/images/space.png';
import { Link } from "react-router-dom";
import { SendOutlined, MoreHoriz } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { commentList, deleteComments, postComments, updateComments } from "../../Services/Networks/Comments";
import { Icon } from "@iconify/react";
import { NOTIFICATION_TYPE } from "../../Constant/Integrations";
import { getStatus } from "../../Services/Networks/Integration";

const Comments = ({ onClose, selectedKey, data, timeAgo }) => {
    
    const notificationDetails = useSelector(state => state?.slackconfig?.data);
    const projectID = useSelector(state => state?.project?.selectedProject?.id);
    const userName = useSelector(state => state?.user?.name);
    const slackInfo = useSelector(state => state?.slackconfig?.data?.slackInfo);
    const commentHistory = useSelector(state => state?.comments?.data);
    const [comment, setComment] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [isComtLoading, setIscmtLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [commentEdit, setCommentEdit] = useState("");
    const [newComment, setNewComment] = useState("");
    const [resolved, setResolved] = useState(false);
    const timeDiff = (tx) => {
        return timeAgo.format(tx)
    }

    useEffect(() => {
        getComment();
    },[]);

    const sendMessage = () => {
        if(comment?.length > 0){
            const payload = {
                "projectId": projectID,
                "keyId": selectedKey.key,
                "value": comment,
                "mentionedUserIds": ["dolar"]
            };
            if(!isLoading)
                postComments(setLoading, payload, setComment, slackInfo, getComment)
        }
    }

    const deleteCommentsApi = (id) => {
        const payload = {
            projectId: projectID,
            keyId: selectedKey.key,
            commentId: id
        };
        deleteComments(setIsDeleting, payload, getComment)
    }

    const isResolvedApi = (id) => {
        const payload = {
            projectId: projectID,
            keyId: selectedKey.key,
            commentId: id,
            isResolved: true
        };
        updateComments(setIsDeleting, payload, getComment)
    }

    const editMessage = (id) => {
        const payload = {
            projectId: projectID,
            keyId: selectedKey.key,
            commentId: id,
            value: newComment
        };
        updateComments(setIsDeleting, payload, getComment);
        setCommentEdit("");
    }

    const getComment = () => {
        commentList(setIscmtLoading, projectID, selectedKey.key, resolved);
    }
    
    return (
        <Box
            sx={{ width: 350, height: "100%" }}
            role="presentation"
            //   onClick={() => onClose(false)}
            //   onKeyDown={() => onClose(false)}
        >
        <div className="comments-wrapper">
            <div style={{}}>
                <List className="">
                    <div className="px-2 py-3">
                        <div className="d-flex justify-content-sm-between">
                            <h4>Comments</h4> 
                            <Icon icon="material-symbols:close-rounded" style={{fontSize: 24, cursor: "pointer"}} onClick={() => onClose()}/>
                        </div>
                        <p className="d-flex align-items-center justify-content-c-between" style={{margin: 0}}>key <p style={{margin: 0, marginLeft: 5, color: "blue", fontSize: 12}}>{selectedKey.key}</p></p>
                        <p className="d-flex align-items-center justify-content-c-between" style={{margin: 0}}>Description  <p style={{margin: 0, marginLeft: 5, color: "blue", fontSize: 12}}>{selectedKey.value.description}</p></p>
                        {/* <p className="d-flex align-items-center justify-content-c-between mb-0">Hindi :- <p style={{margin: 0, marginLeft: 5, color: "blue", fontSize: 12}}></p></p> */}
                        <Divider className="py-1"/>
                        <div className="d-flex float-right">
                            <Button onClick={() => setResolved(!resolved)} style={{ padding: 3, marginTop: 5, fontSize: 12, textTransform: "capitalize" }} variant="outlined">Resolved Comments</Button>
                            {/* <Icon icon="ic:outline-filter-alt" style={{fontSize: 22, cursor: "pointer"}}/> */}
                        </div>
                    </div>
                </List>
                <div className="comment-box-inner">
                {commentHistory && commentHistory?.length > 0 ?
                    <div className="comment-list-col">
                        {!getStatus(notificationDetails, NOTIFICATION_TYPE.INFO_COMMENTS_AND_KEY_VALUE)  && <div className="p-4 comments-setting-control">
                                <b>Control your notifications</b>
                                <p>Not Getting notification about comments? A quick visit to our <Link to={`/home/${projectID}/settings?tab=notification`}>settings page</Link> can help you out with that.</p>
                            </div>
                         }
                        {commentHistory?.map((mes, id) => 
                            <div className="comment-each-div d-flex" key={id}>
                                <div className="mr-2">
                                    <Avatar sx={{ bgcolor: "#01a9ac", fontSize: 22, width: 40, height: 40 }}>{userName[0]}</Avatar>
                                </div>
                                <div style={{width :"100%"}}> 
                                    <div className="d-flex align-items-center justify-content-sm-between pos-relative" >
                                        <b>{userName}</b>
                                        <div className="hover-gs-comments">
                                            <Icon icon="mdi:dots-horizontal" style={{fontSize: 18, cursor: "pointer"}}/>
                                            <div className="drop-down-comments pos-absolute t-0 right-0">
                                                <ul className="d-flex p-0">
                                                    <li onClick={() => deleteCommentsApi(mes?.commentId)}>
                                                        <Tooltip title="Delete" arrow placement="bottom">
                                                            <Icon icon="ic:round-delete-outline" style={{fontSize: 18, cursor: "pointer"}} />
                                                        </Tooltip>
                                                    </li>
                                                    {mes?.isResolved != true && <li onClick={() => {
                                                            setCommentEdit(mes?.commentId);
                                                            setNewComment(mes?.value)
                                                        }}>
                                                        <Tooltip title="Edit" arrow placement="bottom">
                                                            <Icon icon="material-symbols:edit-outline-rounded" style={{fontSize: 18, cursor: "pointer"}}/>
                                                        </Tooltip>
                                                    </li>
                                                    }
                                                    <li onClick={() => mes?.isResolved != true ? isResolvedApi(mes?.commentId): ()=>{}} style={{ cursor:  mes?.isResolved == true ? "not-allowed" : "pointer"}}>
                                                        <Tooltip title={mes?.isResolved != true ? "Mark Resolved" : "Mark Unresolved"} arrow placement="bottom">
                                                            <Icon icon={mes?.isResolved ? 'ic:round-remove-done' : "icon-park:done-all"} color="red" style={{fontSize: 18}}/>
                                                        </Tooltip>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="m-0"><span>{timeDiff(mes?.updatedAt)}</span> . <span>{selectedKey.key}</span></p>
                                    {mes?.commentId !== commentEdit ? <p className="m-0 mt-2">{mes.value}</p> :
                                        <div>
                                            <div className="p-1 d-flex justify-content-c-between">
                                                <input value={newComment} placeholder="Type your comments" style={{minWidth: "88%", height: 40}} onChange={(e) => setNewComment(e.target.value)} onKeyPress={(e) => e.key == 'Enter' ? editMessage(mes?.commentId) : () => {}}/>
                                                <IconButton edge="end" disabled={isLoading} style={{borderRadius: 0, border: "none"}} onClick={() => editMessage(mes?.commentId)}>
                                                    {isLoading ? <MoreHoriz /> : <SendOutlined />}
                                                </IconButton>
                                            </div>
                                            <span className="float-right" style={{fontSize: 14, cursor: "pointer"}} onClick={() => setCommentEdit("")}>Cancel</span>
                                        </div>
                                    }
                                </div> 
                            </div>
                        )}
                    </div>
                        :
                        isComtLoading ? <div className="d-flex align-item-center">
                                <CircularProgress style={{ width: 20, height: 20}}/>
                            </div> : 
                            <div className="d-flex flex-column">
                            <div className="p-4 comments-nothing-found">
                                <img className="img-fluid img-200" src={ICON} alt="Theme-Logo" /> 
                                <br/>
                                <br/>
                                <b>There are nothing to show yet</b>
                                <p>Add a new comments.</p>
                            </div>
                            {!getStatus(notificationDetails, NOTIFICATION_TYPE.INFO_COMMENTS_AND_KEY_VALUE) && <div className="p-4 comments-setting-control">
                                <b>Control your notifications</b>
                                <p>Not Getting notification about comments? A quick visit to our <Link to={`/home/${projectID}/settings?tab=notification`}>settings page</Link> can help you out with that.</p>
                            </div>
                         }
                    </div>
                }
                </div>
            </div>
            <div className="p-2 d-flex justify-content-c-between">
                <TextField value={comment} placeholder="Type your comments" style={{minWidth: "88%"}} onChange={(e) => setComment(e.target.value)} onKeyPress={(e) => e.key == 'Enter' ? sendMessage() : () => {}}/>
                <IconButton edge="end" disabled={isLoading} style={{borderRadius: 0, border: "none"}} onClick={() => sendMessage()}>
                    {isLoading ? <MoreHoriz /> : <SendOutlined />}
                </IconButton>
            </div>
        </div>
        </Box>
    );
}

export default Comments