/* eslint-disable no-unused-vars */
import React, { Component, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Translation from "./components/TranslationMaster/TranslationList";
import ProjectList from "./components/Project/ProjectList.js";
import SignUp from "./common/Singup.js";
import LanguageList from "./components/Languages/LanguageList.js";
import Uploads from "./components/Uploads/Uploads.js";
import Setting from "./components/Settings/Setting.js";
import AdminDashboard from "./components/AdminDashboard.js";
import queryString from "query-string";
import Dashboard from "./common/Dashboard.js";
import Home from "./Layout/Home";
import NotFound from "./common/NotFound";
import Blank from "./Layout/Blank";
import Bugs from "./components/Bugs/Bugs";
import TranslationOrder from "./components/TranslationOrder/TranslationOrder";
import TxnCreateOrder from "./components/TranslationOrder/TranslationOrderCreate";
import Slacks from "./Custom/Slacks-redirect";
import Invitation from "./common/InvitationConfirm";
import Downloads from "./components/Downloads/Download";

const AuthRoutes = () => {

  const queryParams = queryString.parse(window.location.search);
  const token = queryParams.token;
  const pathname = window.location?.pathname.split("/")[1];

  useEffect(() => {
    if(pathname == "authorize" && token?.length > 0){
      localStorage.setItem("token-le-wegid", token);
      localStorage.setItem("oauth-redirect", true);
      window.close();
    }
  });
  
  return (
    <Router>
      <Routes>
        {!localStorage.getItem("loged_In_auth")?.length > 0 ? 
          <Route path="/">
            <Route path={"/"} element={<SignUp />} />
            {pathname !== "authorize" && <Route path='/*' element={<Navigate replace to='/' />} />}
          </Route>
        : 
          <Route>
            <Route path="/" element={<Blank />}>
              <Route index element={<Dashboard />} />
              <Route path={"dashboard"} element={<Dashboard />} />
              <Route path={"invitation-confirmation"} element={<Invitation />} />
            </Route>
            <Route path="/" element={<Home />}>
              <Route path='bugs' element={<Bugs />} />
              <Route path='home'>
                <Route path='slackoauth' element={<Slacks />} />
                <Route path=':projectId/translation' element={<Translation />} />
                <Route path=':projectId/analytics' element={<AdminDashboard />} />
                <Route path=':projectId/languages' element={<LanguageList />} />
                <Route path=':projectId/uploads' element={<Uploads />} />
                <Route path=':projectId/settings' element={<Setting />} />
                <Route path=':projectId/downloads' element={<Downloads />} />
                <Route path=':projectId/translation-orders' element={<TranslationOrder />} />
                <Route path=':projectId/addorders' element={<TxnCreateOrder />} />
                <Route path=':projectId/notFound' element={<NotFound />} />
                <Route path='*' element={<Navigate replace to='notFound' />} />
              </Route>
              <Route path ={"notFound"} element={<NotFound />} />
              <Route path='*' element={<Navigate replace to='notFound' />} />
            </Route>
          </Route>
      }
      </Routes>
    </Router>
    );
  }

export default AuthRoutes;
