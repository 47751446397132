/* eslint-disable array-callback-return */
import React, {useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Icon } from '@iconify/react';
import { Autocomplete, Box, Chip, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { CustomWidthTooltip } from '../../Custom/BootStarpToolTip';
import { abbreviationLang } from '../../Constant/Language';
import { postProject } from '../../Services/Networks/Projects';
import { uid } from 'uid';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProjectAdd({handleClose, fetchProjectList, projectListData, saveData, edit, open, url, key}) {
  const [projectName, setProjectName] = React.useState("");
  const [onerror, setOnerror] = React.useState("");
  const [onerrorName, setOnerrorName] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [baseLanguage, setBaseLang] = React.useState("en_IN");
  const [langaugeList, setLangauges] = React.useState([]);
  const [preFetchListList, setPreFetchList] = React.useState([]);
  const unique_id = uid(26);
  
  const handleChangeLanguage = (event, newValue) => {
    // const { target: { value }} = event;
    setLangauges([...newValue]);
  };
  const handleChange = (event) => {
    const {target: { value }} = event;
    setBaseLang(value);
    setOnerror("")
  };

  const getClear = () => {
    setBaseLang("en_IN");
    setLangauges([]);
    setProjectName("");
  };

  const handleSave = () => {
    let locale_payload = [];
  
    langaugeList.forEach((d) => {
      locale_payload.push({locale: d.split("- ")[1] , isDefault: false})
    })
    locale_payload.push({
      locale: baseLanguage,
      isDefault: true
    });
    
    const payload = {
      projectId: unique_id,
      projectName: projectName,
      locales: locale_payload
    };
    if(projectName?.length > 0 && langaugeList?.length > 0 && baseLanguage.length > 0){
      postProject(payload, setIsLoading, handleClose, fetchProjectList, getClear);
    }else{
      setOnerror("All feilds are required");
    }
  }

  useEffect(() => {
     let arrayName = [];
     if(projectListData?.length > 0)
      projectListData.map((d) => {
        arrayName.push(d.name?.toLocaleLowerCase());
      });
      setPreFetchList(arrayName);
  },[projectListData])

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle  className='flex-sb'>
          <span>Add Project</span>
          <Icon icon={"eva:close-fill"} onClick={handleClose} style={{fontSize: 30, cursor: "pointer" }}/>
      </DialogTitle>
      <Divider />
      <DialogContent>
          <div>
          <div className="form-group col" style={{width: "100%"}}>
              <label className="col-form-label">
                  <span>Project Name</span><span>*</span>
                  <CustomWidthTooltip arrow placement='top' title="We allows you to keep different platform strings in the same project – no more duplicate translation work.">
                    <Icon icon="ant-design:info-circle-filled" className='icon-dailog-info'/>
                  </CustomWidthTooltip>
              </label>
              <div className="">
                  <TextField
                      type="text"
                      size='small'
                      required={true}
                      className="form-control"
                      name="projectName"
                      id="projectName"
                      error={onerror.length > 0 || onerrorName?.length > 0}
                      style={{borderColor: onerror.length > 0 && "red", height: 50, width: "96%"}}
                      placeholder="Project name"
                      onChange={(e) => {
                        if(preFetchListList?.includes(e.target.value?.toLocaleLowerCase())){
                          setOnerrorName("*This name is already taken");
                          setProjectName(e.target.value);
                        }else{
                          setProjectName(e.target.value);
                          setOnerror("");
                          setOnerrorName("");
                        }
                      }}
                      helperText={onerrorName}
                      value={projectName}
                  />
              </div>
          </div>
          <div className="form-group col" style={{width: "96%"}}>
              <label className="col-form-label">
                  <span>Base language</span> 
                  <CustomWidthTooltip arrow placement='top' title="Base language value act as value for which translations is to be performed">
                    <Icon icon="ant-design:info-circle-filled" className='icon-dailog-info'/>
                  </CustomWidthTooltip>
              </label>
              <Box className="">
              <FormControl fullWidth style={{borderColor: onerror.length > 0 && "red"}}>
                  <InputLabel id="demo-simple-select-label">Base Language</InputLabel>
                  <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size='small'
                      value={baseLanguage}
                      label="Base Language"
                      onChange={handleChange}
                      error={onerror.length > 0}
                  >
                      {abbreviationLang.map((name) => (
                          <MenuItem
                          key={name?.abbreviation}
                          value={name?.abbreviation}
                          // style={getStyles(name, personName, theme)}
                          >
                              {name?.locale_name} -- {" "}<span style={{color: "grey", textTransform: "uppercase"}}> {name?.abbreviation}</span>
                          </MenuItem>
                      ))}
                  </Select>
              </FormControl>
              </Box>
          </div>
          <div className="form-group col" style={{width: "100%"}}>
              <label className="col-form-label">
                  <span>Target languages</span> 
                  <CustomWidthTooltip arrow placement='top' title="Select the languages you want to translate into. You'll be able to edit these later.">
                    <Icon icon="ant-design:info-circle-filled" className='icon-dailog-info'/>
                  </CustomWidthTooltip>
              </label>
              <div className="">
              <Autocomplete
                size='small'
                multiple
                error={onerror?.length>0}
                value={langaugeList}
                onChange={(event, newValue) => {
                  handleChangeLanguage(event, newValue);
                  setOnerror("")
                }}
                options={abbreviationLang.map((option) => `${option?.locale_name} - ${option?.abbreviation}`)}
                // options={abbreviationLang}
                // getOptionLabel={(option) => <>{option?.locale_name} -- <span style={{color: "grey", textTransform: "uppercase"}}> {option?.abbreviation}</span></>}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      style={{borderRadius: 4}}
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                style={{width: 500 }}
                renderInput={(params) => (
                  <TextField {...params} label="Languages" placeholder="Languages" error={onerror.length > 0} />
                )}
              />
              </div>
          </div>
          {onerror.length > 0 && <p style={{color: "red", marginLeft: 15}}>{onerror}*</p>}
          </div>
      </DialogContent>
      <DialogActions className='flex-fe px-4'>
        <div>
          <Button onClick={handleClose} style={{color: "#6366f1", borderColor: "#6366f1", marginRight: 5}} variant="outlined">Cancel</Button>
          <Button onClick={handleSave} variant="contained" style={{background: "#6366f1"}} disabled={isLoading}>{isLoading ? <CircularProgress style={{ width: "20px", height: "20px"}}/> : "Save" }</Button>
        </div>
        </DialogActions>
    </Dialog>
  );
}
