import React, { useEffect, useState } from "react";
import { Paper, Table, TableBody, TableHead, TablePagination ,TableCell, TableContainer, TableRow, Checkbox, Tooltip, Skeleton, Button } from "@mui/material";
import { Icon } from "@iconify/react";
import {AlertDialogSlide, ConfirmationDialogSlide} from "../../Custom/DialogBox";
import { Delete } from "@mui/icons-material";
import LangaugeAdd from "./LanguageAdd";
import { useSelector } from "react-redux";
import { updateProject } from "../../Services/Networks/Projects";
import { localName } from "../../utils/Response";

const LanguageList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [url, setUrl] = useState([]);
  const [datatable, setTableData] = useState([]);
  const [editData, setEditData] = useState();
  const [openDelete, setopenDelete] = useState(false);
  const [deleteRowid, setDeleteRowid] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [bulkDelete, setBulkDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUploading] = useState(-1);
  const projectDetails = useSelector(state => state?.project?.selectedProject);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getData(projectDetails?.locales);
  }, [projectDetails]);
  
  const getData = (items) => {
    setIsLoading(true);
    setTimeout(() => {
      setTableData(items);
      setIsLoading(false);
    }, 2000)
  }
  const columns = [
    {
      name: "name",
      label: "Locale",
      align: "left",
      minWidth: 30,
    },
    {
      name: "locale",
      label: "Code",
      align: "left",
      minWidth: 30,
    },
    {
       name: "default",
       label: "Default",
       align: "left",
       minWidth: 65,
       options: {
        filter: true,
        sort: true,
       }
    },
    {
        name: "id",
        label: "Actions",
        align: "left",
        minWidth: 65,
        options: {
         filter: true,
         sort: true,
        }
     }
  ];

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseForm = () => {
    setEditData([]);
    setFormOpen(!formOpen)
  }

  const handleCloseDelete = () => {
    setopenDelete(!openDelete);
  }

  const onConfirm = (id) => {
    if(bulkDelete){
      let filtered = datatable;
      selectedRows?.forEach((d) => {
        filtered = filtered.filter(it =>  it.key !== d.key);
        setTableData(filtered);
      })
    }else{
      const filtered = datatable.filter(d => d.key !== id);
      setTableData(filtered);
    }
    handleCloseDelete();
    setSelectedRows([]);
  }

  const handleCheckBox  = (item) => {
     if(!isItemSelected(item)){
      setSelectedRows([...selectedRows, item])
     }else{
       const d = selectedRows?.filter(d => item?.id !== d.id)
       setSelectedRows([...d])
     }
  }
  const onSelectAllClick = (e) => {
    if(selectedRows?.length === rowsPerPage) {
      setSelectedRows([])
    }else{
    setSelectedRows(datatable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
    }
  }

  const isItemSelected = (e) => {
     let isSelected = false;
     selectedRows.some(d => {
      if(e?.id === d.id){
        isSelected = true;
      }
     });
     return isSelected
  }

  const handleChangeDefault = (selected, j) => {
    setIsUploading(j);
    const setUploading = () => {
      setIsUploading(-1)
    }
    datatable.forEach((d) => d.locale == selected ? d.isDefault = true : d.isDefault = false);
    setTableData([...datatable]);
    const payload = {
      projectId: projectDetails?.id,
      payload: {
          projectName: projectDetails.name,
          locales: datatable
      }
    }
    updateProject(payload, setUploading, projectDetails, ()=>{}, ()=> {})
}

  return (
      <div className="pcoded-inner-content">
        <AlertDialogSlide open={open} url={url} handleClose={handleClose} />
        <ConfirmationDialogSlide onConfirm={onConfirm} title={"Delete key"} id={deleteRowid} open={openDelete} handleClose={handleCloseDelete} btnConfirm={"Delete"}  />
        <LangaugeAdd handleClose={handleCloseForm} open={formOpen}/>
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col-lg-8">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>Language List</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <Button
                    onClick={() => {
                      setFormOpen(!formOpen);
                    }}
                    variant="contained"
                    className="f-right d-inline-block"
                    data-modal="modal-13"
                    style={{opacity: 1, textTransform: "capitalize"}}
                  >
                    {" "}
                    <i className="icofont icofont-plus m-r-5"></i> Add Language
                  </Button>
                </div>
              </div>
            </div>
            <Paper sx={{ width: '100%', overflow: 'hidden' }} className="paper-c-shadow">
              <div className="flex-sb alc-pl-20" style={{ background: selectedRows?.length > 0 && "aliceblue"}}>
                <div>
                    {selectedRows?.length > 0 && <b style={{color: "#262626"}}>{selectedRows?.length} Row selected </b>}
                </div>
                <div className="flex-right"> 
                  {selectedRows?.length > 0 && <Tooltip title="Delete selected">
                    <div className="filter-span-button"  onClick={() => {
                      setDeleteRowid(`${selectedRows?.length} rows`);
                      setBulkDelete(true);
                      handleCloseDelete();
                    }}>
                      <Delete />
                    </div>
                  </Tooltip>
                  }
                  {/* <Tooltip title="Download">
                    <div className="filter-span-button">
                      <Download />
                    </div>
                  </Tooltip>
                  {!selectedRows?.length > 0 &&  <Tooltip title="Filter list">
                    <div className="filter-span-button">
                      <FilterListIcon />
                    </div>
                  </Tooltip>} */}
              </div>
              </div>
              <TableContainer sx={{ maxHeight: "65vh", borderBottom: "1px solid rgba(224, 224, 224, 1)" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className="table-custom-row">
                    <TableCell padding="checkbox">
                      {/* <Checkbox
                        color="primary"
                        indeterminate={selectedRows?.length > 0 && selectedRows?.length < rowsPerPage}
                        checked={rowsPerPage > 0 && selectedRows?.length === rowsPerPage}
                        onChange={(e) => onSelectAllClick(e)}
                        inputProps={{
                          'aria-label': 'select all desserts',
                        }}
                      /> */}
                    </TableCell>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: `${column.minWidth}%` }}
                        >
                          <b>{column.label}</b>
                        </TableCell>
                      ))}
                    {/* </TableRow> */}
                  </TableHead>
                  <TableBody>
                    {isLoading ? <TableRow variant="h1">
                      <TableCell className="flex-fs">
                        <Skeleton width={"60%"} style={{margin:"auto", height: 50}}/>
                        <Skeleton width={"60%"} style={{margin:"auto", height: 50}}/>
                      </TableCell>
                      <TableCell className="flex-fs">
                        <Skeleton width={"60%"} style={{margin:"auto", height: 50}}/>
                        <Skeleton width={"60%"} style={{margin:"auto", height: 50}}/>
                      </TableCell>
                      <TableCell className="flex-fs">
                        <Skeleton width={"60%"} style={{margin:"auto", height: 50}}/>
                        <Skeleton width={"60%"} style={{margin:"auto", height: 50}}/>
                      </TableCell>
                      <TableCell className="flex-fs">
                        <Skeleton width={"60%"} style={{margin:"auto", height: 50}}/>
                        <Skeleton width={"60%"} style={{margin:"auto", height: 50}}/>
                      </TableCell>
                    </TableRow> :
                    datatable
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      ?.sort((it, it2) => it.isDefault < it2.isDefault ? 1 : -1)
                      ?.map((row, j) => {
                        return (
                            <TableRow className="table-hover-icons-normal-rows" tabIndex={-1} key={row.id}>
                                <TableCell padding="checkbox">
                                    {/* <Checkbox
                                      color="primary"
                                      onChange={() => handleCheckBox(row)}
                                      checked={isItemSelected(row)}
                                      inputProps={{
                                        // 'aria-labelledby': labelId,
                                    }}
                                    /> */}
                                </TableCell>
                                <TableCell align="left">{localName(row.locale)} </TableCell>
                                <TableCell align="left">{row.locale} </TableCell>
                                <TableCell>
                                    {row.isDefault === true && <span className="tag-default-lang">default</span> }
                                </TableCell>
                                <TableCell>
                                  {row.isDefault !== true && 
                                    <div className="flex-fs" style={{marginTop: 8}}>
                                        <Tooltip title={"Delete"} arrow>
                                          <Icon icon={"fluent:delete-32-regular"} className={"icon-actions-end"}  style={{color: "red"}}/>
                                        </Tooltip>
                                        <Tooltip title="Mark as default" arrow>
                                          {isUpdating == j ? <Icon icon={"line-md:loading-twotone-loop"} className={"icon-actions-end verified-action-hover"} /> : <Icon icon={"akar-icons:double-check"} className={"icon-actions-end"}  style={{color: "#64bcab"}} onClick={()=> handleChangeDefault(row.locale, j)}/>}
                                        </Tooltip>
                                    </div>
                                  }
                                </TableCell>
                            </TableRow>
                            );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={datatable.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
            </Paper>
          </div>
        </div>
      </div>
    );
}

export default LanguageList;
