import { Icon } from '@iconify/react'
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, Dialog, Switch, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import slackImg from '../../Assets/images/slack.png';
import { NOTIFICATION_TYPE } from '../../Constant/Integrations';
import { ConfirmDailog } from '../../Custom/DialogBox';
import { deleteSlackChannel, getStatus, getWindowTab, integrateInit, oAuthSlackConfigs } from '../../Services/Networks/Integration';
import Logo from '../../Assets/images/icon.png';


const Notification = ({ projectId }) => {
    const [apicall, setAPiCall] = useState(0);
    const [expanded, setExpanded] = useState('panel0');
    const [isLoading, setIsLoading] = useState(false);
    const [isDelete, setIsDelete] = useState(-1);
    const [openDialog, setOpenDialog] = useState(false);

    const handleChange = (panel, newExpanded) => {
        setExpanded(newExpanded == panel ? false : panel);
    };

    const notificationDetails = useSelector(state => state?.slackconfig?.data);
    const notificationData = useSelector(state => state?.slackconfig);
    var nIntervId="";
    
    const triggerSlack = (type) => {
        setOpenDialog(false);
        integrateInit({ title: "Slack" }, setIsLoading, () => {}, type, notificationDetails)
    }

    if(notificationData?.types?.trigger == true){
        nIntervId = setInterval(() => {
            if(getWindowTab()){
                console.log("ehllo 2");
                setAPiCall(1);
                clearInterval(nIntervId)
                // oAuthSlackConfigs(projectId, true, nIntervId, setIsLoading);
            }
        }, 1000);
    }
    useEffect(() => {
        if(apicall < 2)
            oAuthSlackConfigs(projectId, true, nIntervId, setIsLoading);
    },[apicall]);

    const deleteChannel = (channelId, type, i) => {
        const payload = {
            "projectId": projectId,
            "notificationType": type,
            "channelId": channelId
        }
        setIsDelete(i);
        deleteSlackChannel(payload, setIsDelete, callbacktrigger);
    }

    const callbacktrigger = () => {
        oAuthSlackConfigs(projectId, false)
    }

    return (
        <div>
            <div>
                <ConfirmDailog open={openDialog} handleClose={() => setOpenDialog(false)} triggerSlack={triggerSlack} />
                <h5 className="pl-3 pt-3" style={{ paddingBottom: 0 }}>Notification Settings</h5>
                <span className="pl-3 pb-3">Get notified about your translation and comments </span>
                {notificationDetails?.length > 0 && <>
                    <div className='pt-3 px-2'>
                        <Button variant='outlined' size="small" disabled={isLoading} onClick={() => {
                            setOpenDialog(true);
                        }}
                            style={{ textTransform: "none", paddingRight: 10, borderRadius: 50, margin: "0px 10px" }}>
                            <Icon icon={isLoading ? "line-md:loading-twotone-loop" :"logos:slack-icon"} style={{ margin: "0px 6px" }} /> Connect With Slack
                        </Button>
                    </div>
                    <div className='pt-3'>
                        <Accordion expanded={expanded === 'panel1'} className="m-3 card-view-settings" >
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                <div className='flex-sb' style={{ width: "100%" }}>
                                    <div style={{ width: "90%" }}>
                                        <p style={{ fontSize: 18, fontWeight: 500, margin: 0 }}>Enable Notification for Comments</p>
                                        <p style={{ fontSize: 14, fontWeight: 400, color: "#595959" }}>Get notified on slack channels about comments and keys crud operations</p>
                                    </div>
                                    <div>
                                        <Switch disabled={!getStatus(notificationDetails, NOTIFICATION_TYPE.INFO_COMMENTS_AND_KEY_VALUE)} checked={getStatus(notificationDetails, NOTIFICATION_TYPE.INFO_COMMENTS_AND_KEY_VALUE)} onChange={() => handleChange('panel1', expanded)} />
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    {notificationDetails.filter(d => d.type == NOTIFICATION_TYPE.INFO_COMMENTS_AND_KEY_VALUE)[0]?.mediums?.map((item, i) => <Card key={i} className='row m-3 p-3 justify-content-sm-between align-items-center'>
                                        <div className='d-flex'>
                                            <div>
                                                <img src={slackImg} style={{ width: 60 }} />
                                            </div>
                                            <div className='px-2'>
                                                <b style={{ fontSize: 16 }}>{item?.config.channel}</b>
                                                <p style={{ color: "rgba(0,0,0,.6)", fontSize: 14 }}>Workspace {item?.config?.teamName}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <Icon icon={"akar-icons:settings-vertical"} style={{ fontSize: 20, marginRight: 10, cursor: "pointer", color: "#6366f1" }} onClick={() => window.open(item?.config.configuration_url, "_blank")} />
                                            <Icon icon={isDelete == i ? "line-md:loading-twotone-loop" : "mingcute:delete-2-line"} style={{ fontSize: 20, cursor: "pointer", color: "#6366f1" }} onClick={() => deleteChannel(item.config.id, "INFO_COMMENTS_AND_KEY_VALUE", i )}  />
                                        </div>
                                    </Card>
                                    )}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} className="m-3 card-view-settings">
                            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                                <div className='flex-sb' style={{ width: "100%" }}>
                                    <div style={{ width: "90%" }}>
                                        <p style={{ fontSize: 18, fontWeight: 500, margin: 0 }}>Enable Notification from Vernacle</p>
                                        <p style={{ fontSize: 14, fontWeight: 400, color: "#595959" }}>Get notification for invite member, billing and plans, integrations, order updates and etc.</p>
                                    </div>
                                    <div>
                                        <Switch disabled={!getStatus(notificationDetails, NOTIFICATION_TYPE.INFO_CONSOLE)} checked={getStatus(notificationDetails, NOTIFICATION_TYPE.INFO_CONSOLE)} onChange={() => handleChange('panel2', expanded)} />
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    {notificationDetails.filter(d => d.type == NOTIFICATION_TYPE.INFO_CONSOLE)[0]?.mediums?.map((item, i) => <Card key={i} className='row m-3 p-3 justify-content-sm-between align-items-center'>
                                        <div className='d-flex'>
                                            <div>
                                                <img src={slackImg} style={{ width: 60 }} />
                                            </div>
                                            <div className='px-2'>
                                                <b style={{ fontSize: 16 }}>{item?.config.channel}</b>
                                                <p style={{ color: "rgba(0,0,0,.6)", fontSize: 14 }}>Workspace {item?.config?.teamName}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <Icon icon={"akar-icons:settings-vertical"} style={{ fontSize: 20, marginRight: 10, cursor: "pointer", color: "#6366f1" }} onClick={() => window.open(item?.config.configuration_url, "_blank")} />
                                            <Icon icon={isDelete == i ? "line-md:loading-twotone-loop" : "mingcute:delete-2-line"} style={{ fontSize: 20, cursor: "pointer", color: "#6366f1" }} onClick={() => deleteChannel(item.config.id, "INFO_CONSOLE", i )} />
                                        </div>
                                    </Card>
                                    )}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} className="m-3 card-view-settings" >
                            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                                <div className='flex-sb' style={{ width: "100%" }}>
                                    <div style={{ width: "90%" }}>
                                        <p style={{ fontSize: 18, fontWeight: 500, margin: 0, color: "#262626" }}>Enable Notification for portal</p>
                                        <p style={{ fontSize: 14, fontWeight: 400, color: "#595959" }}>Get notified on slack about your concerns, query and other system messages  </p>
                                    </div>
                                    <div>
                                        <Switch disabled={!getStatus(notificationDetails, NOTIFICATION_TYPE.INFO_SYSTEM)} checked={getStatus(notificationDetails, NOTIFICATION_TYPE.INFO_SYSTEM)} onChange={() => handleChange('panel3', expanded)} />
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <div>
                                    {notificationDetails.filter(d => d.type == NOTIFICATION_TYPE.INFO_SYSTEM)[0]?.mediums?.map((item, i) => <Card key={i} className='row m-3 p-3 justify-content-sm-between align-items-center'>
                                        <div className='d-flex'>
                                            <div>
                                                <img src={slackImg} style={{ width: 60 }} />
                                            </div>
                                            <div className='px-2'>
                                                <b style={{ fontSize: 16 }}>{item?.config.channel}</b>
                                                <p style={{ color: "rgba(0,0,0,.6)", fontSize: 14 }}>Workspace {item?.config?.teamName}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <Icon icon={"akar-icons:settings-vertical"} style={{ fontSize: 20, marginRight: 10, cursor: "pointer", color: "#6366f1" }} onClick={() => window.open(item?.config.configuration_url, "_blank")} />
                                            <Icon icon={isDelete == i ? "line-md:loading-twotone-loop" : "mingcute:delete-2-line"} style={{ fontSize: 20, cursor: "pointer", color: "#6366f1" }} onClick={() => deleteChannel(item.config.id, "INFO_SYSTEM", i )} />
                                        </div>
                                    </Card>
                                    )}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </>}
                {!notificationDetails?.length > 0 && <div className='flex-slack-not-configure'>
                    <h1>Start configuration for your notifications</h1>
                    <p style={{ color: "red" }}>*You don't have any channel configured. Please add one for enabling notifications.</p>
                    <div className='pt-3 px-2 flex-c'>
                        <Card sx={{ width: 300, height: 300, borderRadius: 4 }} className="paper-c-shadow">
                            <div className='flex-c align-items-center' style={{ height: 200, background: "#6366f1" }}>
                                <Icon icon={"logos:slack-icon"} width={70} style={{ margin: "0px 6px" }} />
                                <Icon icon={"eos-icons:three-dots-loading"} width={60} style={{ marginRight: "-26px" }} color={"white"} />
                                <img src={Logo} width={140} style={{ marginRight: -40 }} />
                            </div>
                            <div className='flex-c mt-4'>
                                <Button variant='outlined' size="small" disabled={isLoading} onClick={() => {
                                    setOpenDialog(true);
                                }}
                                    style={{ textTransform: "none", padding: 10, borderRadius: 50, margin: "0px 10px" }}>
                                    <Icon icon={isLoading ? "line-md:loading-twotone-loop" :"logos:slack-icon"} style={{ margin: "0px 6px" }} /> Connect with Slack
                                </Button>
                            </div>
                        </Card>
                    </div>
                </div>
                }
            </div>
        </div>
    )
}

export default Notification
