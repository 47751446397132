import axios from "axios";
import { GET_SLACKCONFIG_LIST, SET_TYPE } from "../../Redux/slices/Slack_notification";
import { HANDLE_SNACKBAR } from "../../Redux/slices/SnackbarAlert";
import { store } from "../../store";
import { apiGateway } from "../../utils/constant/apigateways";
import { ResponseSuccess } from "../../utils/Response";
let openWindow = '';

export const getStatus = (data, type) => {
    let val = false;
    if(!data?.length > 0 ){
        val = false
    }else{
        data?.forEach(element => {
            if(element.type == type){
                val = element.mediums?.length > 0 ? true : false;
            }
        });
    }

   return val
}
export const integrateInit = (data, setIsLoading, handleClose, type, notificationDetails) => {
    setIsLoading(true);
   
    if(data?.title == "Slack"){
        axios.get(apiGateway + `/notification/oauth`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token-le-wegid')}`
            }
        })
        .then(res => {
            if(ResponseSuccess(res).status == true){
                store.dispatch(SET_TYPE({
                    type: type,
                    trigger: true,
                    isNew: !getStatus(notificationDetails, type)
                }))
                openWindow = window.open(ResponseSuccess(res).response.redirect_uri, "_blank");
            }else{
                store.dispatch(HANDLE_SNACKBAR({
                    open: true,
                    message: "Something went wrong!",
                    type: "error"
                }));
           }
        })
        .catch((err) => {
            setIsLoading(false);
            store.dispatch(HANDLE_SNACKBAR({
                open: true,
                message: "Not able to fetch sack-oauth url!",
                type: "error"
            }));
        }).finally(() => {
            handleClose();
        })
    }
}

export const oAuthAccess = (code, projectId, integrationInfo, setText) => {
    if(projectId !== "" && code !== "" ){
        axios.get(apiGateway + `/notification/create?slackAccessCode=${code}&projectId=${projectId}&notificationType=${integrationInfo.type}&isNew=${integrationInfo.isNew}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token-le-wegid')}`
            }
        })
        .then(res => {
            if(ResponseSuccess(res).status == true){
                store.dispatch(SET_TYPE({
                    sucess: true,
                }));
                store.dispatch(HANDLE_SNACKBAR({
                    open: true,
                    message: "Added slack channel to your account!",
                    type: "success"
                }));
                setText("This window tab will be closed in 3s .... Please wait  ")
                setTimeout(() =>window.close(), 2000)         
            }else{
                store.dispatch(HANDLE_SNACKBAR({
                    open: true,
                    message: "Something went wrong!",
                    type: "error"
                }));
                store.dispatch(SET_TYPE({
                    sucess: false,
                }));
           }
        })
        .catch((err) => {
            store.dispatch(HANDLE_SNACKBAR({
                open: true,
                message: "Not able to connect with sack!",
                type: "error"
            }));
            store.dispatch(SET_TYPE({
                sucess: false,
            }));
        })
    }
    // setTimeout(() =>window.close(), 1000)

        
}

export const oAuthSlackConfigs = (projectId, error, nIntervId, setIsLoading ) => {
    if(projectId !== "" ){
        store.dispatch(SET_TYPE({
            trigger: false,
        }));
        axios.get(apiGateway + `/notification/list?projectId=${projectId}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token-le-wegid')}`
            }
        })
        .then(res => {
            if(ResponseSuccess(res).status == true){
                store.dispatch(GET_SLACKCONFIG_LIST(ResponseSuccess(res).response));
            }else{
                if(error){
                    store.dispatch(HANDLE_SNACKBAR({
                        open: true,
                        message: "Something went wrong!",
                        type: "error"
                    }));
                }
           }
        })
        .catch((err) => {
            if(error){
                store.dispatch(HANDLE_SNACKBAR({
                    open: true,
                    message: "Not able to connect with sack!",
                    type: "error"
                }));
            }
        }).finally(() => {
            if(error) {
                setIsLoading(false)
                clearInterval(nIntervId);
            } 
        })
    }
}

export const deleteSlackChannel = (payload, setIsLoading, callback) => {
    axios.delete(apiGateway + `/notification`, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('token-le-wegid')}`
        },
        data: payload
    })
    .then(res => {
        if(ResponseSuccess(res).status == true){
            store.dispatch(HANDLE_SNACKBAR({
                open: true,
                message: "Channel Deleted Successfully!",
                type: "success"
            }));
        }else{
            store.dispatch(HANDLE_SNACKBAR({
                open: true,
                message: "Something went wrong!",
                type: "error"
            }));
        }
        setIsLoading(-1);
    })
    .catch((err) => {
        setIsLoading(-1);
        store.dispatch(HANDLE_SNACKBAR({
            open: true,
            message: "Not able to fetch sack-oauth url!",
            type: "error"
        }));
    }).finally(() => {
        callback();
    })
}

export const getWindowTab = () => {
    return openWindow?.closed
}