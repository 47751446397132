export const defaultAvatar = [{
    "id": "da-01",
    "url": "https://s3.vernacle.com/da-01.png",
    "sex": "man",
    "faceColor": "#F9C9B6",
    "earSize": "small",
    "eyeStyle": "smile",
    "noseStyle": "short",
    "mouthStyle": "smile",
    "shirtStyle": "polo",
    "glassesStyle": "round",
    "hairColor": "#77311D",
    "hairStyle": "thick",
    "hatStyle": "none",
    "hatColor": "#F48150",
    "eyeBrowStyle": "up",
    "shirtColor": "#9287FF",
    "bgColor": "linear-gradient(45deg, #3e1ccd 0%, #ff6871 100%)"
}, {
    "id": "da-02",
    "url": "https://s3.vernacle.com/da-02.png",
    "sex": "man",
    "faceColor": "#F9C9B6",
    "earSize": "big",
    "eyeStyle": "smile",
    "noseStyle": "long",
    "mouthStyle": "laugh",
    "shirtStyle": "short",
    "glassesStyle": "none",
    "hairColor": "#000",
    "hairStyle": "thick",
    "hatStyle": "none",
    "hatColor": "#F48150",
    "eyeBrowStyle": "up",
    "shirtColor": "#6BD9E9",
    "bgColor": "linear-gradient(45deg, #3e1ccd 0%, #ff6871 100%)"
},
{
    "id": "da-03",
    "url": "https://s3.vernacle.com/da-03.png",
    "sex": "woman",
    "faceColor": "#F9C9B6",
    "earSize": "big",
    "eyeStyle": "oval",
    "noseStyle": "short",
    "mouthStyle": "laugh",
    "shirtStyle": "short",
    "glassesStyle": "none",
    "hairColor": "#77311D",
    "hairStyle": "normal",
    "hatStyle": "none",
    "hatColor": "#77311D",
    "eyeBrowStyle": "up",
    "shirtColor": "#FC909F",
    "bgColor": "linear-gradient(45deg, #178bff 0%, #ff6868 100%)"
},
{
    "id": "da-04",
    "url": "https://s3.vernacle.com/da-04.png",
    "sex": "woman",
    "faceColor": "#F9C9B6",
    "earSize": "small",
    "eyeStyle": "oval",
    "noseStyle": "round",
    "mouthStyle": "peace",
    "shirtStyle": "hoody",
    "glassesStyle": "none",
    "hairColor": "#FC909F",
    "hairStyle": "womanLong",
    "hatStyle": "none",
    "hatColor": "#fff",
    "eyeBrowStyle": "up",
    "shirtColor": "#9287FF",
    "bgColor": "#74D153"
},
{
    "id": "da-05",
    "url": "https://s3.vernacle.com/da-05.png",
    "sex": "woman",
    "faceColor": "#F9C9B6",
    "earSize": "big",
    "eyeStyle": "circle",
    "noseStyle": "round",
    "mouthStyle": "smile",
    "shirtStyle": "short",
    "glassesStyle": "none",
    "hairColor": "#FC909F",
    "hairStyle": "normal",
    "hatStyle": "beanie",
    "hatColor": "#D2EFF3",
    "eyeBrowStyle": "upWoman",
    "shirtColor": "#77311D",
    "bgColor": "linear-gradient(45deg, #ff1717 0%, #ffd368 100%)"
},
{
    "id": "da-06",
    "url": "https://s3.vernacle.com/da-06.png",
    "sex": "man",
    "faceColor": "#F9C9B6",
    "earSize": "big",
    "eyeStyle": "smile",
    "noseStyle": "long",
    "mouthStyle": "laugh",
    "shirtStyle": "polo",
    "glassesStyle": "none",
    "hairColor": "#000",
    "hairStyle": "thick",
    "hatStyle": "none",
    "hatColor": "#F48150",
    "eyeBrowStyle": "up",
    "shirtColor": "#6BD9E9",
    "bgColor": "linear-gradient(45deg, #3e1ccd 0%, #ff6871 100%)"
},
{
    "id": "da-07",
    "url": "https://s3.vernacle.com/da-07.png",
    "sex": "man",
    "faceColor": "#AC6651",
    "earSize": "small",
    "eyeStyle": "circle",
    "noseStyle": "long",
    "mouthStyle": "smile",
    "shirtStyle": "polo",
    "glassesStyle": "square",
    "hairColor": "#000",
    "hairStyle": "normal",
    "hatStyle": "turban",
    "hatColor": "#F48150",
    "eyeBrowStyle": "up",
    "shirtColor": "#9287FF",
    "bgColor": "linear-gradient(45deg, #178bff 0%, #ff6868 100%)"
},
{
    "id": "da-08",
    "url": "https://s3.vernacle.com/da-08.png",
    "sex": "man",
    "faceColor": "#F9C9B6",
    "earSize": "big",
    "eyeStyle": "circle",
    "noseStyle": "short",
    "mouthStyle": "laugh",
    "shirtStyle": "polo",
    "glassesStyle": "round",
    "hairColor": "#000",
    "hairStyle": "normal",
    "hatStyle": "none",
    "hatColor": "#F48150",
    "eyeBrowStyle": "up",
    "shirtColor": "#FC909F",
    "bgColor": "linear-gradient(45deg, #178bff 0%, #ff6868 100%)"
},
{
    "id": "da-09",
    "url": "https://s3.vernacle.com/da-09.png",
    "sex": "man",
    "faceColor": "#F9C9B6",
    "earSize": "small",
    "eyeStyle": "oval",
    "noseStyle": "round",
    "mouthStyle": "smile",
    "shirtStyle": "short",
    "glassesStyle": "round",
    "hairColor": "#000",
    "hairStyle": "normal",
    "hatStyle": "none",
    "hatColor": "#77311D",
    "eyeBrowStyle": "up",
    "shirtColor": "#77311D",
    "bgColor": "#FFEBA4"
},
{
    "id": "da-10",
    "url": "https://s3.vernacle.com/da-10.png",
    "sex": "woman",
    "faceColor": "#AC6651",
    "earSize": "big",
    "eyeStyle": "circle",
    "noseStyle": "round",
    "mouthStyle": "smile",
    "shirtStyle": "polo",
    "glassesStyle": "none",
    "hairColor": "#000",
    "hairStyle": "womanLong",
    "hatStyle": "none",
    "hatColor": "#fff",
    "eyeBrowStyle": "up",
    "shirtColor": "#FC909F",
    "bgColor": "#9287FF"
},
]