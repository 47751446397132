import { Icon } from '@iconify/react'
import { Button, Card, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { onboardedUpdate } from "../../Services/Networks/User";

function GeneralSetting({ handleOpenInvite }) {
    const userDetails = useSelector(state => state?.user);
    const [username, setName] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {setName(userDetails.name)},[]);

    const updateUserSetting = () => {
        const reqPay = {
            name: username
        }
        if(username.length > 0 && username !== userDetails.name)
            onboardedUpdate(reqPay, setIsLoading)
    }
    return (
        <div>
            <div>
                <h5 className="pl-3 pt-3" style={{ paddingBottom: 0 }}>General Settings</h5>
                <span className="pl-3 pb-3">General information about your profile</span>
                <Card className="row p-4 card-view-settings">
                    <div className="inputfeild-basic-group d-flex">
                        <TextField
                            size="small"
                            className="input-custom-css"
                            type="text"
                            id="outlined-basic"
                            label="Full Name"
                            variant="outlined"
                            value={username}
                            onChange={(e) => setName(e.target.value)}
                            style={{ marginRight: 10 }}
                        />
                        <TextField
                            size="small"
                            className="input-custom-css"
                            type="text"
                            id="outlined-basic"
                            label="Registered Email"
                            variant="outlined"
                            disabled
                            value={userDetails?.email}
                            style={{ marginRight: 10 }}
                        />
                    </div>
                    <div className="inputfeild-basic-group d-flex">
                        <TextField
                            size="small"
                            className="input-custom-css"
                            type="text"
                            id="outlined-basic"
                            label="User Role"
                            variant="outlined"
                            value={"Admin"}
                            disabled
                            style={{ marginRight: 10 }}
                        />
                        <TextField
                            size="small"
                            className="input-custom-css"
                            type="text"
                            id="outlined-basic"
                            label="Invitie"
                            variant="outlined"
                            disabled
                            style={{ marginRight: 10 }}
                        />
                    </div>
                    <div className="inputfeild-basic-group d-flex">
                        <TextField
                            size="small"
                            className="input-custom-css"
                            type="text"
                            id="outlined-basic"
                            label="Account Status"
                            variant="outlined"
                            value={"Active"}
                            disabled
                            style={{ marginRight: 10, width: "50%" }}
                        />
                         <FormControl variant="outlined" style={{ width: "50%", height: 40, marginRight: 10 }}>
                            <InputLabel htmlFor="outlined-adornment-password" style={{marginTop: "-6px"}}>User ID</InputLabel>
                            <OutlinedInput
                                size="small"
                                value={userDetails?.id}
                                className="input-custom-css"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            style={{fontSize: 14, color: "red"}}
                                        >
                                            <Icon icon="lucide:clipboard-copy" /> Copy 
                                        </IconButton>
                                    </InputAdornment>
                                }
                                disabled
                                label="User ID"
                            />
                        </FormControl>
                    </div>
                    {/* <div className="inputfeild-basic-group d-flex">
                        <FormControl variant="outlined" style={{ width: "50%", height: 40, marginRight: 10 }}>
                            <InputLabel htmlFor="outlined-adornment-password" style={{marginTop: "-6px"}}>User ID</InputLabel>
                            <OutlinedInput
                                size="small"
                                value={userDetails?.id}
                                className="input-custom-css"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            style={{fontSize: 14, color: "red"}}
                                        >
                                            <Icon icon="lucide:clipboard-copy" /> Copy 
                                        </IconButton>
                                    </InputAdornment>
                                }
                                disabled
                                label="User ID"
                            />
                        </FormControl> */}
                        {/* <FormControl variant="outlined" style={{ width: "50%", height: 40 }}>
                            <InputLabel htmlFor="outlined-adornment-password" style={{marginTop: "-6px"}}>SDK secret</InputLabel>
                            <OutlinedInput
                                size="small"
                                className="input-custom-css"
                                id="outlined-adornment-password"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                            style={{fontSize: 14, color: "red"}}
                                        >
                                            <Icon icon="lucide:clipboard-copy" /> Copy 
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="SDK secret"
                            />
                        </FormControl> */}
                    {/* </div> */}
                    <div style={{ width: '100%' }}>
                        <Button
                            onClick={() => {
                                updateUserSetting();
                            }}
                            disabled={username === userDetails.name || isLoading || username.length == 0}
                            variant="contained"
                            style={{ float: 'right' }}
                            data-modal="modal-13"
                        >
                            {isLoading ? "Updating...":"Update" }
                            
                        </Button>
                    </div>
                </Card>
            </div>
            <div>
                <h5 className="pl-3 pt-3" style={{ paddingBottom: 0 }}>Danger Zone</h5>
                <span className="pl-3 pb-3">Irreversible and destructive actions. Tread lightly</span>
                <div className='d-flex'>
                    <Card className="row p-2 card-view-settings" style={{ pointerEvents:"none", cursor: "not-allowed", opacity: .5}}>
                        <div className="d-flex my-3 align-items-center">
                            <Icon icon={"mingcute:key-2-line"} style={{ fontSize: 24, marginRight: 10, color: "red" }} />
                            <span style={{ fontWeight: 600, color: "red", fontSize: 16 }}>Deactivate account</span>
                        </div>
                        <div className='p-2'>Deactivate your account, on doing so your you and your team will be no longer get access to projects.</div>
                        <div style={{ width: '100%' }}>
                            <button className='text-align-center-button dz float-right' onClick={(e) => handleOpenInvite(e)}>Deactivate account</button>
                        </div>
                    </Card>
                    <Card className="row p-2 card-view-settings" style={{ pointerEvents:"none", cursor: "not-allowed", opacity: .5}}>
                        <div className="d-flex my-3 align-items-center">
                            <Icon icon={"mingcute:delete-2-line"} style={{ fontSize: 24, marginRight: 10, color: "red" }} />
                            <span style={{ fontWeight: 600, color: "red", fontSize: 16 }}>Delete Account</span>
                        </div>
                        <div className='p-2'>Deleting a Account is permanent and removes all data from our servers. All members will immediately lose access</div>
                        <div style={{ width: '100%' }}>
                            <button className='text-align-center-button dz float-right' onClick={(e) => handleOpenInvite(e)}>Delete Account</button>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default GeneralSetting