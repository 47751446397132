import React from "react";
import { Box, Divider, List } from "@mui/material";
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Icon } from "@iconify/react";
import { ToLocalDate } from "../../utils/Response";
const History = ({ onClose, data, userDetails }) => {

  const lastValue = (id) => {
    let ff = ''
    if(id > 1){
      const d = data?.history?.slice(0,id);
      for (let index = 0; index < d.length; index++) {
        const element = d[index];
        if(element?.action == "UPDATE") 
          if(element.hasOwnProperty('text')) 
            ff = element.text
          else
            continue
        else
          ff = " null"
      }
    }
    return ff
  }
  return (
    <Box
      sx={{ width: 350, height: "100%", overflow: "hidden" }}
      role="presentation"
    //   onClick={() => onClose(false)}
    //   onKeyDown={() => onClose(false)}
    >
      <div className="">
        <div>
          <List>
            <div className="px-2 py-3">
              <div className="d-flex justify-content-sm-between">
                <h4>Translation History</h4>
                <Icon icon="material-symbols:close-rounded" style={{ fontSize: 24, cursor: "pointer" }} onClick={() => onClose()} />
              </div>
              <p style={{ margin: 0 }}>Timeline for <b style={{ color: "blue" }}>page.home.Welcome</b></p>
              <p style={{ margin: 0 }}>Locale: <b style={{ color: "blue" }}>{data?.locale}</b></p>
              <p style={{ margin: 0 }}>Value: <b style={{ color: "blue" }}>{data?.value}</b></p>
            </div>
          </List>
        </div>
        <Divider />
        <div style={{ overflowY: "scroll", height: "80vh", }}>
          <Timeline
            style={{ width: "100%", flexDirection: "column-reverse" }}
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >

            {data?.history?.map((dataItm, id) =>
              <TimelineItem key={id}>
                <TimelineSeparator>
                  <TimelineDot color="success" variant="outlined" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <div>
                    <p style={{ color: "#918a8a", fontWeight: 500 }}>{ToLocalDate(dataItm?.dateTime)}</p>
                    <b >Key <span style={{ textTransform: "lowercase" }}>{dataItm?.action}</span></b>
                    {dataItm?.action == "CREATE" ?
                      <p> {userDetails?.name} {" "}
                        <span style={{ textTransform: "lowercase" }}>
                          {dataItm?.action}d
                        </span> the key with value
                        <p style={{ color: "blue" }}>
                          {dataItm?.text ? dataItm?.text : " null"}
                        </p>
                      </p>
                      :
                      dataItm?.action == "UPDATE" &&
                        dataItm.hasOwnProperty('reviewed') ? <p> {userDetails?.name} has
                        <span style={{ color: "blue" }}>{" "}
                          {dataItm?.reviewed ? " reviewed " : " unreviewed "}
                        </span>
                        the key
                      </p> :
                        <p> {userDetails?.name} {" "}
                          <span style={{ textTransform: "lowercase" }}>
                            {dataItm?.action}d
                          </span> the key with value from
                          <span style={{ color: "blue" }}>{" "}
                            {lastValue(id)?.length > 0 ? lastValue(id) : " null"}
                          </span>  to
                          <span style={{ color: "blue" }}>{" "}
                            {dataItm?.text ? dataItm?.text : " null"}
                          </span>
                        </p>
                    }
                    <div className="d-flex justify-content-sm-between">
                      <p style={{ color: "grey" }}>Auther: {userDetails?.name}</p>
                    </div>
                  </div>
                </TimelineContent>
              </TimelineItem>
            )}
          </Timeline>
        </div>
      </div>
    </Box>
  )
}

export default History