import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Logo from '../../Assets/images/icon.png';
import Feedback from "../Feedback";

const SideNavBar = () => {
  const projectId = useSelector(state => state?.project?.selectedProject?.id);
  const projectName = useSelector(state => state?.project?.selectedProject?.name);
  console.log("pathname", window.location.pathname.split('/')[3]);
  const [open, setOpen] = useState(false);
  return (
    <nav className="pcoded-navbar noprint" id="admin_menu">
      <Feedback open={open} handleClose={() => setOpen(false)} />
      <a className="mobile-menu" id="mobile-collapse" style={{ cursor: "pointer" }}>
        <i className="feather icon-menu" style={{ color: "white" }}></i>
      </a>
      <div className="pcoded-inner-navbar main-menu">
        <div className="navbar-logo flex-fs" style={{ marginTop: 5, alignItems: "center", borderTop: "none" }}>
          <Link to="/">
            <img className="img-fluid img-70" src={Logo} alt="Vernacle" />
          </Link>
          <span className="titles-project-navbar" style={{ color: "white", fontWeight: "bold", fontSize: 16 }}>{projectName.slice(0, 20)}{projectName?.length > 20 && ".."}</span>
          {/* <a className="mobile-options">
                <i className="feather icon-more-horizontal"></i>
              </a> */}
        </div>
        <div className="pcoded-navigatio-lavel"> <b>DASHBOARD</b></div>
        <ul className="pcoded-item pcoded-left-item pt-2">
          <li className={window.location.pathname.split('/')[3] == "translation" ? "pcoded-trigger wt-translation " : "wt-translation"} >
            <Tooltip className="tool-cust-opac" title="Translation" arrow placement="right">
              <Link to={`/home/${projectId}/translation`}>
                <span className="pcoded-micon">
                  <i className="feather icon-file-text"></i>
                </span>
                <span className="pcoded-mtext">Translation</span>
              </Link>
            </Tooltip>
          </li>
          <li className={window.location.pathname.split('/')[3] == "langauges" ? "pcoded-trigger wt-langauge " : "wt-langauge"}>
            <Tooltip className="tool-cust-opac" title="Langauge" arrow placement="right">
              <Link to={`/home/${projectId}/languages`}>
                <span className="pcoded-micon">
                  <i className="icofont icofont-globe"></i>
                </span>
                <span className="pcoded-mtext">Langauge</span>
              </Link>
            </Tooltip>
          </li>
          <li className={window.location.pathname.split('/')[3] == "uploads" ? "pcoded-trigger wt-uploadss " : "wt-uploadss"}>
            <Tooltip className="tool-cust-opac" title="Upload" arrow placement="right">
              <Link to={`/home/${projectId}/uploads`}>
                <span className="pcoded-micon">
                  <i className="feather icon-upload"></i>
                </span>
                <span className="pcoded-mtext">Upload</span>
              </Link>
            </Tooltip>
          </li>
          <li className={window.location.pathname.split('/')[3] == "downloads" ? "pcoded-trigger wt-download " : "wt-download"}>
            <Tooltip className="tool-cust-opac" title="Download" arrow placement="right">
              <Link to={`/home/${projectId}/downloads`}>
                <span className="pcoded-micon">
                  <i className="feather icon-download"></i>
                </span>
                <span className="pcoded-mtext">Download</span>
              </Link>
            </Tooltip>
          </li>
          <li className={window.location.pathname.split('/')[3] == "analytics" ? "pcoded-trigger wt-analytics" : "wt-analytics"}>
            <Tooltip className="tool-cust-opac" title="Analytics" arrow placement="right">
              <Link to={`/home/${projectId}/analytics`}>
                <span className="pcoded-micon">
                  <i className="feather icon-pie-chart"></i>
                </span>
                <span className="pcoded-mtext">Analytics</span>
              </Link>
            </Tooltip>
          </li>
          <li className={window.location.pathname.split('/')[3] == "translation-orders" ? "pcoded-trigger wt-order" : "wt-order"}>
            <Tooltip className="tool-cust-opac" title="Bugs" arrow placement="right">
              <Link to={`/home/${projectId}/translation-orders`}>
                <span className="pcoded-micon">
                  <i className="icofont icofont-cart-alt"></i>
                </span>
                <span className="pcoded-mtext">Translation orders </span>
              </Link>
            </Tooltip>
          </li>
        </ul>
        <div className="pcoded-navigatio-lavel"> <b>SETTINGS</b></div>
        <ul className="pcoded-item pcoded-left-item">
          <li className={window.location.pathname.split('/')[3] == "settings" ? "pcoded-trigger wt-settings " : "wt-settings"}>
            <Tooltip className="tool-cust-opac" title="Account Setting" arrow placement="right">
              <Link to={`/home/${projectId}/settings`} >
                <span className="pcoded-micon">
                  <i className="icofont icofont-options"></i>
                </span>
                <span className="pcoded-mtext">Setting</span>
              </Link>
            </Tooltip>
          </li>
        </ul>
      </div>
      <div className="px-4 py-0" style={{position: 'absolute', bottom: 20, zIndex:100}}>
        <div>
          <Tooltip className="tool-cust-opac" title="Get Started" arrow placement="right">
            <a className="hover_side_bar_info" style={{ color: "white", fontSize: 16 }} href="https://docs.vernacle.in/docs/prologue/introduction/" target="_blank">
              <span className="pcoded-micon">
                <i className="icofont icofont-paper-plane" style={{ fontSize: 20 }}></i>
              </span>
              <span className="pcoded-mtext ml-2">Developers Docs</span>
            </a>
          </Tooltip>
        </div>
        <div className="py-2">
          <Tooltip className="tool-cust-opac" title="Question & FAQ" arrow placement="right">
            <a className="hover_side_bar_info" href="https://vernacle.in/" style={{ color: "white", fontSize: 16 }} target="_blank">
              <span className="pcoded-micon">
                <i className="icofont icofont-support-faq" style={{ fontSize: 20 }}></i>
              </span>
              <span className="pcoded-mtext ml-2">FAQ</span>
            </a>
          </Tooltip>
        </div>
        <div>
          <Tooltip className="tool-cust-opac" title="Feedback" arrow placement="right">
            <Link className="hover_side_bar_info" onClick={() => setOpen(true)} style={{ color: "white", fontSize: 16 }}>
              <span className="pcoded-micon">
                <i className="icofont icofont-megaphone" style={{ fontSize: 20 }}></i>
              </span>
              <span className="pcoded-mtext ml-2">Feedback</span>
            </Link>
          </Tooltip>
        </div>
      </div>
    </nav>
  );
}
export default SideNavBar;
