import * as XLSX from "xlsx";


export const excelToArray = (file, setData) => {
    const reader = new FileReader();
    let finalArray = [];
    reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        finalArray = XLSX.utils.sheet_to_json(worksheet);
        setData(finalArray);
    }
    reader.readAsArrayBuffer(file);
}

export const jsonToArray = (file) => {
    const reader = new FileReader();
    function logFile (event) {
        let str = event.target.result;
        let json = JSON.parse(str);
        const finalArry = [];
        Object.keys(json)?.forEach(element => {
            let object = {
                key: element,
                value: json[element]
            }
            finalArry.push(object);
        });
    }
    reader.onload = logFile;
    reader.readAsText(file);
}

export const csvToArray = (file, setData) => {
    const reader = new FileReader();
    function csvToArray(str, delimiter = ",") {
        const headers = str.slice(0, str.indexOf("\n")).split(delimiter);
        const rows = str.slice(str.indexOf("\n") + 1).split("\n");
        const arr = rows.map(function (row) {
          const values = row.split(delimiter);
          const el = headers.reduce(function (object, header, index) {
            object[header.trim()] = values[index];
            return object;
          }, {});
          return el;
        });
        setData(arr);
        return arr;
      }
    function logFile (event) {
        let str = event.target.result;
        const data = csvToArray(str);
    }
    reader.onload = logFile;
    reader.readAsText(file);
}