import axios from "axios";
import { GET_PROJECT_LIST, PROJECT_SELECTED } from "../../Redux/slices/ProjectSlice";
import { HANDLE_SNACKBAR } from "../../Redux/slices/SnackbarAlert";
import { store } from "../../store";
import { apiGateway } from "../../utils/constant/apigateways";
import { ResponseError, ResponseSuccess } from "../../utils/Response";

export const postProject = (payload, setLoading, handleClose, fetchProjectList, getClear) => {
    setLoading(true);
    axios.post(apiGateway + "/project", payload, {
       headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem('token-le-wegid')}`
       }
    })
    .then(res => {
       if(ResponseSuccess(res).status == true){
          setLoading(false);
        //   store.dispatch(POST_PROJECT(res?.data?.data));
          store.dispatch(HANDLE_SNACKBAR({
            open: true,
            message: "Wow! Project added successfully.",
            type: "success"
          }));
       }else{
            store.dispatch(HANDLE_SNACKBAR({
                open: true,
                message: "Something went wrong!",
                type: "error"
            }));
       }
    })
    .catch(() => {
        store.dispatch(HANDLE_SNACKBAR({
            open: true,
            message: "Something went wrong!",
            type: "error"
        }));
       setLoading(false);
    }).finally(() => {
        getClear();
        handleClose();
        fetchProjectList();
    })
}

export const updateProject = (payload, setLoading, projectDetails, handleClose, reLoad) => {
   setLoading(true);
   axios.put(apiGateway + "/project", payload, {
      headers: {
         "Content-Type": "application/json",
         "Authorization": `Bearer ${localStorage.getItem('token-le-wegid')}`
      }
   })
   .then(res => {
      if(ResponseSuccess(res).status == true){
         let newSelected = {...projectDetails};
         newSelected["name"] = ResponseSuccess(res).response?.projectName
         newSelected["locales"] = ResponseSuccess(res).response?.locales;
         store.dispatch(HANDLE_SNACKBAR({
            open: true,
            message: "Wow! Project updated successfully.",
            type: "success"
          }));
         store.dispatch(PROJECT_SELECTED(newSelected));
      }else{
           store.dispatch(HANDLE_SNACKBAR({
               open: true,
               message: "Something went wrong",
               type: "error"
           }));
      }
   })
   .catch((err) => {
      console.log("error", ResponseError(err))
   }).finally(() => {
      setLoading(false);
      handleClose();
      reLoad();
   })
}

export const projectList = ( setLoading ) => {
    setLoading(true);
    axios.get(apiGateway + "/project", {
       headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem('token-le-wegid')}`
       }
    })
    .then(res => {
       if(ResponseSuccess(res).status == true){
          setLoading(false);
          store.dispatch(GET_PROJECT_LIST(ResponseSuccess(res).response));
       }else{
            store.dispatch(HANDLE_SNACKBAR({
                open: true,
                message: "Something went wrong!",
                type: "error"
            }));
       }
    })
    .catch(() => {
        store.dispatch(HANDLE_SNACKBAR({
            open: true,
            message: "Something went wrong!",
            type: "error"
        }));
       setLoading(false);
    })
}