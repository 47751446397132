import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import DropZoneCus from '../../Custom/DropZoneCus';
import { Icon } from '@iconify/react';
import { CustomTableForm } from '../../Custom/CustomTableForm';
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from '@mui/material';

const steps = [
    {
        label: 'File Selecetion',
        description: `For uploading the documents please select file (File format supported JSON, EXCEL, CSV).`,
    },
    {
        label: 'Upload Config',
        description:
            'After selection of file please specify the different settings.',
    },
];

export default function Uploads() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [array, setData] = React.useState([]);
    const [openCustom, setOpenCustom] = React.useState(false);

    const handleClose = () => setOpenCustom(!openCustom);

    const handleNext = () => {
        if (!array?.length > 0 && activeStep !== 1) {
            return
        }
        if (activeStep == 0) {
            handleClose()
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setData([])
    };

    return (
        <div className="pcoded-inner-content">
            <div className="main-body">
                <div className="page-wrapper">
                    <div className="page-header">
                        <div className="row align-items-end">
                            <div className="col-lg-8">
                                <div className="page-header-title">
                                    <div className="d-inline">
                                        <h4>Upload Files</h4>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-4">
            <Link
                onClick={() => {
                  setFormOpen(!formOpen);
                }}
                className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger"
                data-modal="modal-13"
              >
                {" "}
                <i className="icofont icofont-plus m-r-5"></i> Add Language
              </Link>
            </div> */}
                        </div>
                    </div>
                    <Paper sx={{ width: '100%', height: "72vh", marginBottom: 20, overflowY: "scroll" }} className="flex-fs paper-steper-upload paper-c-shadow">
                        <Box sx={{ width: "70%", paddingBottom: 40 }}>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                {steps.map((step, index) => (
                                    <Step key={step.label}>
                                        <StepLabel
                                            optional={
                                                index === 2 ? (
                                                    <Typography variant="caption">Last step</Typography>
                                                ) : null
                                            }
                                        >
                                            {step.label}
                                        </StepLabel>
                                        <StepContent>
                                            <Typography>{step.description}</Typography>
                                            {index == 0 && <Box height={350}>
                                                <DropZoneCus setData={setData} array={array} />
                                            </Box>
                                            }
                                            <CustomTableForm open={openCustom} array={array} handleClose={handleReset} />
                                            {index == 1 && <Box height={250}>
                                                <div className='mt-4'>
                                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                                        <InputLabel id="demo-simple-select-standard-label">Select Language</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            value={10}
                                                            // onChange={handleChange}
                                                            label="Select Language"
                                                        >
                                                            <MenuItem value={10}>English</MenuItem>
                                                            <MenuItem value={20}>Hindi</MenuItem>
                                                            <MenuItem value={30}>French</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div className='mt-5 flex-sb'>
                                                    <div className='grid-fs'>
                                                        <FormControlLabel control={<Checkbox defaultChecked />} label="Overwrite translations for selected language" />
                                                        <FormControlLabel control={<Checkbox defaultChecked />} label="Mark all rows as verified" /> 
                                                    </div>
                                                    <div className='grid-fs'>
                                                        <FormControlLabel control={<Checkbox defaultChecked />} label="Tags" /> 
                                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                                        <InputLabel id="demo-simple-select-standard-label">Tag new keys</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            value={10}
                                                            // onChange={handleChange}
                                                            label="tags"
                                                        >
                                                            <MenuItem value={10}>new</MenuItem>
                                                            <MenuItem value={30}>uploaded</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    </div>
                                                </div>
                                            </Box>
                                            }
                                            <Box sx={{ mb: 2, mt: 3 }}>
                                                <div>
                                                    <Button
                                                        variant="contained"
                                                        onClick={handleNext}
                                                        size={"small"}
                                                        sx={{ mt: 1, mr: 1 }}

                                                    >
                                                        {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                                    </Button>
                                                    <Button
                                                        disabled={index === 0}
                                                        onClick={handleBack}
                                                        color="primary"
                                                        style={{ color: index !== 0 && "#6366f1" }}
                                                        size={"small"}
                                                        sx={{ mt: 1, mr: 1 }}
                                                    >
                                                        Back
                                                    </Button>
                                                </div>
                                            </Box>
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                            {activeStep === steps.length && (
                                <Paper square elevation={0} sx={{ p: 3 }}>
                                    <Typography style={{ background: "#6366f1", padding: 5, color: "white", borderRadius: 8}}>All steps completed - you&apos;re finished</Typography>
                                    <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }} style={{ color: "#6366f1" }}>
                                        Upload again
                                    </Button>
                                    <Button onClick={()=>{}} sx={{ mt: 1, mr: 1 }} style={{ color: "#6366f1" }}>
                                        View Translation
                                    </Button>
                                </Paper>
                            )}
                        </Box>
                        <Box sx={{ width: "30%", }}>
                            <div className='m-3 p-4 wrap-info-supported'>
                                <b>Supported files: </b>
                                <ul className='supported-list-wrapper'>
                                    <li>
                                        <div className='supported-list'>
                                            <span>Key-Value JSON (.json): <Icon icon="eva:download-fill" /></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='supported-list'>
                                            <span>CSV (.csv): <Icon icon="eva:download-fill" /></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='supported-list'>
                                            <span>Excel XLSX (.xlsx, .xls): <Icon icon="eva:download-fill" /></span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Box>
                    </Paper>
                </div>
            </div>
        </div>
    );
}
