import axios from "axios";
import { GET_COMMENT_LIST } from "../../Redux/slices/commentsSlice";
import { HANDLE_SNACKBAR } from "../../Redux/slices/SnackbarAlert";
import { store } from "../../store";
import { apiGateway } from "../../utils/constant/apigateways";
import { ResponseSuccess } from "../../utils/Response";

export const commentList = ( setIscmtLoading, projectID, keyId, resolved ) => {
    setIscmtLoading(true);
    axios.post(apiGateway + `/comments/list?isResolved=${resolved}` , {
        projectId: projectID,
        keyId: keyId
    }, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('token-le-wegid')}`
        }
    })
    .then(res => {
        setIscmtLoading(false)
        if(ResponseSuccess(res).status == true){
          store.dispatch(GET_COMMENT_LIST(ResponseSuccess(res).response));
        }else{
            store.dispatch(HANDLE_SNACKBAR({
                open: true,
                message: "Something went wrong!",
                type: "error"
            }));
       }
    })
    .catch((err) => {
        setIscmtLoading(false)
        store.dispatch(HANDLE_SNACKBAR({
            open: true,
            message: "Not able to fetch comments!",
            type: "error"
        }));
    })
}

export const postComments = ( setLoading, requestBody, setComment, slackInfo, getComment ) => {
    setLoading(true);
    axios.post(apiGateway + "/comments", requestBody, {
       headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem('token-le-wegid')}`
       }
    })
    .then(res => {
       if(ResponseSuccess(res).status == true){
          setLoading(false);
          setComment("");
       }else{
            store.dispatch(HANDLE_SNACKBAR({
                open: true,
                message: "Unable to comment",
                type: "error"
            }));
       }
    })
    .catch((error) => {
        store.dispatch(HANDLE_SNACKBAR({
            open: true,
            message: "Unable to comment",
            type: "error"
        }));
       setLoading(false);
    }).finally(() => {
        getComment();
    })
}

export const deleteComments = ( setLoading, requestBody, getComment ) => {
    setLoading(true);
    axios.delete(apiGateway + "/comments", {
       headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem('token-le-wegid')}`
       },
       data: requestBody
    })
    .then(res => {
       if(ResponseSuccess(res).status == true){
          setLoading(false);
          store.dispatch(HANDLE_SNACKBAR({
            open: true,
            message: "Yup! Comment deleted successfully",
            type: "success"
        }));
        //   store.dispatch(GET_KEYS_LIST(res?.data?.data));
       }else{
            store.dispatch(HANDLE_SNACKBAR({
                open: true,
                message: "Something went wrong!",
                type: "error"
            }));
       }
    })
    .catch(() => {
        store.dispatch(HANDLE_SNACKBAR({
            open: true,
            message: "Something went wrong!",
            type: "error"
        }));
       setLoading(false);
    }).finally(() => {
        getComment()
    })
}

export const updateComments = (setLoading, requestBody, getComment) => {
    setLoading(true);
    axios.patch(apiGateway + "/comments", requestBody, {
       headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem('token-le-wegid')}`
       }
    })
    .then(res => {
       if(ResponseSuccess(res).status == true){
          setLoading(false);
          store.dispatch(HANDLE_SNACKBAR({
            open: true,
            message: "Yup! Comment is updated.",
            type: "success"
        }));
        //   store.dispatch(GET_KEYS_LIST(res?.data?.data));
       }else{
            store.dispatch(HANDLE_SNACKBAR({
                open: true,
                message: "Something went wrong!",
                type: "error"
            }));
       }
    })
    .catch(() => {
        store.dispatch(HANDLE_SNACKBAR({
            open: true,
            message: "Something went wrong!",
            type: "error"
        }));
       setLoading(false);
    }).finally(() => {
        getComment()
    })
}

export const triggerSlackWebhook = (msg, slackInfo) => {
    const message = {
        text: msg.value
    };
    axios.post(slackInfo[0]?.url, JSON.stringify(message))
    .then((response) => {
        console.log(`Message posted to Slack: ${response.data}`);
    })
    .catch((error) => {
        console.error(`Error posting message to Slack: ${error}`);
    })
}