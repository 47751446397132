import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import { Divider } from '@mui/material';
import { postFeedback } from '../Services/Networks/Feedback';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Feedback({ handleClose, open }) {
  const userData = JSON.parse(localStorage.getItem("userObj"));
  const [isLoading, setIsLoading] = useState(false);
  const [emotion, setEmotion] = useState('5');
  const [type, setType] = useState('bug');
  const [description, setDescription] = useState('');
  const [error, setError] = useState('');
  
  const saveFeedback = () => {
    if(emotion == "" || emotion == null || emotion == undefined){
       return setError("Please select the ratings");
    }else{
        const feedbackPayload  = {
            userId: userData?.id,
            level_e: emotion,
            isuser: true,
            type: "feedback",
            type_t: type,
            description: description,
            email: userData?.email
        }
        postFeedback(setIsLoading, feedbackPayload, handleClose, clearFields)
    }
  }

  const clearFields = () => {
    setEmotion('5');
    setType('bug');
    setDescription("")
  }

  const placeholder = () => {
    let finalPlace = '';
    if(type == 'bug') finalPlace = 'What we can solve for you ?';
    if(type == 'improvement') finalPlace = 'We always look for what we can imporve for you ?';
    if(type == 'feature') finalPlace = 'We are here for your new need which make your work easy ?';
    return finalPlace
  }
  return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle  className='flex-sb'>
            <span>Feedback</span>
            <Icon icon={"eva:close-fill"} onClick={handleClose} style={{fontSize: 30, cursor: "pointer" }}/>
        </DialogTitle>
        <Divider />
        <DialogContent>
            <div>
                <div>
                    <p style={{ fontSize: 20 }}>How does Vernacle Portal as a platform make you feel?</p>
                    <ul className='d-flex flex-c'>
                        <li className='mx-2 text-align-center'><Icon icon={"bi:emoji-smile-upside-down"} style={{fontSize: 54, cursor: "pointer", color: emotion == '1' ?"#6366f1" : "" }} onClick={() => setEmotion('1')}/><p>Terrible</p></li>
                        <li className='mx-2 text-align-center'><Icon icon={"bi:emoji-frown"} style={{fontSize: 54, cursor: "pointer", color: emotion == '2' ?"#6366f1" : ""}} onClick={() => setEmotion('2')}/><p>Bad</p></li>
                        <li className='mx-2 text-align-center'><Icon icon={"bi:emoji-neutral"} style={{fontSize: 54, cursor: "pointer", color: emotion == '3' ?"#6366f1" : "" }} onClick={() => setEmotion('3')}/><p>Okay</p></li>
                        <li className='mx-2 text-align-center'><Icon icon={"bi:emoji-smile"} style={{fontSize: 54, cursor: "pointer", color: emotion == '4' ?"#6366f1" : "" }} onClick={() => setEmotion('4')}/><p>Good</p></li>
                        <li className='mx-2 text-align-center'><Icon icon={"bi:emoji-heart-eyes"} style={{fontSize: 54, cursor: "pointer", color: emotion == '5' ?"#6366f1" : "" }} onClick={() => setEmotion('5')}/><p>Amazing</p></li>
                    </ul>
                </div>
                <div className='my-4'>
                    <b style={{ fontSize: 14, marginBottom: 0 }}>Email Address</b><br/>
                    <input value={userData?.email} disabled placeholder='jhon.doe@example.xyz' style={{width: "90%", height: 40, paddingLeft: 10, borderRadius: 5, border: "1px solid #6366f1"}}/>
                </div>
                <div>
                    <b style={{ fontSize: 14, marginBottom: 0 }}>Description</b><br/>
                    <div>
                        <ul className='d-flex flex-fs mt-2'>
                            <li className='d-flex align-items-center cursor-pointer'  onClick={() => setType('bug')} style={type == "bug" ? { borderBottom: "2px solid #6366f1" , color: "#6366f1"} : {}}><Icon icon={"ph:bug-fill"} style={{fontSize: 20, cursor: "pointer", marginRight: 8 }}/> Bug</li>
                            <li className='d-flex align-items-center cursor-pointer mx-4' onClick={() => setType('improvement')} style={ type == "improvement" ?  { borderBottom:  "2px solid #6366f1" , color: "#6366f1"} : {}}><Icon icon={"mdi:restore-alert"} style={{fontSize: 20, cursor: "pointer", marginRight: 8 }}/> Improvement</li>
                            <li className='d-flex align-items-center cursor-pointer'  onClick={() => setType('feature')} style={type == "feature" ? { borderBottom: "2px solid #6366f1" , color: "#6366f1"} : {}}><Icon icon={"iconoir:light-bulb"} style={{fontSize: 20, cursor: "pointer", marginRight: 8 }}/> Feature</li>
                        </ul>
                        <Divider />
                    </div>
                    <div className='mt-3'>
                        <textarea value={description} style={{width: "90%", height: 100, paddingLeft: 10, borderRadius: 5, border: "1px solid #6366f1"}} placeholder={placeholder()} onChange={(e) => setDescription(e.target.value)}/>
                    </div>
                </div>
            </div>
        </DialogContent>
        <DialogActions className='flex-c d-flex'>
            {error?.length > 0  && <p>{error}</p>}
            <Button onClick={() => saveFeedback()} variant="contained" style={{background: "#6366f1"}} disabled={isLoading}>{isLoading ? "Submitting..." : "Submit Feedback" }</Button>
        </DialogActions>
      </Dialog>
  );
}
