export const langData  = [{
    id: 1,
    code: "en-US",
    langauge: "English (US)",
    default: true,
},{
    id: 2,
    code: "de",
    langauge: "German",
    default: false,
},{
    id: 3,
    code: "fr",
    langauge: "French",
    default: false,
},{
    id: 4,
    code: "hi-IN",
    langauge: "Hindi (IN)",
    default: false,
}]

export const abbreviationLang = [
    {
      "lang": "Afrikaans",
      "country": "South Africa",
      "dominat_locale": "af_ZA",
      "abbreviation": "af_ZA",
      "locale_name": "Afrikaans (South Africa)"
    },
    {
      "lang": "Albanian",
      "country": "Albania",
      "dominat_locale": "sq_AL",
      "abbreviation": "sq_AL",
      "locale_name": "Albanian (Albania)"
    },
    {
      "lang": "Arabic",
      "country": "Algeria",
      "dominat_locale": "ar_SA",
      "abbreviation": "ar_DZ",
      "locale_name": "Arabic (Algeria)"
    },
    {
      "lang": "Arabic",
      "country": "Bahrain",
      "dominat_locale": "ar_SA",
      "abbreviation": "ar_BH",
      "locale_name": "Arabic (Bahrain)"
    },
    {
      "lang": "Arabic",
      "country": "Egypt",
      "dominat_locale": "ar_SA",
      "abbreviation": "ar_EG",
      "locale_name": "Arabic (Egypt)"
    },
    {
      "lang": "Arabic",
      "country": "Iraq",
      "dominat_locale": "ar_SA",
      "abbreviation": "ar_IQ",
      "locale_name": "Arabic (Iraq)"
    },
    {
      "lang": "Arabic",
      "country": "Jordan",
      "dominat_locale": "ar_SA",
      "abbreviation": "ar_JO",
      "locale_name": "Arabic (Jordan)"
    },
    {
      "lang": "Arabic",
      "country": "Kuwait",
      "dominat_locale": "ar_SA",
      "abbreviation": "ar_KW",
      "locale_name": "Arabic (Kuwait)"
    },
    {
      "lang": "Arabic",
      "country": "Lebanon",
      "dominat_locale": "ar_SA",
      "abbreviation": "ar_LB",
      "locale_name": "Arabic (Lebanon)"
    },
    {
      "lang": "Arabic",
      "country": "Libya",
      "dominat_locale": "ar_SA",
      "abbreviation": "ar_LY",
      "locale_name": "Arabic (Libya)"
    },
    {
      "lang": "Arabic",
      "country": "Morocco",
      "dominat_locale": "ar_SA",
      "abbreviation": "ar_MA",
      "locale_name": "Arabic (Morocco)"
    },
    {
      "lang": "Arabic",
      "country": "Oman",
      "dominat_locale": "ar_SA",
      "abbreviation": "ar_OM",
      "locale_name": "Arabic (Oman)"
    },
    {
      "lang": "Arabic",
      "country": "Qatar",
      "dominat_locale": "ar_SA",
      "abbreviation": "ar_QA",
      "locale_name": "Arabic (Qatar)"
    },
    {
      "lang": "Arabic",
      "country": "Saudi Arabia",
      "dominat_locale": "ar_SA",
      "abbreviation": "ar_SA",
      "locale_name": "Arabic (Saudi Arabia)"
    },
    {
      "lang": "Arabic",
      "country": "Syria",
      "dominat_locale": "ar_SA",
      "abbreviation": "ar_SY",
      "locale_name": "Arabic (Syria)"
    },
    {
      "lang": "Arabic",
      "country": "Tunisia",
      "dominat_locale": "ar_SA",
      "abbreviation": "ar_TN",
      "locale_name": "Arabic (Tunisia)"
    },
    {
      "lang": "Arabic",
      "country": "United Arab Emirates",
      "dominat_locale": "ar_SA",
      "abbreviation": "ar_AE",
      "locale_name": "Arabic (United Arab Emirates)"
    },
    {
      "lang": "Arabic",
      "country": "Yemen",
      "dominat_locale": "ar_SA",
      "abbreviation": "ar_YE",
      "locale_name": "Arabic (Yemen)"
    },
    {
      "lang": "Armenian",
      "country": "Armenia",
      "dominat_locale": "hy_AM",
      "abbreviation": "hy_AM",
      "locale_name": "Armenian (Armenia)"
    },
    {
      "lang": "Azerbaijani",
      "country": "Azerbaijan",
      "dominat_locale": "az_AZ",
      "abbreviation": "az_AZ",
      "locale_name": "Azerbaijani (Azerbaijan)"
    },
    {
      "lang": "Basque",
      "country": "Spain",
      "dominat_locale": "eu_ES",
      "abbreviation": "eu_ES",
      "locale_name": "Basque (Spain)"
    },
    {
      "lang": "Belarusian",
      "country": "Belarus",
      "dominat_locale": "be_BY",
      "abbreviation": "be_BY",
      "locale_name": "Belarusian (Belarus)"
    },
    {
      "lang": "Bengali",
      "country": "India",
      "dominat_locale": "bn_IN",
      "abbreviation": "bn_IN",
      "locale_name": "Bengali (India)"
    },
    {
      "lang": "Bosnian",
      "country": "Bosnia and Herzegovina",
      "dominat_locale": "bs_BA",
      "abbreviation": "bs_BA",
      "locale_name": "Bosnian (Bosnia and Herzegovina)"
    },
    {
      "lang": "Bulgarian",
      "country": "Bulgaria",
      "dominat_locale": "bg_BG",
      "abbreviation": "bg_BG",
      "locale_name": "Bulgarian (Bulgaria)"
    },
    {
      "lang": "Catalan",
      "country": "Spain",
      "dominat_locale": "ca_ES",
      "abbreviation": "ca_ES",
      "locale_name": "Catalan (Spain)"
    },
    {
      "lang": "Chinese",
      "country": "China",
      "dominat_locale": "zh_CN",
      "abbreviation": "zh_CN",
      "locale_name": "Chinese (China)"
    },
    {
      "lang": "Chinese",
      "country": "Hong Kong SAR China",
      "dominat_locale": "zh_TW",
      "abbreviation": "zh_HK",
      "locale_name": "Chinese (Hong Kong SAR China)"
    },
    {
      "lang": "Chinese",
      "country": "Macao SAR China",
      "dominat_locale": "zh_TW",
      "abbreviation": "zh_MO",
      "locale_name": "Chinese (Macao SAR China)"
    },
    {
      "lang": "Chinese",
      "country": "Singapore",
      "dominat_locale": "zh_CN",
      "abbreviation": "zh_SG",
      "locale_name": "Chinese (Singapore)"
    },
    {
      "lang": "Chinese",
      "country": "Taiwan",
      "dominat_locale": "zh_TW",
      "abbreviation": "zh_TW",
      "locale_name": "Chinese (Taiwan)"
    },
    {
      "lang": "Croatian",
      "country": "Croatia",
      "dominat_locale": "hr_HR",
      "abbreviation": "hr_HR",
      "locale_name": "Croatian (Croatia)"
    },
    {
      "lang": "Czech",
      "country": "Czech Republic",
      "dominat_locale": "cs_CZ",
      "abbreviation": "cs_CZ",
      "locale_name": "Czech (Czech Republic)"
    },
    {
      "lang": "Danish",
      "country": "Denmark",
      "dominat_locale": "da_DK",
      "abbreviation": "da_DK",
      "locale_name": "Danish (Denmark)"
    },
    {
      "lang": "Dutch",
      "country": "Belgium",
      "dominat_locale": "nl_NL",
      "abbreviation": "nl_BE",
      "locale_name": "Dutch (Belgium)"
    },
    {
      "lang": "Dutch",
      "country": "Netherlands",
      "dominat_locale": "nl_NL",
      "abbreviation": "nl_NL",
      "locale_name": "Dutch (Netherlands)"
    },
    {
      "lang": "English",
      "country": "Australia",
      "dominat_locale": "en_US",
      "abbreviation": "en_AU",
      "locale_name": "English (Australia)"
    },
    {
      "lang": "English",
      "country": "Belize",
      "dominat_locale": "en_US",
      "abbreviation": "en_BZ",
      "locale_name": "English (Belize)"
    },
    {
      "lang": "English",
      "country": "Canada",
      "dominat_locale": "en_US",
      "abbreviation": "en_CA",
      "locale_name": "English (Canada)"
    },
    {
      "lang": "English",
      "country": "Ireland",
      "dominat_locale": "en_US",
      "abbreviation": "en_IE",
      "locale_name": "English (Ireland)"
    },
    {
      "lang": "English",
      "country": "India",
      "dominat_locale": "en_US",
      "abbreviation": "en_IN",
      "locale_name": "English (India)"
    },
    {
      "lang": "English",
      "country": "Jamaica",
      "dominat_locale": "en_US",
      "abbreviation": "en_JM",
      "locale_name": "English (Jamaica)"
    },
    {
      "lang": "English",
      "country": "New Zealand",
      "dominat_locale": "en_US",
      "abbreviation": "en_NZ",
      "locale_name": "English (New Zealand)"
    },
    {
      "lang": "English",
      "country": "Philippines",
      "dominat_locale": "en_US",
      "abbreviation": "en_PH",
      "locale_name": "English (Philippines)"
    },
    {
      "lang": "English",
      "country": "South Africa",
      "dominat_locale": "en_US",
      "abbreviation": "en_ZA",
      "locale_name": "English (South Africa)"
    },
    {
      "lang": "English",
      "country": "Trinidad and Tobago",
      "dominat_locale": "en_US",
      "abbreviation": "en_TT",
      "locale_name": "English (Trinidad and Tobago)"
    },
    {
      "lang": "English",
      "country": "U.S. Virgin Islands",
      "dominat_locale": "en_US",
      "abbreviation": "en_VI",
      "locale_name": "English (U.S. Virgin Islands)"
    },
    {
      "lang": "English",
      "country": "United Kingdom",
      "dominat_locale": "en_US",
      "abbreviation": "en_GB",
      "locale_name": "English (United Kingdom)"
    },
    {
      "lang": "English",
      "country": "United States",
      "dominat_locale": "en_US",
      "abbreviation": "en_US",
      "locale_name": "English (United States)"
    },
    {
      "lang": "English",
      "country": "Zimbabwe",
      "dominat_locale": "en_US",
      "abbreviation": "en_ZW",
      "locale_name": "English (Zimbabwe)"
    },
    {
      "lang": "Estonian",
      "country": "Estonia",
      "dominat_locale": "et_EE",
      "abbreviation": "et_EE",
      "locale_name": "Estonian (Estonia)"
    },
    {
      "lang": "Faroese",
      "country": "Faroe Islands",
      "dominat_locale": "fo_FO",
      "abbreviation": "fo_FO",
      "locale_name": "Faroese (Faroe Islands)"
    },
    {
      "lang": "Finnish",
      "country": "Finland",
      "dominat_locale": "fi_FI",
      "abbreviation": "fi_FI",
      "locale_name": "Finnish (Finland)"
    },
    {
      "lang": "French",
      "country": "Belgium",
      "dominat_locale": "fr_FR",
      "abbreviation": "fr_BE",
      "locale_name": "French (Belgium)"
    },
    {
      "lang": "French",
      "country": "Canada",
      "dominat_locale": "fr_FR",
      "abbreviation": "fr_CA",
      "locale_name": "French (Canada)"
    },
    {
      "lang": "French",
      "country": "France",
      "dominat_locale": "fr_FR",
      "abbreviation": "fr_FR",
      "locale_name": "French (France)"
    },
    {
      "lang": "French",
      "country": "Luxembourg",
      "dominat_locale": "fr_FR",
      "abbreviation": "fr_LU",
      "locale_name": "French (Luxembourg)"
    },
    {
      "lang": "French",
      "country": "Monaco",
      "dominat_locale": "fr_FR",
      "abbreviation": "fr_MC",
      "locale_name": "French (Monaco)"
    },
    {
      "lang": "French",
      "country": "Switzerland",
      "dominat_locale": "fr_FR",
      "abbreviation": "fr_CH",
      "locale_name": "French (Switzerland)"
    },
    {
      "lang": "Galician",
      "country": "Spain",
      "dominat_locale": "gl_ES",
      "abbreviation": "gl_ES",
      "locale_name": "Galician (Spain)"
    },
    {
      "lang": "Georgian",
      "country": "Georgia",
      "dominat_locale": "ka_GE",
      "abbreviation": "ka_GE",
      "locale_name": "Georgian (Georgia)"
    },
    {
      "lang": "German",
      "country": "Austria",
      "dominat_locale": "de_DE",
      "abbreviation": "de_AT",
      "locale_name": "German (Austria)"
    },
    {
      "lang": "German",
      "country": "Germany",
      "dominat_locale": "de_DE",
      "abbreviation": "de_DE",
      "locale_name": "German (Germany)"
    },
    {
      "lang": "German",
      "country": "Liechtenstein",
      "dominat_locale": "de_DE",
      "abbreviation": "de_LI",
      "locale_name": "German (Liechtenstein)"
    },
    {
      "lang": "German",
      "country": "Luxembourg",
      "dominat_locale": "de_DE",
      "abbreviation": "de_LU",
      "locale_name": "German (Luxembourg)"
    },
    {
      "lang": "German",
      "country": "Switzerland",
      "dominat_locale": "de_DE",
      "abbreviation": "de_CH",
      "locale_name": "German (Switzerland)"
    },
    {
      "lang": "Greek",
      "country": "Greece",
      "dominat_locale": "el_GR",
      "abbreviation": "el_GR",
      "locale_name": "Greek (Greece)"
    },
    {
      "lang": "Gujarati",
      "country": "India",
      "dominat_locale": "gu_IN",
      "abbreviation": "gu_IN",
      "locale_name": "Gujarati (India)"
    },
    {
      "lang": "Hebrew",
      "country": "Israel",
      "dominat_locale": "he_IL",
      "abbreviation": "he_IL",
      "locale_name": "Hebrew (Israel)"
    },
    {
      "lang": "Hindi",
      "country": "India",
      "dominat_locale": "hi_IN",
      "abbreviation": "hi_IN",
      "locale_name": "Hindi (India)"
    },
    {
      "lang": "Hungarian",
      "country": "Hungary",
      "dominat_locale": "hu_HU",
      "abbreviation": "hu_HU",
      "locale_name": "Hungarian (Hungary)"
    },
    {
      "lang": "Icelandic",
      "country": "Iceland",
      "dominat_locale": "is_IS",
      "abbreviation": "is_IS",
      "locale_name": "Icelandic (Iceland)"
    },
    {
      "lang": "Indonesian",
      "country": "Indonesia",
      "dominat_locale": "id_ID",
      "abbreviation": "id_ID",
      "locale_name": "Indonesian (Indonesia)"
    },
    {
      "lang": "Italian",
      "country": "Italy",
      "dominat_locale": "it_IT",
      "abbreviation": "it_IT",
      "locale_name": "Italian (Italy)"
    },
    {
      "lang": "Italian",
      "country": "Switzerland",
      "dominat_locale": "it_IT",
      "abbreviation": "it_CH",
      "locale_name": "Italian (Switzerland)"
    },
    {
      "lang": "Japanese",
      "country": "Japan",
      "dominat_locale": "ja_JP",
      "abbreviation": "ja_JP",
      "locale_name": "Japanese (Japan)"
    },
    {
      "lang": "Kannada",
      "country": "India",
      "dominat_locale": "kn_IN",
      "abbreviation": "kn_IN",
      "locale_name": "Kannada (India)"
    },
    {
      "lang": "Kazakh",
      "country": "Kazakhstan",
      "dominat_locale": "kk_KZ",
      "abbreviation": "kk_KZ",
      "locale_name": "Kazakh (Kazakhstan)"
    },
    {
      "lang": "Konkani",
      "country": "India",
      "dominat_locale": "kok_IN",
      "abbreviation": "kok_IN",
      "locale_name": "Konkani (India)"
    },
    {
      "lang": "Korean",
      "country": "South Korea",
      "dominat_locale": "ko_KR",
      "abbreviation": "ko_KR",
      "locale_name": "Korean (South Korea)"
    },
    {
      "lang": "Latvian",
      "country": "Latvia",
      "dominat_locale": "lv_LV",
      "abbreviation": "lv_LV",
      "locale_name": "Latvian (Latvia)"
    },
    {
      "lang": "Lithuanian",
      "country": "Lithuania",
      "dominat_locale": "lt_LT",
      "abbreviation": "lt_LT",
      "locale_name": "Lithuanian (Lithuania)"
    },
    {
      "lang": "Macedonian",
      "country": "Macedonia",
      "dominat_locale": "mk_MK",
      "abbreviation": "mk_MK",
      "locale_name": "Macedonian (Macedonia)"
    },
    {
      "lang": "Malay",
      "country": "Brunei",
      "dominat_locale": "ms_MY",
      "abbreviation": "ms_BN",
      "locale_name": "Malay (Brunei)"
    },
    {
      "lang": "Malay",
      "country": "Malaysia",
      "dominat_locale": "ms_MY",
      "abbreviation": "ms_MY",
      "locale_name": "Malay (Malaysia)"
    },
    {
      "lang": "Malayalam",
      "country": "India",
      "dominat_locale": "ml_IN",
      "abbreviation": "ml_IN",
      "locale_name": "Malayalam (India)"
    },
    {
      "lang": "Maltese",
      "country": "Malta",
      "dominat_locale": "mt_MT",
      "abbreviation": "mt_MT",
      "locale_name": "Maltese (Malta)"
    },
    {
      "lang": "Marathi",
      "country": "India",
      "dominat_locale": "mr_IN",
      "abbreviation": "mr_IN",
      "locale_name": "Marathi (India)"
    },
    {
      "lang": "Mongolian",
      "country": "Mongolia",
      "dominat_locale": "mn_MN",
      "abbreviation": "mn_MN",
      "locale_name": "Mongolian (Mongolia)"
    },
    {
      "lang": "Northern Sami",
      "country": "Norway",
      "dominat_locale": "se_NO",
      "abbreviation": "se_NO",
      "locale_name": "Northern Sami (Norway)"
    },
    {
      "lang": "Norwegian Bokmål",
      "country": "Norway",
      "dominat_locale": "nb_NO",
      "abbreviation": "nb_NO",
      "locale_name": "Norwegian Bokmål (Norway)"
    },
    {
      "lang": "Norwegian Nynorsk",
      "country": "Norway",
      "dominat_locale": "nn_NO",
      "abbreviation": "nn_NO",
      "locale_name": "Norwegian Nynorsk (Norway)"
    },
    {
      "lang": "Persian",
      "country": "Iran",
      "dominat_locale": "fa_IR",
      "abbreviation": "fa_IR",
      "locale_name": "Persian (Iran)"
    },
    {
      "lang": "Polish",
      "country": "Poland",
      "dominat_locale": "pl_PL",
      "abbreviation": "pl_PL",
      "locale_name": "Polish (Poland)"
    },
    {
      "lang": "Portuguese",
      "country": "Brazil",
      "dominat_locale": "pt_BR",
      "abbreviation": "pt_BR",
      "locale_name": "Portuguese (Brazil)"
    },
    {
      "lang": "Portuguese",
      "country": "Portugal",
      "dominat_locale": "pt_BR",
      "abbreviation": "pt_PT",
      "locale_name": "Portuguese (Portugal)"
    },
    {
      "lang": "Punjabi",
      "country": "India",
      "dominat_locale": "pa_IN",
      "abbreviation": "pa_IN",
      "locale_name": "Punjabi (India)"
    },
    {
      "lang": "Romanian",
      "country": "Romania",
      "dominat_locale": "ro_RO",
      "abbreviation": "ro_RO",
      "locale_name": "Romanian (Romania)"
    },
    {
      "lang": "Russian",
      "country": "Russia",
      "dominat_locale": "ru_RU",
      "abbreviation": "ru_RU",
      "locale_name": "Russian (Russia)"
    },
    {
      "lang": "Serbian",
      "country": "Bosnia and Herzegovina",
      "dominat_locale": "sr_BA",
      "abbreviation": "sr_BA",
      "locale_name": "Serbian (Bosnia and Herzegovina)"
    },
    {
      "lang": "Serbian",
      "country": "Serbia And Montenegro",
      "dominat_locale": "sr_BA",
      "abbreviation": "sr_CS",
      "locale_name": "Serbian (Serbia And Montenegro)"
    },
    {
      "lang": "Slovak",
      "country": "Slovakia",
      "dominat_locale": "sk_SK",
      "abbreviation": "sk_SK",
      "locale_name": "Slovak (Slovakia)"
    },
    {
      "lang": "Slovenian",
      "country": "Slovenia",
      "dominat_locale": "sk_SK",
      "abbreviation": "sl_SI",
      "locale_name": "Slovenian (Slovenia)"
    },
    {
      "lang": "Spanish",
      "country": "Argentina",
      "dominat_locale": "es_ES",
      "abbreviation": "es_AR",
      "locale_name": "Spanish (Argentina)"
    },
    {
      "lang": "Spanish",
      "country": "Bolivia",
      "dominat_locale": "es_ES",
      "abbreviation": "es_BO",
      "locale_name": "Spanish (Bolivia)"
    },
    {
      "lang": "Spanish",
      "country": "Chile",
      "dominat_locale": "es_ES",
      "abbreviation": "es_CL",
      "locale_name": "Spanish (Chile)"
    },
    {
      "lang": "Spanish",
      "country": "Colombia",
      "dominat_locale": "es_ES",
      "abbreviation": "es_CO",
      "locale_name": "Spanish (Colombia)"
    },
    {
      "lang": "Spanish",
      "country": "Costa Rica",
      "dominat_locale": "es_ES",
      "abbreviation": "es_CR",
      "locale_name": "Spanish (Costa Rica)"
    },
    {
      "lang": "Spanish",
      "country": "Dominican Republic",
      "dominat_locale": "es_ES",
      "abbreviation": "es_DO",
      "locale_name": "Spanish (Dominican Republic)"
    },
    {
      "lang": "Spanish",
      "country": "Ecuador",
      "dominat_locale": "es_ES",
      "abbreviation": "es_EC",
      "locale_name": "Spanish (Ecuador)"
    },
    {
      "lang": "Spanish",
      "country": "El Salvador",
      "dominat_locale": "es_ES",
      "abbreviation": "es_SV",
      "locale_name": "Spanish (El Salvador)"
    },
    {
      "lang": "Spanish",
      "country": "Guatemala",
      "dominat_locale": "es_ES",
      "abbreviation": "es_GT",
      "locale_name": "Spanish (Guatemala)"
    },
    {
      "lang": "Spanish",
      "country": "Honduras",
      "dominat_locale": "es_ES",
      "abbreviation": "es_HN",
      "locale_name": "Spanish (Honduras)"
    },
    {
      "lang": "Spanish",
      "country": "Mexico",
      "dominat_locale": "es_ES",
      "abbreviation": "es_MX",
      "locale_name": "Spanish (Mexico)"
    },
    {
      "lang": "Spanish",
      "country": "Nicaragua",
      "dominat_locale": "es_ES",
      "abbreviation": "es_NI",
      "locale_name": "Spanish (Nicaragua)"
    },
    {
      "lang": "Spanish",
      "country": "Panama",
      "dominat_locale": "es_ES",
      "abbreviation": "es_PA",
      "locale_name": "Spanish (Panama)"
    },
    {
      "lang": "Spanish",
      "country": "Paraguay",
      "dominat_locale": "es_ES",
      "abbreviation": "es_PY",
      "locale_name": "Spanish (Paraguay)"
    },
    {
      "lang": "Spanish",
      "country": "Peru",
      "dominat_locale": "es_ES",
      "abbreviation": "es_PE",
      "locale_name": "Spanish (Peru)"
    },
    {
      "lang": "Spanish",
      "country": "Puerto Rico",
      "dominat_locale": "es_ES",
      "abbreviation": "es_PR",
      "locale_name": "Spanish (Puerto Rico)"
    },
    {
      "lang": "Spanish",
      "country": "Spain",
      "dominat_locale": "es_ES",
      "abbreviation": "es_ES",
      "locale_name": "Spanish (Spain)"
    },
    {
      "lang": "Spanish",
      "country": "Uruguay",
      "dominat_locale": "es_ES",
      "abbreviation": "es_UY",
      "locale_name": "Spanish (Uruguay)"
    },
    {
      "lang": "Spanish",
      "country": "Venezuela",
      "dominat_locale": "es_ES",
      "abbreviation": "es_VE",
      "locale_name": "Spanish (Venezuela)"
    },
    {
      "lang": "Swahili",
      "country": "Kenya",
      "dominat_locale": "sw_KE",
      "abbreviation": "sw_KE",
      "locale_name": "Swahili (Kenya)"
    },
    {
      "lang": "Swedish",
      "country": "Finland",
      "dominat_locale": "sv_SE",
      "abbreviation": "sv_FI",
      "locale_name": "Swedish (Finland)"
    },
    {
      "lang": "Swedish",
      "country": "Sweden",
      "dominat_locale": "sv_SE",
      "abbreviation": "sv_SE",
      "locale_name": "Swedish (Sweden)"
    },
    {
      "lang": "Syriac",
      "country": "Syria",
      "dominat_locale": "syr_SY",
      "abbreviation": "syr_SY",
      "locale_name": "Syriac (Syria)"
    },
    {
      "lang": "Tamil",
      "country": "India",
      "dominat_locale": "ta_IN",
      "abbreviation": "ta_IN",
      "locale_name": "Tamil (India)"
    },
    {
      "lang": "Telugu",
      "country": "India",
      "dominat_locale": "te_IN",
      "abbreviation": "te_IN",
      "locale_name": "Telugu (India)"
    },
    {
      "lang": "Thai",
      "country": "Thailand",
      "dominat_locale": "th_TH",
      "abbreviation": "th_TH",
      "locale_name": "Thai (Thailand)"
    },
    {
      "lang": "Tswana",
      "country": "South Africa",
      "dominat_locale": "tn_ZA",
      "abbreviation": "tn_ZA",
      "locale_name": "Tswana (South Africa)"
    },
    {
      "lang": "Turkish",
      "country": "Turkey",
      "dominat_locale": "tr_TR",
      "abbreviation": "tr_TR",
      "locale_name": "Turkish (Turkey)"
    },
    {
      "lang": "Ukrainian",
      "country": "Ukraine",
      "dominat_locale": "uk_UA",
      "abbreviation": "uk_UA",
      "locale_name": "Ukrainian (Ukraine)"
    },
    {
      "lang": "Uzbek",
      "country": "Uzbekistan",
      "dominat_locale": "uz_UZ",
      "abbreviation": "uz_UZ",
      "locale_name": "Uzbek (Uzbekistan)"
    },
    {
      "lang": "Vietnamese",
      "country": "Vietnam",
      "dominat_locale": "vi_VN",
      "abbreviation": "vi_VN",
      "locale_name": "Vietnamese (Vietnam)"
    },
    {
      "lang": "Welsh",
      "country": "United Kingdom",
      "dominat_locale": "cy_GB",
      "abbreviation": "cy_GB",
      "locale_name": "Welsh (United Kingdom)"
    },
    {
      "lang": "Xhosa",
      "country": "South Africa",
      "dominat_locale": "xh_ZA",
      "abbreviation": "xh_ZA",
      "locale_name": "Xhosa (South Africa)"
    },
    {
      "lang": "Zulu",
      "country": "South Africa",
      "dominat_locale": "zu_ZA",
      "abbreviation": "zu_ZA",
      "locale_name": "Zulu (South Africa)"
    }
];  
export const globalLangList = [
    {
        "Locale": "Afrikaans",
        "Languagecode": "af",
        "LCIDstring": "af",
        "LCIDDecimal": "1078",
        "LCIDHexadecimal": "436",
        "Codepage": "1252"
    },
    {
        "Locale": "Albanian",
        "Languagecode": "sq",
        "LCIDstring": "sq",
        "LCIDDecimal": "1052",
        "LCIDHexadecimal": "1250"
    },
    {
        "Locale": "Amharic",
        "Languagecode": "am",
        "LCIDstring": "am",
        "LCIDDecimal": "1118",
        "LCIDHexadecimal": "Arabic - Algeria",
        "Codepage": "ar"
    },
    {
        "Locale": "Arabic - Bahrain",
        "Languagecode": "ar",
        "LCIDstring": "ar-bh",
        "LCIDDecimal": "15361",
        "LCIDHexadecimal": "1256"
    },
    {
        "Locale": "Arabic - Egypt",
        "Languagecode": "ar",
        "LCIDstring": "ar-eg",
        "LCIDDecimal": "3073",
        "LCIDHexadecimal": "1256"
    },
    {
        "Locale": "Arabic - Iraq",
        "Languagecode": "ar",
        "LCIDstring": "ar-iq",
        "LCIDDecimal": "2049",
        "LCIDHexadecimal": "801",
        "Codepage": "1256"
    },
    {
        "Locale": "Arabic - Jordan",
        "Languagecode": "ar",
        "LCIDstring": "ar-jo",
        "LCIDDecimal": "11265",
        "LCIDHexadecimal": "1256"
    },
    {
        "Locale": "Arabic - Kuwait",
        "Languagecode": "ar",
        "LCIDstring": "ar-kw",
        "LCIDDecimal": "13313",
        "LCIDHexadecimal": "3201",
        "Codepage": "1256"
    },
    {
        "Locale": "Arabic - Lebanon",
        "Languagecode": "ar",
        "LCIDstring": "ar-lb",
        "LCIDDecimal": "12289",
        "LCIDHexadecimal": "3001",
        "Codepage": "1256"
    },
    {
        "Locale": "Arabic - Libya",
        "Languagecode": "ar",
        "LCIDstring": "ar-ly",
        "LCIDDecimal": "4097",
        "LCIDHexadecimal": "1001",
        "Codepage": "1256"
    },
    {
        "Locale": "Arabic - Morocco",
        "Languagecode": "ar",
        "LCIDstring": "ar-ma",
        "LCIDDecimal": "6145",
        "LCIDHexadecimal": "1801",
        "Codepage": "1256"
    },
    {
        "Locale": "Arabic - Oman",
        "Languagecode": "ar",
        "LCIDstring": "ar-om",
        "LCIDDecimal": "8193",
        "LCIDHexadecimal": "2001",
        "Codepage": "1256"
    },
    {
        "Locale": "Arabic - Qatar",
        "Languagecode": "ar",
        "LCIDstring": "ar-qa",
        "LCIDDecimal": "16385",
        "LCIDHexadecimal": "4001",
        "Codepage": "1256"
    },
    {
        "Locale": "Arabic - Saudi Arabia",
        "Languagecode": "ar",
        "LCIDstring": "ar-sa",
        "LCIDDecimal": "1025",
        "LCIDHexadecimal": "401",
        "Codepage": "1256"
    },
    {
        "Locale": "Arabic - Syria",
        "Languagecode": "ar",
        "LCIDstring": "ar-sy",
        "LCIDDecimal": "10241",
        "LCIDHexadecimal": "2801",
        "Codepage": "1256"
    },
    {
        "Locale": "Arabic - Tunisia",
        "Languagecode": "ar",
        "LCIDstring": "ar-tn",
        "LCIDDecimal": "7169",
        "LCIDHexadecimal": "1256"
    },
    {
        "Locale": "Arabic - United Arab Emirates",
        "Languagecode": "ar",
        "LCIDstring": "ar-ae",
        "LCIDDecimal": "14337",
        "LCIDHexadecimal": "3801",
        "Codepage": "1256"
    },
    {
        "Locale": "Arabic - Yemen",
        "Languagecode": "ar",
        "LCIDstring": "ar-ye",
        "LCIDDecimal": "9217",
        "LCIDHexadecimal": "2401",
        "Codepage": "1256"
    },
    {
        "Locale": "Armenian",
        "Languagecode": "hy",
        "LCIDstring": "hy",
        "LCIDDecimal": "1067",
        "LCIDHexadecimal": "Assamese",
        "Codepage": "as"
    },
    {
        "Locale": "Azeri - Latin",
        "Languagecode": "az",
        "LCIDstring": "az-az",
        "LCIDDecimal": "1068",
        "LCIDHexadecimal": "1254"
    },
    {
        "Locale": "Basque",
        "Languagecode": "eu",
        "LCIDstring": "eu",
        "LCIDDecimal": "1069",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "Belarusian",
        "Languagecode": "be",
        "LCIDstring": "be",
        "LCIDDecimal": "1059",
        "LCIDHexadecimal": "423",
        "Codepage": "1251"
    },
    {
        "Locale": "Bengali - Bangladesh",
        "Languagecode": "bn",
        "LCIDstring": "bn",
        "LCIDDecimal": "2117",
        "LCIDHexadecimal": "845",
        "Codepage": "Bengali - India"
    },
    {
        "Locale": "Burmese",
        "Languagecode": "my",
        "LCIDstring": "my",
        "LCIDDecimal": "1109",
        "LCIDHexadecimal": "455",
        "Codepage": "Catalan"
    },
    {
        "Locale": "Chinese - China",
        "Languagecode": "zh",
        "LCIDstring": "zh-cn",
        "LCIDDecimal": "2052",
        "LCIDHexadecimal": "804",
        "Codepage": "Chinese - Hong Kong SAR"
    },
    {
        "Locale": "Czech",
        "Languagecode": "cs",
        "LCIDstring": "cs",
        "LCIDDecimal": "1029",
        "LCIDHexadecimal": "405",
        "Codepage": "1250"
    },
    {
        "Locale": "Danish",
        "Languagecode": "da",
        "LCIDstring": "da",
        "LCIDDecimal": "1030",
        "LCIDHexadecimal": "406",
        "Codepage": "1252"
    },
    {
        "Locale": "Divehi",
        "Languagecode": "Dhivehi",
        "LCIDstring": "Maldivian",
        "LCIDDecimal": "dv",
        "LCIDHexadecimal": "dv",
        "Codepage": "Dutch - Belgium"
    },
    {
        "Locale": "Dutch - Netherlands",
        "Languagecode": "nl",
        "LCIDstring": "nl-nl",
        "LCIDDecimal": "1043",
        "LCIDHexadecimal": "413",
        "Codepage": "1252"
    },
    {
        "Locale": "Edo",
        "Languagecode": "1126",
        "LCIDstring": "466",
        "LCIDDecimal": "English - Australia",
        "LCIDHexadecimal": "en",
        "Codepage": "en-au"
    },
    {
        "Locale": "English - Belize",
        "Languagecode": "en",
        "LCIDstring": "en-bz",
        "LCIDDecimal": "10249",
        "LCIDHexadecimal": "2809",
        "Codepage": "1252"
    },
    {
        "Locale": "English - Canada",
        "Languagecode": "en",
        "LCIDstring": "en-ca",
        "LCIDDecimal": "4105",
        "LCIDHexadecimal": "1009",
        "Codepage": "1252"
    },
    {
        "Locale": "English - Caribbean",
        "Languagecode": "en",
        "LCIDstring": "en-cb",
        "LCIDDecimal": "9225",
        "LCIDHexadecimal": "2409",
        "Codepage": "1252"
    },
    {
        "Locale": "English - Great Britain",
        "Languagecode": "en",
        "LCIDstring": "en-gb",
        "LCIDDecimal": "2057",
        "LCIDHexadecimal": "809",
        "Codepage": "1252"
    },
    {
        "Locale": "English - India",
        "Languagecode": "en",
        "LCIDstring": "en-in",
        "LCIDDecimal": "16393",
        "LCIDHexadecimal": "4009",
        "Codepage": "English - Ireland"
    },
    {
        "Locale": "English - Jamaica",
        "Languagecode": "en",
        "LCIDstring": "en-jm",
        "LCIDDecimal": "8201",
        "LCIDHexadecimal": "2009",
        "Codepage": "1252"
    },
    {
        "Locale": "English - New Zealand",
        "Languagecode": "en",
        "LCIDstring": "en-nz",
        "LCIDDecimal": "5129",
        "LCIDHexadecimal": "1409",
        "Codepage": "1252"
    },
    {
        "Locale": "English - Philippines",
        "Languagecode": "en",
        "LCIDstring": "en-ph",
        "LCIDDecimal": "13321",
        "LCIDHexadecimal": "3209",
        "Codepage": "1252"
    },
    {
        "Locale": "English - Southern Africa",
        "Languagecode": "en",
        "LCIDstring": "en-za",
        "LCIDDecimal": "7177",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "English - Trinidad",
        "Languagecode": "en",
        "LCIDstring": "en-tt",
        "LCIDDecimal": "11273",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "English - United States",
        "Languagecode": "en",
        "LCIDstring": "en-us",
        "LCIDDecimal": "1033",
        "LCIDHexadecimal": "409",
        "Codepage": "1252"
    },
    {
        "Locale": "English - Zimbabwe",
        "Languagecode": "en",
        "LCIDstring": "12297",
        "LCIDDecimal": "3009",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "Estonian",
        "Languagecode": "et",
        "LCIDstring": "et",
        "LCIDDecimal": "1061",
        "LCIDHexadecimal": "425",
        "Codepage": "1257"
    },
    {
        "Locale": "FYRO Macedonia",
        "Languagecode": "mk",
        "LCIDstring": "mk",
        "LCIDDecimal": "1071",
        "LCIDHexadecimal": "1251"
    },
    {
        "Locale": "Faroese",
        "Languagecode": "fo",
        "LCIDstring": "fo",
        "LCIDDecimal": "1080",
        "LCIDHexadecimal": "438",
        "Codepage": "1252"
    },
    {
        "Locale": "Farsi - Persian",
        "Languagecode": "fa",
        "LCIDstring": "fa",
        "LCIDDecimal": "1065",
        "LCIDHexadecimal": "429",
        "Codepage": "1256"
    },
    {
        "Locale": "Filipino",
        "Languagecode": "1124",
        "LCIDstring": "464",
        "LCIDDecimal": "Finnish",
        "LCIDHexadecimal": "fi",
        "Codepage": "fi"
    },
    {
        "Locale": "French - Belgium",
        "Languagecode": "fr",
        "LCIDstring": "fr-be",
        "LCIDDecimal": "2060",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "French - Cameroon",
        "Languagecode": "fr",
        "LCIDstring": "11276",
        "LCIDDecimal": "French - Canada",
        "LCIDHexadecimal": "fr",
        "Codepage": "fr-ca"
    },
    {
        "Locale": "French - Congo",
        "Languagecode": "fr",
        "LCIDstring": "9228",
        "LCIDDecimal": "French - Cote d'Ivoire",
        "LCIDHexadecimal": "fr",
        "Codepage": "12300"
    },
    {
        "Locale": "French - Luxembourg",
        "Languagecode": "fr",
        "LCIDstring": "fr-lu",
        "LCIDDecimal": "5132",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "French - Mali",
        "Languagecode": "fr",
        "LCIDstring": "13324",
        "LCIDDecimal": "French - Monaco",
        "LCIDHexadecimal": "fr",
        "Codepage": "6156"
    },
    {
        "Locale": "French - Morocco",
        "Languagecode": "fr",
        "LCIDstring": "14348",
        "LCIDDecimal": "French - Senegal",
        "LCIDHexadecimal": "fr",
        "Codepage": "10252"
    },
    {
        "Locale": "French - West Indies",
        "Languagecode": "fr",
        "LCIDstring": "7180",
        "LCIDDecimal": "Frisian - Netherlands",
        "LCIDHexadecimal": "1122",
        "Codepage": "462"
    },
    {
        "Locale": "Georgian",
        "Languagecode": "ka",
        "LCIDstring": "1079",
        "LCIDDecimal": "437",
        "LCIDHexadecimal": "German - Austria",
        "Codepage": "de"
    },
    {
        "Locale": "German - Germany",
        "Languagecode": "de",
        "LCIDstring": "de-de",
        "LCIDDecimal": "1031",
        "LCIDHexadecimal": "407",
        "Codepage": "1252"
    },
    {
        "Locale": "German - Liechtenstein",
        "Languagecode": "de",
        "LCIDstring": "de-li",
        "LCIDDecimal": "5127",
        "LCIDHexadecimal": "1407",
        "Codepage": "1252"
    },
    {
        "Locale": "German - Luxembourg",
        "Languagecode": "de",
        "LCIDstring": "de-lu",
        "LCIDDecimal": "4103",
        "LCIDHexadecimal": "1007",
        "Codepage": "1252"
    },
    {
        "Locale": "German - Switzerland",
        "Languagecode": "de",
        "LCIDstring": "de-ch",
        "LCIDDecimal": "2055",
        "LCIDHexadecimal": "807",
        "Codepage": "1252"
    },
    {
        "Locale": "Greek",
        "Languagecode": "el",
        "LCIDstring": "el",
        "LCIDDecimal": "1032",
        "LCIDHexadecimal": "408",
        "Codepage": "1253"
    },
    {
        "Locale": "Guarani - Paraguay",
        "Languagecode": "gn",
        "LCIDstring": "gn",
        "LCIDDecimal": "1140",
        "LCIDHexadecimal": "474",
        "Codepage": "Gujarati"
    },
    {
        "Locale": "Hindi",
        "Languagecode": "hi",
        "LCIDstring": "hi",
        "LCIDDecimal": "1081",
        "LCIDHexadecimal": "439",
        "Codepage": "Hungarian"
    },
    {
        "Locale": "Icelandic",
        "Languagecode": "is",
        "LCIDstring": "is",
        "LCIDDecimal": "1039",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "Igbo - Nigeria",
        "Languagecode": "1136",
        "LCIDstring": "470",
        "LCIDDecimal": "Indonesian",
        "LCIDHexadecimal": "id",
        "Codepage": "id"
    },
    {
        "Locale": "Italian - Italy",
        "Languagecode": "it",
        "LCIDstring": "it-it",
        "LCIDDecimal": "1040",
        "LCIDHexadecimal": "410",
        "Codepage": "1252"
    },
    {
        "Locale": "Italian - Switzerland",
        "Languagecode": "it",
        "LCIDstring": "it-ch",
        "LCIDDecimal": "2064",
        "LCIDHexadecimal": "810",
        "Codepage": "1252"
    },
    {
        "Locale": "Japanese",
        "Languagecode": "ja",
        "LCIDstring": "ja",
        "LCIDDecimal": "1041",
        "LCIDHexadecimal": "411",
        "Codepage": "Kannada"
    },
    {
        "Locale": "Khmer",
        "Languagecode": "km",
        "LCIDstring": "km",
        "LCIDDecimal": "1107",
        "LCIDHexadecimal": "453",
        "Codepage": "Konkani"
    },
    {
        "Locale": "Lao",
        "Languagecode": "lo",
        "LCIDstring": "lo",
        "LCIDDecimal": "1108",
        "LCIDHexadecimal": "454",
        "Codepage": "Latin"
    },
    {
        "Locale": "Lithuanian",
        "Languagecode": "lt",
        "LCIDstring": "lt",
        "LCIDDecimal": "1063",
        "LCIDHexadecimal": "427",
        "Codepage": "1257"
    },
    {
        "Locale": "Malay - Brunei",
        "Languagecode": "ms",
        "LCIDstring": "ms-bn",
        "LCIDDecimal": "2110",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "Malay - Malaysia",
        "Languagecode": "ms",
        "LCIDstring": "ms-my",
        "LCIDDecimal": "1086",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "Malayalam",
        "Languagecode": "ml",
        "LCIDstring": "ml",
        "LCIDDecimal": "1100",
        "LCIDHexadecimal": "Maltese",
        "Codepage": "mt"
    },
    {
        "Locale": "Nepali",
        "Languagecode": "ne",
        "LCIDstring": "ne",
        "LCIDDecimal": "1121",
        "LCIDHexadecimal": "461",
        "Codepage": "Norwegian - Bokml"
    },
    {
        "Locale": "Norwegian - Nynorsk",
        "Languagecode": "nn",
        "LCIDstring": "no-no",
        "LCIDDecimal": "2068",
        "LCIDHexadecimal": "814",
        "Codepage": "1252"
    },
    {
        "Locale": "Oriya",
        "Languagecode": "or",
        "LCIDstring": "or",
        "LCIDDecimal": "1096",
        "LCIDHexadecimal": "448",
        "Codepage": "Polish"
    },
    {
        "Locale": "Portuguese - Brazil",
        "Languagecode": "pt",
        "LCIDstring": "pt-br",
        "LCIDDecimal": "1046",
        "LCIDHexadecimal": "416",
        "Codepage": "1252"
    },
    {
        "Locale": "Portuguese - Portugal",
        "Languagecode": "pt",
        "LCIDstring": "pt-pt",
        "LCIDDecimal": "2070",
        "LCIDHexadecimal": "816",
        "Codepage": "1252"
    },
    {
        "Locale": "Punjabi",
        "Languagecode": "pa",
        "LCIDstring": "pa",
        "LCIDDecimal": "1094",
        "LCIDHexadecimal": "446",
        "Codepage": "Raeto-Romance"
    },
    {
        "Locale": "Russian",
        "Languagecode": "ru",
        "LCIDstring": "ru",
        "LCIDDecimal": "1049",
        "LCIDHexadecimal": "419",
        "Codepage": "1251"
    },
    {
        "Locale": "Russian - Moldova",
        "Languagecode": "ru",
        "LCIDstring": "ru-mo",
        "LCIDDecimal": "2073",
        "LCIDHexadecimal": "819",
        "Codepage": "Sami Lappish"
    },
    {
        "Locale": "Serbian - Latin",
        "Languagecode": "sr",
        "LCIDstring": "sr-sp",
        "LCIDDecimal": "2074",
        "LCIDHexadecimal": "1250"
    },
    {
        "Locale": "Sesotho (Sutu)",
        "Languagecode": "1072",
        "LCIDstring": "430",
        "LCIDDecimal": "Setsuana",
        "LCIDHexadecimal": "tn",
        "Codepage": "tn"
    },
    {
        "Locale": "Slovenian",
        "Languagecode": "sl",
        "LCIDstring": "sl",
        "LCIDDecimal": "1060",
        "LCIDHexadecimal": "424",
        "Codepage": "1250"
    },
    {
        "Locale": "Somali",
        "Languagecode": "so",
        "LCIDstring": "so",
        "LCIDDecimal": "1143",
        "LCIDHexadecimal": "477",
        "Codepage": "Sorbian"
    },
    {
        "Locale": "Spanish - Bolivia",
        "Languagecode": "es",
        "LCIDstring": "es-bo",
        "LCIDDecimal": "16394",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "Spanish - Chile",
        "Languagecode": "es",
        "LCIDstring": "es-cl",
        "LCIDDecimal": "13322",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "Spanish - Colombia",
        "Languagecode": "es",
        "LCIDstring": "es-co",
        "LCIDDecimal": "9226",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "Spanish - Costa Rica",
        "Languagecode": "es",
        "LCIDstring": "es-cr",
        "LCIDDecimal": "5130",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "Spanish - Dominican Republic",
        "Languagecode": "es",
        "LCIDstring": "es-do",
        "LCIDDecimal": "7178",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "Spanish - Ecuador",
        "Languagecode": "es",
        "LCIDstring": "es-ec",
        "LCIDDecimal": "12298",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "Spanish - El Salvador",
        "Languagecode": "es",
        "LCIDstring": "es-sv",
        "LCIDDecimal": "17418",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "Spanish - Guatemala",
        "Languagecode": "es",
        "LCIDstring": "es-gt",
        "LCIDDecimal": "4106",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "Spanish - Honduras",
        "Languagecode": "es",
        "LCIDstring": "es-hn",
        "LCIDDecimal": "18442",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "Spanish - Mexico",
        "Languagecode": "es",
        "LCIDstring": "es-mx",
        "LCIDDecimal": "2058",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "Spanish - Nicaragua",
        "Languagecode": "es",
        "LCIDstring": "es-ni",
        "LCIDDecimal": "19466",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "Spanish - Panama",
        "Languagecode": "es",
        "LCIDstring": "es-pa",
        "LCIDDecimal": "6154",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "Spanish - Paraguay",
        "Languagecode": "es",
        "LCIDstring": "es-py",
        "LCIDDecimal": "15370",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "Spanish - Peru",
        "Languagecode": "es",
        "LCIDstring": "es-pe",
        "LCIDDecimal": "10250",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "Spanish - Puerto Rico",
        "Languagecode": "es",
        "LCIDstring": "es-pr",
        "LCIDDecimal": "20490",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "Spanish - Spain (Traditional)",
        "Languagecode": "es",
        "LCIDstring": "es-es",
        "LCIDDecimal": "1034",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "Spanish - Uruguay",
        "Languagecode": "es",
        "LCIDstring": "es-uy",
        "LCIDDecimal": "14346",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "Spanish - Venezuela",
        "Languagecode": "es",
        "LCIDstring": "es-ve",
        "LCIDDecimal": "8202",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "Swahili",
        "Languagecode": "sw",
        "LCIDstring": "sw",
        "LCIDDecimal": "1089",
        "LCIDHexadecimal": "441",
        "Codepage": "1252"
    },
    {
        "Locale": "Swedish - Finland",
        "Languagecode": "sv",
        "LCIDstring": "sv-fi",
        "LCIDDecimal": "2077",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "Swedish - Sweden",
        "Languagecode": "sv",
        "LCIDstring": "sv-se",
        "LCIDDecimal": "1053",
        "LCIDHexadecimal": "1252"
    },
    {
        "Locale": "Syriac",
        "Languagecode": "1114",
        "LCIDstring": "Tajik",
        "LCIDDecimal": "tg",
        "LCIDHexadecimal": "tg",
        "Codepage": "1064"
    },
    {
        "Locale": "Telugu",
        "Languagecode": "te",
        "LCIDstring": "te",
        "LCIDDecimal": "1098",
        "LCIDHexadecimal": "Thai",
        "Codepage": "th"
    },
    {
        "Locale": "Turkmen",
        "Languagecode": "tk",
        "LCIDstring": "tk",
        "LCIDDecimal": "1090",
        "LCIDHexadecimal": "442",
        "Codepage": "Ukrainian"
    },
    {
        "Locale": "Unicode",
        "Languagecode": "UTF-8",
        "LCIDstring": "0",
        "LCIDDecimal": "Urdu",
        "LCIDHexadecimal": "ur",
        "Codepage": "ur"
    },
    {
        "Locale": "Uzbek - Cyrillic",
        "Languagecode": "uz",
        "LCIDstring": "uz-uz",
        "LCIDDecimal": "2115",
        "LCIDHexadecimal": "843",
        "Codepage": "1251"
    },
    {
        "Locale": "Uzbek - Latin",
        "Languagecode": "uz",
        "LCIDstring": "uz-uz",
        "LCIDDecimal": "1091",
        "LCIDHexadecimal": "443",
        "Codepage": "1254"
    },
    {
        "Locale": "Venda",
        "Languagecode": "1075",
        "LCIDstring": "433",
        "LCIDDecimal": "Vietnamese",
        "LCIDHexadecimal": "vi",
        "Codepage": "vi"
    }
]

export const countries = [
  { code: 'AD', label: 'Andorra', phone: '376' },
  {
    code: 'AE',
    label: 'United Arab Emirates',
    phone: '971',
  },
  { code: 'AF', label: 'Afghanistan', phone: '93' },
  {
    code: 'AG',
    label: 'Antigua and Barbuda',
    phone: '1-268',
  },
  { code: 'AI', label: 'Anguilla', phone: '1-264' },
  { code: 'AL', label: 'Albania', phone: '355' },
  { code: 'AM', label: 'Armenia', phone: '374' },
  { code: 'AO', label: 'Angola', phone: '244' },
  { code: 'AQ', label: 'Antarctica', phone: '672' },
  { code: 'AR', label: 'Argentina', phone: '54' },
  { code: 'AS', label: 'American Samoa', phone: '1-684' },
  { code: 'AT', label: 'Austria', phone: '43' },
  {
    code: 'AU',
    label: 'Australia',
    phone: '61',
    suggested: true,
  },
  { code: 'AW', label: 'Aruba', phone: '297' },
  { code: 'AX', label: 'Alland Islands', phone: '358' },
  { code: 'AZ', label: 'Azerbaijan', phone: '994' },
  {
    code: 'BA',
    label: 'Bosnia and Herzegovina',
    phone: '387',
  },
  { code: 'BB', label: 'Barbados', phone: '1-246' },
  { code: 'BD', label: 'Bangladesh', phone: '880' },
  { code: 'BE', label: 'Belgium', phone: '32' },
  { code: 'BF', label: 'Burkina Faso', phone: '226' },
  { code: 'BG', label: 'Bulgaria', phone: '359' },
  { code: 'BH', label: 'Bahrain', phone: '973' },
  { code: 'BI', label: 'Burundi', phone: '257' },
  { code: 'BJ', label: 'Benin', phone: '229' },
  { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
  { code: 'BM', label: 'Bermuda', phone: '1-441' },
  { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
  { code: 'BO', label: 'Bolivia', phone: '591' },
  { code: 'BR', label: 'Brazil', phone: '55' },
  { code: 'BS', label: 'Bahamas', phone: '1-242' },
  { code: 'BT', label: 'Bhutan', phone: '975' },
  { code: 'BV', label: 'Bouvet Island', phone: '47' },
  { code: 'BW', label: 'Botswana', phone: '267' },
  { code: 'BY', label: 'Belarus', phone: '375' },
  { code: 'BZ', label: 'Belize', phone: '501' },
  {
    code: 'CA',
    label: 'Canada',
    phone: '1',
    suggested: true,
  },
  {
    code: 'CC',
    label: 'Cocos (Keeling) Islands',
    phone: '61',
  },
  {
    code: 'CD',
    label: 'Congo, Democratic Republic of the',
    phone: '243',
  },
  {
    code: 'CF',
    label: 'Central African Republic',
    phone: '236',
  },
  {
    code: 'CG',
    label: 'Congo, Republic of the',
    phone: '242',
  },
  { code: 'CH', label: 'Switzerland', phone: '41' },
  { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
  { code: 'CK', label: 'Cook Islands', phone: '682' },
  { code: 'CL', label: 'Chile', phone: '56' },
  { code: 'CM', label: 'Cameroon', phone: '237' },
  { code: 'CN', label: 'China', phone: '86' },
  { code: 'CO', label: 'Colombia', phone: '57' },
  { code: 'CR', label: 'Costa Rica', phone: '506' },
  { code: 'CU', label: 'Cuba', phone: '53' },
  { code: 'CV', label: 'Cape Verde', phone: '238' },
  { code: 'CW', label: 'Curacao', phone: '599' },
  { code: 'CX', label: 'Christmas Island', phone: '61' },
  { code: 'CY', label: 'Cyprus', phone: '357' },
  { code: 'CZ', label: 'Czech Republic', phone: '420' },
  {
    code: 'DE',
    label: 'Germany',
    phone: '49',
    suggested: true,
  },
  { code: 'DJ', label: 'Djibouti', phone: '253' },
  { code: 'DK', label: 'Denmark', phone: '45' },
  { code: 'DM', label: 'Dominica', phone: '1-767' },
  {
    code: 'DO',
    label: 'Dominican Republic',
    phone: '1-809',
  },
  { code: 'DZ', label: 'Algeria', phone: '213' },
  { code: 'EC', label: 'Ecuador', phone: '593' },
  { code: 'EE', label: 'Estonia', phone: '372' },
  { code: 'EG', label: 'Egypt', phone: '20' },
  { code: 'EH', label: 'Western Sahara', phone: '212' },
  { code: 'ER', label: 'Eritrea', phone: '291' },
  { code: 'ES', label: 'Spain', phone: '34' },
  { code: 'ET', label: 'Ethiopia', phone: '251' },
  { code: 'FI', label: 'Finland', phone: '358' },
  { code: 'FJ', label: 'Fiji', phone: '679' },
  {
    code: 'FK',
    label: 'Falkland Islands (Malvinas)',
    phone: '500',
  },
  {
    code: 'FM',
    label: 'Micronesia, Federated States of',
    phone: '691',
  },
  { code: 'FO', label: 'Faroe Islands', phone: '298' },
  {
    code: 'FR',
    label: 'France',
    phone: '33',
    suggested: true,
  },
  { code: 'GA', label: 'Gabon', phone: '241' },
  { code: 'GB', label: 'United Kingdom', phone: '44' },
  { code: 'GD', label: 'Grenada', phone: '1-473' },
  { code: 'GE', label: 'Georgia', phone: '995' },
  { code: 'GF', label: 'French Guiana', phone: '594' },
  { code: 'GG', label: 'Guernsey', phone: '44' },
  { code: 'GH', label: 'Ghana', phone: '233' },
  { code: 'GI', label: 'Gibraltar', phone: '350' },
  { code: 'GL', label: 'Greenland', phone: '299' },
  { code: 'GM', label: 'Gambia', phone: '220' },
  { code: 'GN', label: 'Guinea', phone: '224' },
  { code: 'GP', label: 'Guadeloupe', phone: '590' },
  { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
  { code: 'GR', label: 'Greece', phone: '30' },
  {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    phone: '500',
  },
  { code: 'GT', label: 'Guatemala', phone: '502' },
  { code: 'GU', label: 'Guam', phone: '1-671' },
  { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
  { code: 'GY', label: 'Guyana', phone: '592' },
  { code: 'HK', label: 'Hong Kong', phone: '852' },
  {
    code: 'HM',
    label: 'Heard Island and McDonald Islands',
    phone: '672',
  },
  { code: 'HN', label: 'Honduras', phone: '504' },
  { code: 'HR', label: 'Croatia', phone: '385' },
  { code: 'HT', label: 'Haiti', phone: '509' },
  { code: 'HU', label: 'Hungary', phone: '36' },
  { code: 'ID', label: 'Indonesia', phone: '62' },
  { code: 'IE', label: 'Ireland', phone: '353' },
  { code: 'IL', label: 'Israel', phone: '972' },
  { code: 'IM', label: 'Isle of Man', phone: '44' },
  { code: 'IN', label: 'India', phone: '91' },
  {
    code: 'IO',
    label: 'British Indian Ocean Territory',
    phone: '246',
  },
  { code: 'IQ', label: 'Iraq', phone: '964' },
  {
    code: 'IR',
    label: 'Iran, Islamic Republic of',
    phone: '98',
  },
  { code: 'IS', label: 'Iceland', phone: '354' },
  { code: 'IT', label: 'Italy', phone: '39' },
  { code: 'JE', label: 'Jersey', phone: '44' },
  { code: 'JM', label: 'Jamaica', phone: '1-876' },
  { code: 'JO', label: 'Jordan', phone: '962' },
  {
    code: 'JP',
    label: 'Japan',
    phone: '81',
    suggested: true,
  },
  { code: 'KE', label: 'Kenya', phone: '254' },
  { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
  { code: 'KH', label: 'Cambodia', phone: '855' },
  { code: 'KI', label: 'Kiribati', phone: '686' },
  { code: 'KM', label: 'Comoros', phone: '269' },
  {
    code: 'KN',
    label: 'Saint Kitts and Nevis',
    phone: '1-869',
  },
  {
    code: 'KP',
    label: "Korea, Democratic People's Republic of",
    phone: '850',
  },
  { code: 'KR', label: 'Korea, Republic of', phone: '82' },
  { code: 'KW', label: 'Kuwait', phone: '965' },
  { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
  { code: 'KZ', label: 'Kazakhstan', phone: '7' },
  {
    code: 'LA',
    label: "Lao People's Democratic Republic",
    phone: '856',
  },
  { code: 'LB', label: 'Lebanon', phone: '961' },
  { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
  { code: 'LI', label: 'Liechtenstein', phone: '423' },
  { code: 'LK', label: 'Sri Lanka', phone: '94' },
  { code: 'LR', label: 'Liberia', phone: '231' },
  { code: 'LS', label: 'Lesotho', phone: '266' },
  { code: 'LT', label: 'Lithuania', phone: '370' },
  { code: 'LU', label: 'Luxembourg', phone: '352' },
  { code: 'LV', label: 'Latvia', phone: '371' },
  { code: 'LY', label: 'Libya', phone: '218' },
  { code: 'MA', label: 'Morocco', phone: '212' },
  { code: 'MC', label: 'Monaco', phone: '377' },
  {
    code: 'MD',
    label: 'Moldova, Republic of',
    phone: '373',
  },
  { code: 'ME', label: 'Montenegro', phone: '382' },
  {
    code: 'MF',
    label: 'Saint Martin (French part)',
    phone: '590',
  },
  { code: 'MG', label: 'Madagascar', phone: '261' },
  { code: 'MH', label: 'Marshall Islands', phone: '692' },
  {
    code: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
    phone: '389',
  },
  { code: 'ML', label: 'Mali', phone: '223' },
  { code: 'MM', label: 'Myanmar', phone: '95' },
  { code: 'MN', label: 'Mongolia', phone: '976' },
  { code: 'MO', label: 'Macao', phone: '853' },
  {
    code: 'MP',
    label: 'Northern Mariana Islands',
    phone: '1-670',
  },
  { code: 'MQ', label: 'Martinique', phone: '596' },
  { code: 'MR', label: 'Mauritania', phone: '222' },
  { code: 'MS', label: 'Montserrat', phone: '1-664' },
  { code: 'MT', label: 'Malta', phone: '356' },
  { code: 'MU', label: 'Mauritius', phone: '230' },
  { code: 'MV', label: 'Maldives', phone: '960' },
  { code: 'MW', label: 'Malawi', phone: '265' },
  { code: 'MX', label: 'Mexico', phone: '52' },
  { code: 'MY', label: 'Malaysia', phone: '60' },
  { code: 'MZ', label: 'Mozambique', phone: '258' },
  { code: 'NA', label: 'Namibia', phone: '264' },
  { code: 'NC', label: 'New Caledonia', phone: '687' },
  { code: 'NE', label: 'Niger', phone: '227' },
  { code: 'NF', label: 'Norfolk Island', phone: '672' },
  { code: 'NG', label: 'Nigeria', phone: '234' },
  { code: 'NI', label: 'Nicaragua', phone: '505' },
  { code: 'NL', label: 'Netherlands', phone: '31' },
  { code: 'NO', label: 'Norway', phone: '47' },
  { code: 'NP', label: 'Nepal', phone: '977' },
  { code: 'NR', label: 'Nauru', phone: '674' },
  { code: 'NU', label: 'Niue', phone: '683' },
  { code: 'NZ', label: 'New Zealand', phone: '64' },
  { code: 'OM', label: 'Oman', phone: '968' },
  { code: 'PA', label: 'Panama', phone: '507' },
  { code: 'PE', label: 'Peru', phone: '51' },
  { code: 'PF', label: 'French Polynesia', phone: '689' },
  { code: 'PG', label: 'Papua New Guinea', phone: '675' },
  { code: 'PH', label: 'Philippines', phone: '63' },
  { code: 'PK', label: 'Pakistan', phone: '92' },
  { code: 'PL', label: 'Poland', phone: '48' },
  {
    code: 'PM',
    label: 'Saint Pierre and Miquelon',
    phone: '508',
  },
  { code: 'PN', label: 'Pitcairn', phone: '870' },
  { code: 'PR', label: 'Puerto Rico', phone: '1' },
  {
    code: 'PS',
    label: 'Palestine, State of',
    phone: '970',
  },
  { code: 'PT', label: 'Portugal', phone: '351' },
  { code: 'PW', label: 'Palau', phone: '680' },
  { code: 'PY', label: 'Paraguay', phone: '595' },
  { code: 'QA', label: 'Qatar', phone: '974' },
  { code: 'RE', label: 'Reunion', phone: '262' },
  { code: 'RO', label: 'Romania', phone: '40' },
  { code: 'RS', label: 'Serbia', phone: '381' },
  { code: 'RU', label: 'Russian Federation', phone: '7' },
  { code: 'RW', label: 'Rwanda', phone: '250' },
  { code: 'SA', label: 'Saudi Arabia', phone: '966' },
  { code: 'SB', label: 'Solomon Islands', phone: '677' },
  { code: 'SC', label: 'Seychelles', phone: '248' },
  { code: 'SD', label: 'Sudan', phone: '249' },
  { code: 'SE', label: 'Sweden', phone: '46' },
  { code: 'SG', label: 'Singapore', phone: '65' },
  { code: 'SH', label: 'Saint Helena', phone: '290' },
  { code: 'SI', label: 'Slovenia', phone: '386' },
  {
    code: 'SJ',
    label: 'Svalbard and Jan Mayen',
    phone: '47',
  },
  { code: 'SK', label: 'Slovakia', phone: '421' },
  { code: 'SL', label: 'Sierra Leone', phone: '232' },
  { code: 'SM', label: 'San Marino', phone: '378' },
  { code: 'SN', label: 'Senegal', phone: '221' },
  { code: 'SO', label: 'Somalia', phone: '252' },
  { code: 'SR', label: 'Suriname', phone: '597' },
  { code: 'SS', label: 'South Sudan', phone: '211' },
  {
    code: 'ST',
    label: 'Sao Tome and Principe',
    phone: '239',
  },
  { code: 'SV', label: 'El Salvador', phone: '503' },
  {
    code: 'SX',
    label: 'Sint Maarten (Dutch part)',
    phone: '1-721',
  },
  {
    code: 'SY',
    label: 'Syrian Arab Republic',
    phone: '963',
  },
  { code: 'SZ', label: 'Swaziland', phone: '268' },
  {
    code: 'TC',
    label: 'Turks and Caicos Islands',
    phone: '1-649',
  },
  { code: 'TD', label: 'Chad', phone: '235' },
  {
    code: 'TF',
    label: 'French Southern Territories',
    phone: '262',
  },
  { code: 'TG', label: 'Togo', phone: '228' },
  { code: 'TH', label: 'Thailand', phone: '66' },
  { code: 'TJ', label: 'Tajikistan', phone: '992' },
  { code: 'TK', label: 'Tokelau', phone: '690' },
  { code: 'TL', label: 'Timor-Leste', phone: '670' },
  { code: 'TM', label: 'Turkmenistan', phone: '993' },
  { code: 'TN', label: 'Tunisia', phone: '216' },
  { code: 'TO', label: 'Tonga', phone: '676' },
  { code: 'TR', label: 'Turkey', phone: '90' },
  {
    code: 'TT',
    label: 'Trinidad and Tobago',
    phone: '1-868',
  },
  { code: 'TV', label: 'Tuvalu', phone: '688' },
  {
    code: 'TW',
    label: 'Taiwan, Republic of China',
    phone: '886',
  },
  {
    code: 'TZ',
    label: 'United Republic of Tanzania',
    phone: '255',
  },
  { code: 'UA', label: 'Ukraine', phone: '380' },
  { code: 'UG', label: 'Uganda', phone: '256' },
  {
    code: 'US',
    label: 'United States',
    phone: '1',
    suggested: true,
  },
  { code: 'UY', label: 'Uruguay', phone: '598' },
  { code: 'UZ', label: 'Uzbekistan', phone: '998' },
  {
    code: 'VA',
    label: 'Holy See (Vatican City State)',
    phone: '379',
  },
  {
    code: 'VC',
    label: 'Saint Vincent and the Grenadines',
    phone: '1-784',
  },
  { code: 'VE', label: 'Venezuela', phone: '58' },
  {
    code: 'VG',
    label: 'British Virgin Islands',
    phone: '1-284',
  },
  {
    code: 'VI',
    label: 'US Virgin Islands',
    phone: '1-320',
  },
  { code: 'VN', label: 'Vietnam', phone: '84' },
  { code: 'VU', label: 'Vanuatu', phone: '678' },
  { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
  { code: 'WS', label: 'Samoa', phone: '685' },
  { code: 'XK', label: 'Kosovo', phone: '383' },
  { code: 'YE', label: 'Yemen', phone: '967' },
  { code: 'YT', label: 'Mayotte', phone: '262' },
  { code: 'ZA', label: 'South Africa', phone: '27' },
  { code: 'ZM', label: 'Zambia', phone: '260' },
  { code: 'ZW', label: 'Zimbabwe', phone: '263' },
];