import axios from "axios";
import { HANDLE_SNACKBAR } from "../../Redux/slices/SnackbarAlert";
import { store } from "../../store";
import { apiGateway } from "../../utils/constant/apigateways";
import { ResponseSuccess } from "../../utils/Response";
import supabase from "../Supabase";

// export const postFeedback = ( setLoading, requestBody, handleClose, clearFields ) => {
//     setLoading(true);
//     axios.post(apiGateway + "/feedback", requestBody, {
//        headers: {
//           "Content-Type": "application/json",
//           "Authorization": `Bearer ${localStorage.getItem('token-le-wegid')}`
//        }
//     })
//     .then(res => {
//        if(ResponseSuccess(res).status == true){
//           setLoading(false);
//           clearFields();
//           store.dispatch(HANDLE_SNACKBAR({
//             open: true,
//             message: "Thank you! Feedback collected successfully.",
//             type: "success"
//         }));
//        }else{
//             store.dispatch(HANDLE_SNACKBAR({
//                 open: true,
//                 message: "Something went wrong!",
//                 type: "error"
//             }));
//        }
//     })
//     .catch(() => {
//         store.dispatch(HANDLE_SNACKBAR({
//             open: true,
//             message: "Something went wrong!",
//             type: "error"
//         }));
//        setLoading(false);
//     }).finally(() => {
//         handleClose();
//     })
// }

export const postFeedback = async( setLoading, requestBody, handleClose, clearFields ) => {
    setLoading(true);
    const { data, error } = await supabase
      .from('demo-feedback')
      .insert(requestBody)
    if (error) {
        store.dispatch(HANDLE_SNACKBAR({
            open: true,
            message: "Something went wrong!",
            type: "error"
        }));
        setLoading(false);
        clearFields();
        handleClose();
        return
    }
    setLoading(false);
    clearFields();
    store.dispatch(HANDLE_SNACKBAR({
      open: true,
      message: "Thank you! Feedback collected successfully.",
      type: "success"
    }));
    handleClose();
}
