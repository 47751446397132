import { Icon } from '@iconify/react'
import { Button, Card, CardContent, CircularProgress, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Avatar, { genConfig } from 'react-nice-avatar';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import ProjectAdd from '../components/Project/ProjectAdd';
import { defaultAvatar } from '../Constant/Avatar';
import { PROJECT_SELECTED } from '../Redux/slices/ProjectSlice';
import { singOut } from '../Services/Networks/Oauth';
import { projectList } from '../Services/Networks/Projects';

function Dashboard() {
    const [formOpen, setFormOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    // const userData = JSON.parse(localStorage.getItem("userObj"));
    const userData = useSelector(state => state.user);
    const projectListData = useSelector(state => state.project?.data);
    
    const selectproject = (item) => {
        dispatch(PROJECT_SELECTED(item));
        window.location.href = `/home/${item.id}/translation`
    }
    const fetchProjectList = () => {
        projectList(setLoading);
    }
    useEffect(() => {
        fetchProjectList();
    },[]);

	const handleCloseForm = () => {
		setFormOpen(!formOpen)
	}
    const getProfileImage = () => {
        const img = defaultAvatar?.filter(data => data.url === userData?.profilePic || data.url === userData?.profilePicture);
        
        if(img.length !== 0){
          const config = genConfig(img[0])

          return <Avatar className="avatar-gen-custo-override" style={{ width: '60px', height: '60px', marginTop: 5 }} {...config} />
        }else{
          return <img alt="User-Image" referrerpolicy="no-referrer" src={userData?.profilePicture} className="img-radius" />
        }
    }
    return (
    <div className='main-pcoded-card'>
        <div className='inner-main-pcoded-card'>
        <ProjectAdd open={formOpen} projectListData={projectListData} handleClose={handleCloseForm} fetchProjectList={fetchProjectList}/>
        <div className='card-back-animation'></div>
        <Card className='card-dashboard-custom'>
            <CardContent>
                <div className='d-flex flex-c my-1'>
                    {getProfileImage()}
                    {/* <Avatar style={{width: 65, height: 65}}/> */}
                </div>
                <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                    Welcome back,
                </Typography>
                <Typography variant="h5" component="b" sx={{ fontWeight: 600 }} >
                    {userData?.name}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {userData?.email} • <Link onClick={() => singOut()} style={{color: 'blue', fontWeight: 'bold'}} >Sign out</Link>
                </Typography>
                <div className='flex-sb' style={{alignItems: 'center', width: '80%', margin: 'auto'}}>
                    <Typography variant="h6" component="b" sx={{ fontWeight: 500, fontSize: 18}} >
                        Your Projects
                    </Typography>
                    <Button className='add-project-m my-1' variant='contained' onClick={() => handleCloseForm()} style={{fontSize: 16, borderRadius: 50, textTransform: 'capitalize'}}>
                        <Icon icon="ion:plus" style={{fontSize: 18, marginRight: 8}}/>
                        <div>Project</div>
                    </Button>
                </div>
                
                {isLoading ? 
                    <CircularProgress style={{ width: "20px", height: "20px"}}/> : 
                    <div className='col col-projectlist'>
                    {!projectListData?.length > 0 ?
                       <>
                        <div className='col-c psdaper-dashboard p-3'>
                            <div className='add-project' >
                                <Icon icon="bi:hourglass-split" style={{fontSize: 20}}/>
                                <div><b>You don't have any project</b></div>
                                <div><span>Please forge a new project</span></div>
                            </div>
                        </div>
                        <Paper className='col-c paper-dashboard p-3' onClick={() => handleCloseForm()}>
                            <div className='add-project' >
                                <Icon icon="ion:construct" style={{fontSize: 20}}/>
                                <div><b>Forge new Project</b></div>
                            </div>
                        </Paper>
                       </> 
                     :
                        projectListData?.map(data => <Paper key ={data?.id} className='flex-fs paper-dashboard' onClick={() => selectproject(data)}>
                        <Icon icon="ion:folder-open"/>
                        <div className='project-details'>
                            <b className='b-tag-desc'>{data?.name}</b>  <span style={{fontSize: 12, textTransform: "lowercase"}}>({data?.role})</span>
                            <p className='p-tag-desc'>{data?.id}</p>
                        </div>
                    </Paper>
                    )}
                </div>
                }
            </CardContent>
        </Card>
        </div>
    </div>
  )
}

export default Dashboard