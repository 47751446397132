import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "project",
  initialState: {},
  reducers: {
    PROJECT_SELECTED: (data, action) => {
      return { ...data, selectedProject: action.payload };
    },
    GET_PROJECT_LIST: (data, action) => {
      return { ...data, data: action.payload };
    },
    POST_PROJECT: (data, action) => {
      return { ...data, status: action.payload.status };
    },
  },
});

export const { PROJECT_SELECTED, POST_PROJECT, GET_PROJECT_LIST } = slice.actions;

export default slice.reducer;
