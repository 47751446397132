import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "comments",
  initialState: {},
  reducers: {
    GET_COMMENT_LIST: (data, action) => {
      return { ...data, data: action.payload };
    },
  },
});

export const { GET_COMMENT_LIST } = slice.actions;

export default slice.reducer;
