import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "snackbar",
  initialState: {
    open: false,
    message: "",
    type: "success"
  },
  reducers: {
    HANDLE_SNACKBAR: (data, action) => {
        return { ...data, ...action.payload };
    }
  },
});

export const { HANDLE_SNACKBAR } = slice.actions;

export default slice.reducer;
