import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Icon } from '@iconify/react';
import { Autocomplete, Checkbox, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import { fileFormat } from '../../Constant/Trans';
import { localName } from '../../utils/Response';
import { useSelector } from 'react-redux';

const steps = [
    {
        label: 'File Format',
        description: `For downloading the keys please select a file format.`,
    },
    {
        label: 'Languages',
        description:
            'Choose your langauges to includes while download.',
    },
    {
        label: 'Keys to export',
        description:
            'Content to export ',
    },
    {
        label: 'Include tags',
        description:
            'Export keys which include tags. ',
    },
    {
        label: 'Exclude tags',
        description:
            'Export keys which exclude tags. ',
    },
];

const names = [
    'All',
    'Untranslated Keys',
    'Translated Keys',
    'Reviewed keys',
    'Un-reviewed keys',
    'Hidden keys',
    'Non-Hidden keys',
];
const tags = [
    'All',
    'p0',
    'Dev',
    'check-qa',
    'released',
];
export default function Downloads() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [array, setData] = React.useState([]);
    const [value, setValue] = React.useState([]);
    const [tagInclude, setTagInclude] = React.useState([]);
    const [tagExclude, setTagExclude] = React.useState([]);
    const projectDetails = useSelector(state => state?.project?.selectedProject);

    const columns = [
        {
            name: "name",
            label: "Locale",
            align: "left",
            minWidth: 30,
        },
        {
            name: "default",
            label: "Default",
            align: "left",
            minWidth: 65,
            options: {
                filter: true,
                sort: true,
            }
        }
    ];

    React.useEffect(() => {
        setData(projectDetails?.locales);
    }, [projectDetails]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
      };

    return (
        <div className="pcoded-inner-content">
            <div className="main-body">
                <div className="page-wrapper">
                    <div className="page-header">
                        <div className="row align-items-end">
                            <div className="col-lg-8">
                                <div className="page-header-title">
                                    <div className="d-inline">
                                        <h4>Download Files</h4>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <Paper sx={{ width: '100%', height: "72vh", marginBottom: 20, overflowY: "scroll" }} className="flex-fs paper-steper-upload paper-c-shadow">
                        <Box sx={{ width: "70%", paddingBottom: 40 }}>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                {steps.map((step, index) => (
                                    <Step key={step.label}>
                                        <StepLabel
                                            onClick={handleStep(index)}
                                            style={{ cursor: "pointer" }}
                                            optional={
                                                index === 5 ? (
                                                    <Typography variant="caption">Last step</Typography>
                                                ) : null
                                            }
                                        >
                                            {step.label}
                                        </StepLabel>
                                        <StepContent>
                                            <Typography>{step.description}</Typography>
                                            {index == 0 && <Box sx={{ mt: 2 }}>
                                                <Autocomplete
                                                    id="country-select-demo"
                                                    sx={{ width: '80%', height: 40 }}
                                                    className="input-custom-css"
                                                    options={fileFormat}
                                                    // value={country}
                                                    size="small"
                                                    // onChange={(e, newValue) => setCountry(newValue)}
                                                    autoHighlight
                                                    getOptionLabel={(option) => option.label}
                                                    renderOption={(props, option) => (
                                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                            {option.label}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Choose a file format"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Box>
                                            }
                                            {index == 1 && <Box sx={{ mt: 2 }}>
                                                <Table aria-label="sticky table">
                                                    <TableHead className="table-custom-row">
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                color="primary"
                                                                // indeterminate={selectedRows?.length > 0 && selectedRows?.length < rowsPerPage}
                                                                // checked={rowsPerPage > 0 && selectedRows?.length === rowsPerPage}
                                                                // onChange={(e) => onSelectAllClick(e)}
                                                                inputProps={{
                                                                    'aria-label': 'select all desserts',
                                                                }}
                                                            />
                                                        </TableCell>
                                                        {columns.map((column) => (
                                                            <TableCell
                                                                key={column.id}
                                                                align={column.align}
                                                                style={{ minWidth: `${column.minWidth}%` }}
                                                            >
                                                                <b>{column.label}</b>
                                                            </TableCell>
                                                        ))}
                                                    </TableHead>
                                                    <TableBody>
                                                        {array
                                                            ?.sort((it, it2) => it.isDefault < it2.isDefault ? 1 : -1)
                                                            ?.map((row, j) => {
                                                                return (
                                                                    <TableRow className="table-hover-icons-normal-rows" tabIndex={-1} key={row.id}>
                                                                        <TableCell padding="checkbox">
                                                                            <Checkbox
                                                                                color="primary"
                                                                                // onChange={() => handleCheckBox(row)}
                                                                                // checked={isItemSelected(row)}
                                                                                inputProps={{
                                                                                    // 'aria-labelledby': labelId,
                                                                                }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell align="left">{localName(row.locale)} - {row.locale} </TableCell>
                                                                        <TableCell>
                                                                            {row.isDefault === true && <span className="tag-default-lang">default</span>}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            })}
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                            }
                                            {index == 2 && <Box sx={{ mt: 2 }}>
                                                <div>
                                                    <b id="demo-multiple-chip-label">Include data</b>
                                                    <FormControl sx={{ m: 1 }} fullWidth size='small'>
                                                        <Select
                                                            labelId="demo-multiple-chip-label"
                                                            id="demo-multiple-chip"
                                                            multiple
                                                            value={value}
                                                            onChange={(e, neValue) => setValue([...e.target.value])}
                                                            input={<OutlinedInput id="select-multiple-chip"  />}
                                                            renderValue={(selected) => (
                                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                    {selected.map((value) => (
                                                                        <Chip key={value} label={value} style={{borderRadius: 2, height: 20}}/>
                                                                    ))}
                                                                </Box>
                                                            )}
                                                        //   MenuProps={MenuProps}
                                                        >
                                                            {names.map((name) => (
                                                                <MenuItem
                                                                    key={name}
                                                                    value={name}
                                                                //   style={getStyles(name, personName, theme)}
                                                                >
                                                                    {name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div>
                                                    <b id="demo-multiple-chip-label">Empty translations </b>
                                                    <FormControl sx={{ m: 1 }} fullWidth size='small'>
                                                    <Select >
                                                        <MenuItem>
                                                           Don't Export 
                                                        </MenuItem>
                                                        <MenuItem>
                                                           Export as empty string
                                                        </MenuItem>
                                                        <MenuItem>
                                                           Replace with base language
                                                        </MenuItem>
                                                    </Select>
                                                    </FormControl>
                                                </div>
                                            </Box>}
                                            {index == 3 && <Box sx={{ mt: 2 }}>
                                                <div>
                                                    <b id="demo-multiple-chip-label">Include tags</b>
                                                    <FormControl sx={{ m: 1 }} fullWidth size='small'>
                                                        <Select
                                                            labelId="demo-multiple-chip-label"
                                                            id="demo-multiple-chip"
                                                            multiple
                                                            value={tagInclude}
                                                            onChange={(e, neValue) => setTagInclude([...e.target.value])}
                                                            input={<OutlinedInput id="select-multiple-chip"  />}
                                                            renderValue={(selected) => (
                                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                    {selected.map((value) => (
                                                                        <Chip key={value} label={value} style={{borderRadius: 2, height: 20}}/>
                                                                    ))}
                                                                </Box>
                                                            )}
                                                        //   MenuProps={MenuProps}
                                                        >
                                                            {tags.map((name) => (
                                                                <MenuItem
                                                                    key={name}
                                                                    value={name}
                                                                //   style={getStyles(name, personName, theme)}
                                                                >
                                                                    {name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </Box>}
                                            {index == 4 && <Box sx={{ mt: 2 }}>
                                                <div>
                                                    <b id="demo-multiple-chip-label">Exclude tags</b>
                                                    <FormControl sx={{ m: 1 }} fullWidth size='small'>
                                                        <Select
                                                            labelId="demo-multiple-chip-label"
                                                            id="demo-multiple-chip"
                                                            multiple
                                                            value={tagExclude}
                                                            onChange={(e, neValue) => setTagExclude([...e.target.value])}
                                                            input={<OutlinedInput id="select-multiple-chip"  />}
                                                            renderValue={(selected) => (
                                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                    {selected.map((value) => (
                                                                        <Chip key={value} label={value} style={{borderRadius: 2, height: 20}}/>
                                                                    ))}
                                                                </Box>
                                                            )}
                                                        //   MenuProps={MenuProps}
                                                        >
                                                            {tags.map((name) => (
                                                                <MenuItem
                                                                    key={name}
                                                                    value={name}
                                                                //   style={getStyles(name, personName, theme)}
                                                                >
                                                                    {name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </Box>}
                                            <Box sx={{ mb: 2, mt: 3 }}>
                                                <div>
                                                    {index !== steps.length - 1 && <Button
                                                        variant="outlined"
                                                        onClick={handleNext}
                                                        size={"small"}
                                                        sx={{ mt: 1, mr: 1 }}
                                                    >
                                                        Skip
                                                    </Button>
                                                    }
                                                    <Button
                                                        variant="contained"
                                                        onClick={handleNext}
                                                        size={"small"}
                                                        sx={{ mt: 1, mr: 1 }}
                                                    >
                                                        {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                                    </Button>
                                                    <Button
                                                        disabled={index === 0}
                                                        onClick={handleBack}
                                                        color="primary"
                                                        style={{ color: index !== 0 && "#6366f1" }}
                                                        size={"small"}
                                                        sx={{ mt: 1, mr: 1 }}
                                                    >
                                                        Back
                                                    </Button>
                                                </div>
                                            </Box>
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                            {activeStep === steps.length && (
                                <Paper square elevation={0} sx={{ p: 3 }}>
                                    <Typography style={{ background: "#6366f1", padding: 5, color: "white", borderRadius: 8 }}>All steps completed - you&apos;re finished</Typography>
                                    
                                    <Button onClick={() => { }} sx={{ mt: 1, mr: 1 }} style={{ color: "#6366f1", textTransform:"none" }}>
                                        <Icon icon={"material-symbols:arrow-back-rounded"}/>Go back to keys
                                    </Button>
                                </Paper>
                            )}
                        </Box>
                        <Box sx={{ width: "30%", }}>
                            <div className='m-3 p-4 wrap-info-supported'>
                                <b className='py-2'>App Integrations: </b><br/>
                                <div className='py-2'>
                                    <Button variant='outlined' disabled><Icon icon={"skill-icons:aws-dark"} width={26} style={{marginRight: 5}}/>Sync with AWS S3</Button>
                                </div>
                                <div className='py-2'>
                                    <b>Triger webhook</b><br/>
                                    <Button className='my-2' disabled variant='contained'>Add webhook</Button>
                                </div>
                            </div>
                        </Box>
                    </Paper>
                </div>
            </div>
        </div>
    );
}
