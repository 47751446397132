import { CircularProgress } from '@mui/material';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { oAuthAccess } from '../Services/Networks/Integration';

function Slacks() {
    const [text, setText] = useState("Requesting slack o-auth access .....")
    const projectDetails = useSelector(state => state?.project?.selectedProject);
    const integrationInfo = useSelector(state => state?.slackconfig?.types);
    const queryParams = queryString.parse(window.location.search);
    
    useEffect(() => {
        if(queryParams?.code !== ""){
            oAuthAccess(queryParams.code, projectDetails.id, integrationInfo, setText);
        }
    }, []);
    
    return (
        <div className='conatiner col-sm-9 flex-c m-auto mt-5 pt-5'>
            <h2>{text}<CircularProgress /></h2>
        </div>
    )
}

export default Slacks