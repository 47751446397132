import { Icon } from '@iconify/react'
import {  Button, Card, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { projectList, updateProject } from '../../Services/Networks/Projects';

function ProjectSetting({ handleOpenInvite }) {
    const projectDetails = useSelector(state => state?.project?.selectedProject);
    const [name, setname] = useState('');
    const [error, setError] = useState('');
    const [isUpdating, setUpdating] = useState(false);

    const getDefault = (data) => {
        let defaults = '';
        data?.locales?.forEach(d => {
            if (d?.isDefault == true) {
                defaults = d.locale;
                return true
            }
        });
        return defaults
    }

    const gettarget = (data) => {
        let obj = [];
        data?.locales?.forEach((d) => {
            if (d?.isDefault !== true) {
                obj.push({
                    locale_name: d?.locale
                })
            }
        });
        return obj
    }

    useEffect(() => {
        if(projectDetails)
            setname(projectDetails?.name)
    }, [projectDetails]);

    const updateProjectDetails = () => {
        if (name?.length > 0) {
            const payload = {
                projectId: projectDetails?.id,
                payload: {
                    projectName: name,
                    locales: projectDetails?.locales
                }
            }
            updateProject(payload, setUpdating, projectDetails ,()=>{}, ()=>{})
        } else {
            setError('Name must not be empty!')
        }
    }
    return (
        <div>
            <div>
                <h5 className="pl-3 pt-3" style={{ paddingBottom: 0 }}>Project Settings</h5>
                <span className="pl-3 pb-3">General information about your project</span>
                <Card className="row p-4 card-view-settings">
                    <div className="inputfeild-basic-group d-flex">
                        <TextField
                            size="small"
                            className="input-custom-css"
                            type="text"
                            id="outlined-basic"
                            label="Project Name"
                            variant="outlined"
                            value={name}
                            error={error?.length > 0}
                            helperText={error}
                            onChange={(e) => {
                                setname(e.target.value)
                                setError('')
                            }}
                            style={{ marginRight: 10 }}
                        />
                    </div>
                    <div className="inputfeild-basic-group d-flex">
                        <FormControl variant="outlined" disabled style={{ width: "50%", height: 40, marginRight: 10 }}>
                            <InputLabel htmlFor="outlined-adornment-password" style={{ marginTop: "-2px" }}>Project ID</InputLabel>
                            <OutlinedInput
                                size="small"
                                disabled
                                value={projectDetails?.id}
                                className="input-custom-css"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            style={{ fontSize: 14, color: "red", borderRadius: 4 }}
                                        >
                                            <Icon icon="lucide:clipboard-copy" /> Copy
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Project ID"
                            />
                        </FormControl>
                        <FormControl variant="outlined" style={{ width: "50%", height: 40 }}>
                            <InputLabel htmlFor="outlined-adornment-password" style={{ marginTop: "-6px" }}>SDK secret</InputLabel>
                            <OutlinedInput
                                size="small"
                                disabled
                                className="input-custom-css"
                                id="outlined-adornment-password"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                            style={{ fontSize: 14, color: "red", borderRadius: 4 }}
                                        >
                                            <Icon icon="lucide:clipboard-copy" /> Copy
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="SDK secret"
                            />
                        </FormControl>
                    </div>
                    {/* <div className="inputfeild-basic-group d-flex">
                        <div style={{width: "50%", marginRight: 20}}>
                            <FormControl fullWidth size="medium">
                                <InputLabel id="demo-simple-select-label">Base Language</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={getDefault(projectDetails)}
                                    label="Base Language"
                                    // onChange={handleChange}
                                    // error={onerror.length > 0}
                                >
                                    {abbreviationLang.map((name) => (
                                        <MenuItem
                                            key={name?.abbreviation}
                                            value={name?.abbreviation}
                                            // style={getStyles(name, personName, theme)}
                                        >
                                            {name?.locale_name} -- {" "}<span style={{color: "grey", textTransform: "uppercase"}}> {name?.abbreviation}</span>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>    
                        <div style={{width: "50%"}}>
                            <Autocomplete
                                multiple
                                // error={onerror?.length>0}
                                value={gettarget(projectDetails)}
                                // onChange={(event, newValue) => {
                                //   handleChangeLanguage(event, newValue);
                                //   setOnerror("")
                                // }}
                                options={abbreviationLang}
                                getOptionLabel={(option) => <>{option?.locale_name} -- <span style={{color: "grey", textTransform: "uppercase"}}> {option?.abbreviation}</span></>}
                                renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                    <Chip
                                    label={option.locale_name}
                                    style={{textTransform: "uppercase"}}
                                    {...getTagProps({ index })}
                                    />
                                ))
                                }
                                style={{ width: "100%" }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Languages" placeholder="Languages"  />
                                )}
                            />
                        </div>
                    </div> */}
                    <div style={{ width: '100%' }}>
                        <Button
                            onClick={() => {
                                updateProjectDetails();
                            }}
                            disabled={name === projectDetails.name || isUpdating || name.length == 0}
                            variant="contained"
                            style={{ float: 'right' }}
                            data-modal="modal-13"
                        >
                            {isUpdating ? "Updating...":"Update" }
                            
                        </Button>
                    </div>
                </Card>
            </div>
            <div>
                <h5 className="pl-3 pt-3" style={{ paddingBottom: 0 }}>Danger Zone</h5>
                <span className="pl-3 pb-3">Irreversible and destructive actions. Tread lightly</span>
                <div className='d-flex'>
                    <Card className="row p-2 card-view-settings">
                        <div className="d-flex my-3 align-items-center">
                            <Icon icon={"mingcute:key-2-line"} style={{ fontSize: 24, marginRight: 10, color: "red" }} />
                            <span style={{ fontWeight: 600, color: "red", fontSize: 16 }}>Revoke secret</span>
                        </div>
                        <div className='p-2'>Revoke an SDK secret only if you think someone is abusing it. All SDK instances using the old secret will be rendered useless.</div>
                        <div style={{ width: '100%' }}>
                            <button className='text-align-center-button dz float-right' onClick={(e) => handleOpenInvite(e)}>Revoke Secret key</button>
                        </div>
                    </Card>
                    <Card className="row p-2 card-view-settings" style={{ pointerEvents:"none", cursor: "not-allowed", opacity: .5}}>
                        <div className="d-flex my-3 align-items-center">
                            <Icon icon={"mingcute:delete-2-line"} style={{ fontSize: 24, marginRight: 10, color: "red" }} />
                            <span style={{ fontWeight: 600, color: "red", fontSize: 16 }}>Delete Project</span>
                        </div>
                        <div className='p-2'>Deleting a project is permanent and removes all data from our servers. All members will immediately lose access</div>
                        <div style={{ width: '100%' }}>
                            <button className='text-align-center-button dz float-right' onClick={(e) => handleOpenInvite(e)}>Delete Project</button>
                        </div>
                    </Card>
                </div>

            </div>
        </div>
    )
}

export default ProjectSetting