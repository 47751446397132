
import S3 from '../Assets/images/s3.png'
import GCS from '../Assets/images/gcs.png'
import JSIMG from '../Assets/images/js.png'
import jira from '../Assets/images/jira.png'
import Git from '../Assets/images/github.svg'
import Clipng from '../Assets/images/cli.png'
import Slack from '../Assets/images/slack.png'
import NodeImg from '../Assets/images/node.png'
import GoLang from '../Assets/images/golang.png'
import Flutter from '../Assets/images/flutter.png'
import ReactImg from '../Assets/images/react.png'
import AppleImg from '../Assets/images/apple.png'
import EmailImg from '../Assets/images/email.png'
import FigmaImg from '../Assets/images/figma.png'
import Android from '../Assets/images/android.png'
import Django from '../Assets/images/django.png'
import vs from '../Assets/images/vi2.png'
import Angular from '../Assets/images/angular.png'
import Webhook from '../Assets/images/webhook.png'
import TypeForm from '../Assets/images/type.png'
import Discord from '../Assets/images/discord.png'


export const fetauredList = [
    {
        "id": 18,
        "slug": "figma",
        "title": "Figma",
        "summary": "Exchange translations and screenshots with Figma.",
        "author": "Vernacle",
        "logo": FigmaImg,
        "logoDarkmode": null,
        "categories": [
            {
                "id": 3,
                "name": "Design tools"
            }
        ],
        "isPopular": true,
        "isAvailable": false,
        "isInstalled": true,
        "isBeta": false,
        "staticKey": "figma"
    }, {
        "id": 42,
        "slug": "slack",
        "title": "Slack",
        "summary": "Post to a Slack channel.",
        "author": "Vernacle",
        "logo": Slack,
        "logoDarkmode": null,
        "categories": [
            {
                "id": 1,
                "name": "Notifications and events"
            }
        ],
        "isPopular": true,
        "isAvailable": true,
        "isInstalled": false,
        "isBeta": false,
        "staticKey": "slack"
    }, {
        "id": 11,
        "slug": "cli",
        "title": "CLI",
        "summary": "Command-line tool for data exchange.",
        "author": "Vernacle",
        "logo": Clipng,
        "logoDarkmode": null,
        "categories": [
            {
                "id": 4,
                "name": "Developer tools"
            }
        ],
        "isPopular": false,
        "isAvailable": false,
        "isInstalled": true,
        "isBeta": false,
        "staticKey": "cli"
    }, {
        "id": 29,
        "slug": "javascript",
        "title": "JavaScript",
        "summary": "Use JS to get your keys configured.",
        "author": "Vernacle",
        "logo": JSIMG,
        "logoDarkmode": null,
        "categories": [
            {
                "id": 9,
                "name": "Others"
            }
        ],
        "isPopular": false,
        "isAvailable": false,
        "isInstalled": true,
        "isBeta": false,
        "staticKey": "javascript"
    }, {
        "id": 35,
        "slug": "nodejs",
        "title": "Node.js",
        "summary": "Localizing with the built-in i18n support.",
        "author": "Vernacle",
        "logo": NodeImg,
        "logoDarkmode": null,
        "categories": [
            {
                "id": 4,
                "name": "Developer tools"
            }
        ],
        "isPopular": false,
        "isAvailable": false,
        "isInstalled": false,
        "isBeta": false,
        "staticKey": "nodejs"
    }, {
        "id": 31,
        "slug": "flutter",
        "title": "Flutter SDK",
        "summary": "Update flutter strings over-the-air.",
        "author": "Vernacle",
        "logo": Flutter,
        "logoDarkmode": null,
        "categories": [
            {
                "id": 9,
                "name": "Others"
            }
        ],
        "isPopular": false,
        "isAvailable": false,
        "isInstalled": false,
        "isBeta": false,
        "staticKey": "flutter"
    }, {
        "id": 3,
        "slug": "reactnative",
        "title": "React Native SDK",
        "summary": "Update react native app's strings over-the-air.",
        "author": "Vernacle",
        "logo": ReactImg,
        "logoDarkmode": null,
        "categories": [
            {
                "id": 9,
                "name": "Others"
            }
        ],
        "isPopular": false,
        "isAvailable": false,
        "isInstalled": false,
        "isBeta": false,
        "staticKey": "native"
    }, {
        "id": 3,
        "slug": "golang",
        "title": "Golang",
        "summary": "Localizing in golang written code support.",
        "author": "Vernacle",
        "logo": GoLang,
        "logoDarkmode": null,
        "categories": [
            {
                "id": 9,
                "name": "Others"
            }
        ],
        "isPopular": false,
        "isAvailable": false,
        "isInstalled": false,
        "isBeta": false,
        "staticKey": "golang"
    }, {
        "id": 3,
        "slug": "s3",
        "title": "Amazon S3",
        "summary": "Upload localization files to your S3 bucket.",
        "author": "Vernacle",
        "logo": S3,
        "logoDarkmode": null,
        "categories": [
            {
                "id": 9,
                "name": "Others"
            }
        ],
        "isPopular": false,
        "isAvailable": false,
        "isInstalled": false,
        "isBeta": false,
        "staticKey": "amazons3"
    },
];

export const upComingApps = [
    {
        "id": 1,
        "slug": "gcs",
        "title": "Google cloude storage",
        "summary": "Upload you files to GCS bucket",
        "author": "Vernacle",
        "logo": GCS,
        "logoDarkmode": null,
        "categories": [
            {
                "id": 3,
                "name": "Design tools"
            }
        ],
        "isPopular": false,
        "isAvailable": false,
        "isInstalled": false,
        "isBeta": false,
        "staticKey": "gcs"
    },
    {
        "id": 3,
        "slug": "android",
        "title": "Android SDK",
        "summary": "Update Android strings over-the-air.",
        "author": "Vernacle",
        "logo": Android,
        "logoDarkmode": null,
        "categories": [
            {
                "id": 9,
                "name": "Others"
            }
        ],
        "isPopular": false,
        "isAvailable": false,
        "isInstalled": false,
        "isBeta": false,
        "staticKey": "android"
    },
    {
        "id": 28,
        "slug": "ios",
        "title": "iOS SDK",
        "summary": "Update iOS strings over-the-air.",
        "author": "Vernacle",
        "logo": AppleImg,
        "logoDarkmode": "/img/integrations/apple_darkmode.png",
        "categories": [
            {
                "id": 9,
                "name": "Others"
            }
        ],
        "isPopular": false,
        "isAvailable": false,
        "isInstalled": false,
        "isBeta": false,
        "staticKey": "ios"
    },
    {
        "id": 3,
        "slug": "react",
        "title": "React",
        "summary": "Connect your react with vernacle for easy localization",
        "author": "Vernacle",
        "logo": ReactImg,
        "logoDarkmode": null,
        "categories": [
            {
                "id": 9,
                "name": "Others"
            }
        ],
        "isPopular": false,
        "isAvailable": false,
        "isInstalled": false,
        "isBeta": false,
        "staticKey": "react"
    },
    {
        "id": 3,
        "slug": "angular",
        "title": "Angular",
        "summary": "Connect your angular with vernacle for easy localization",
        "author": "Vernacle",
        "logo": Angular,
        "logoDarkmode": null,
        "categories": [
            {
                "id": 9,
                "name": "Others"
            }
        ],
        "isPopular": false,
        "isAvailable": false,
        "isInstalled": false,
        "isBeta": false,
        "staticKey": "angular"
    },
    {
        "id": 3,
        "slug": "django",
        "title": "Django",
        "summary": "Connect your Django apps with vernacle for easy localization",
        "author": "Vernacle",
        "logo": Django,
        "logoDarkmode": null,
        "categories": [
            {
                "id": 9,
                "name": "Others"
            }
        ],
        "isPopular": false,
        "isAvailable": false,
        "isInstalled": false,
        "isBeta": false,
        "staticKey": "Django"
    },
    {
        "id": 4,
        "slug": "jira",
        "title": "Jira",
        "summary": "Create Jira issues and track the issues",
        "author": "Vernacle",
        "logo": jira,
        "logoDarkmode": null,
        "categories": [
            {
                "id": 4,
                "name": "Developer tools"
            }
        ],
        "isPopular": false,
        "isAvailable": false,
        "isInstalled": false,
        "isBeta": false,
        "staticKey": "jira"
    },   
    {
        "id": 9,
        "slug": "v18",
        "title": "i18n Ally for VS Code",
        "summary": "This extension makes the development of multilingual interfaces easier in VS Code.",
        "author": "Vernacle",
        "logo": vs,
        "logoDarkmode": null,
        "categories": [
            {
                "id": 7,
                "name": "Code repositories"
            }
        ],
        "isPopular": false,
        "isAvailable": false,
        "isInstalled": false,
        "isBeta": false,
        "staticKey": "i18n"
    },
    {
        "id": 58,
        "slug": "discord",
        "title": "Discord webhook",
        "summary": "This application is an integration of vernacle and Discord.",
        "author": "Daan Vansteenhuyse",
        "logo": Discord ,
        "logoDarkmode": null,
        "categories": [
            {
                "id": 1,
                "name": "Notifications and events"
            }
        ],
        "isPopular": false,
        "isAvailable": false,
        "isInstalled": false,
        "isBeta": false,
        "staticKey": "discord"
    },
    {
        "id": 16,
        "slug": "email",
        "title": "E-mail",
        "summary": "Send e-mail notifications.",
        "author": "Vernacle",
        "logo": EmailImg,
        "logoDarkmode": null,
        "categories": [
            {
                "id": 1,
                "name": "Notifications and events"
            }
        ],
        "isPopular": false,
        "isAvailable": false,
        "isInstalled": false,
        "isBeta": false,
        "staticKey": "email"
    },
    {
        "id": 22,
        "slug": "github",
        "title": "GitHub",
        "summary": "Exchange files with a GitHub repository.",
        "author": "Vernacle",
        "logo": Git,
        "logoDarkmode": "/img/integrations/github_darkmode.svg",
        "categories": [
            {
                "id": 7,
                "name": "Code repositories"
            }
        ],
        "isPopular": true,
        "isAvailable": false,
        "isInstalled": false,
        "isBeta": false,
        "staticKey": "github"
    },
    {
        "id": 69,
        "slug": "typeform",
        "title": "Typeform",
        "summary": "Grow your business with localized forms, quizzes, and surveys that feel like a conversation in your user's native language",
        "author": "Vernacle",
        "logo": TypeForm,
        "categories": [
            {
                "id": 2,
                "name": "Content management"
            }
        ],
        "isPopular": false,
        "isAvailable": false,
        "isInstalled": false,
        "isBeta": true,
        "staticKey": "typeform"
    },
    {
        "id": 58,
        "slug": "webhook",
        "title": "Webhook",
        "summary": "This webhook is a custom integration for vernacle.",
        "author": "Vernacle",
        "logo": Webhook,
        "logoDarkmode": "https://lokalise-live-lok-app-main-assets.s3.eu-central-1.amazonaws.com/integration_logo/61c98e0ae0c7a4.06750606.png",
        "categories": [
            {
                "id": 1,
                "name": "Notifications and events"
            }
        ],
        "isPopular": false,
        "isAvailable": false,
        "isInstalled": false,
        "isBeta": false,
        "staticKey": "Webhook"
    },
];

export const slackData = {
    "definition": {
        "title": "Slack",
        "isAvailable": true,
        "isInstalled": false,
        "isBeta": false,
        "summary": "Post to a Slack channel.",
        "url": null,
        "categories": [
            {
                "id": 1,
                "name": "Notifications and events"
            }
        ],
        "logo": Slack,
        "logoDarkmode": null,
        "staticKey": "slack",
        "isStatic": true,
        "id": 42,
        "about": "The Lokalise Slack app delivers instant notifications about what's happening in Lokalise. Automate this process to notify all team members involved in any Slack channel. Stay informed on the progress of your projects, without leaving your main communication channel.",
        "keyFeatures": ["Automate your notifications so you don’t miss a thing ", "Get pinged in Slack about changes to translations, projects, keys, tasks, languages, contributors, and orders.", "Receive notifications in any Slack channel"],
        "slug": "slack",
        "mediaContent": "",
        "author": "Vernacle",
        "docsUrl": "https://vernacle.in/integrations/slack",
        "privacyPolicyLink": "https://vernacle.in/privacy-policy",
        "termsOfServiceLink": "https://vernacle.in/terms",
        "isInternalApp": true
    }
};

export const NOTIFICATION_TYPE = {
    INFO_SYSTEM : "INFO_SYSTEM",
    INFO_CONSOLE : "INFO_CONSOLE",
    INFO_COMMENTS_AND_KEY_VALUE : "INFO_COMMENTS_AND_KEY_VALUE",
}