import React from 'react'
import { useSelector } from 'react-redux';
import {Link} from 'react-router-dom';
import linkB from '../Assets/images/link.png';

function NotFound() {
  const projectId = useSelector(state => state?.project?.selectedProject?.id);

  return (
    <div className='pnf-main'>
        <div className='flex-pnf'>
            <img src={linkB} style={{ width: 250, height: 250 }}/>
            <h1>We couldn't connect the dots. !</h1>
            <p>This page is not found. You may have mistyped the <br/> address or the page may have removed by owner</p>
            <Link to={`/`}>Take me to the home page</Link>
        </div>
    </div>
  )
}

export default NotFound