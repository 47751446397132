let gateway = "https://lcdl0bq3d2.execute-api.us-east-1.amazonaws.com";
let supaBaseUrl = "https://tnaxbfpzenjspvvizuag.supabase.co";
let supabaseToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InRuYXhiZnB6ZW5qc3B2dml6dWFnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzYzOTgwMzYsImV4cCI6MTk5MTk3NDAzNn0.UwkRQzvjUgKVsepwgCSG-FxyPbZlMDQanPULIOYX830"

const { REACT_APP_API_GATEWAY, REACT_SUPA_BASE_GATEWAY } = process.env;
if (
  !(REACT_APP_API_GATEWAY === null || REACT_APP_API_GATEWAY === undefined) &&
  REACT_APP_API_GATEWAY.trim().length !== 0
) {
  gateway = REACT_APP_API_GATEWAY;
}


if (
  !(REACT_SUPA_BASE_GATEWAY === null || REACT_SUPA_BASE_GATEWAY === undefined) &&
  REACT_SUPA_BASE_GATEWAY.trim().length !== 0
) {
  supaBaseUrl = REACT_SUPA_BASE_GATEWAY;
}

export const apiGateway = gateway;
export const apisupaBaseUrl = supaBaseUrl;
export const supabaseToken_id = supabaseToken;
